import { ISupply } from '@models/Supply.model'

export const SupplyEmpty : ISupply = {
  id: 0,
  category: {
    id: 0,
    name: ''
  },
  family: {
    id: 0,
    name: '',
    parent_id: 0
  },
  subfamily: {
    id: 0,
    name: '',
    parent_id: 0
  },
  sku: '',
  name: '',
  variant_color: '',
  compatible_models: '',
  origin_code: '',
  sku_rewa: '',
  active: 1,
  inventory: 0,
  reference_links: '',
  serial: '',
  my_acquisitions: []
}

import { AnnouncementIcon } from '@assets/icons/Icons'
import { inputsOT } from '@constants/InputsFormOT.constants'
import { IAppStore } from '@models/store.model'
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import { disableInputsRole } from '@utils/Roles.util'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const FaultInformation = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const [data, setData] = React.useState<{ loading: boolean, listFailures: any[], listCosmeticStatus: any[]}>({ loading: false, listFailures: [], listCosmeticStatus: [] })
  const { enqueueSnackbar } = useSnackbar()
  const userState = useSelector((store: IAppStore) => store.user)

  const loadFailureAndCosmetic = async () => {
    const response : any = await Promise.all([
      webApiService.getAllFailure().then((response) => response),
      webApiService.getAllCosmeticStatus().then((response) => response)
    ]).catch((error) => {
      console.log(error)
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    })
    const failures = response[0].items
    const cosmeticStatus = (response[1].success) ? response[1].data : []
    setData({ ...data, listFailures: failures, listCosmeticStatus: cosmeticStatus })
  }

  useEffect(() => {
    loadFailureAndCosmetic()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='row gy-4'>
        <div className='col-12'>
          <Typography variant='h6' component='div' className='color-title'>
            <AnnouncementIcon className='color-azul' /> INFORMACIÓN FALLAS
          </Typography>
        </div>
        {userState.id === 0
          ? (
            <div className='col-12'>
              <Alert severity='info'>
                Puedes seleccionar una o más fallas.
              </Alert>
            </div>
            )
          : null}
        <div className='col-12 col-lg-6'>
          <FormControl fullWidth>
            <InputLabel id='label-sl-id-failure'>
              Información Fallas
            </InputLabel>
            <Select
              disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.slFailure)}
              labelId='label-sl-id-failure'
              id='sl-id-failure'
              multiple
              value={formCtx.ot.failures}
              label='Información Fallas'
              onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, failures: e.target.value } })}
              error={formCtx.ot.failures.length === 0 && formCtx.errorValidation}
            >
              {
              data.listFailures.map(e => {
                return (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)
              })
            }
            </Select>
          </FormControl>
        </div>
        <div className='col-12 col-lg-6'>
          <FormControl fullWidth>
            <InputLabel id='label-sl-id_cosmetic-status'>
              Categoría cosmético
            </InputLabel>
            <Select
              disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.slCosmetic)}
              labelId='label-sl-id_cosmetic-status'
              id='sl-id_cosmetic-status'
              value={formCtx.ot.id_cosmetic_status}
              label='Categoría cosmético'
              onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, id_cosmetic_status: e.target.value } })}
              error={formCtx.ot.id_cosmetic_status === '' && formCtx.errorValidation}
            >
              {
              data.listCosmeticStatus.map(e => {
                return (<MenuItem value={e.id} key={e.id}>{e.description}</MenuItem>)
              })
            }
            </Select>
          </FormControl>
        </div>

        <div className='col-12 col-lg-6'>
          <TextField
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.faultDesc)}
            type='text'
            name='descripcion'
            label='Descripcion'
            multiline
            rows={5}
            fullWidth
            value={formCtx.ot.failure_description}
            onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, failure_description: e.target.value } })}
          />
        </div>
        <div className='col-12 col-lg-6'>
          <TextField
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.cosmeticStatus)}
            type='text'
            name='cosmetico'
            label='Estado Cosmetico'
            multiline
            rows={5}
            fullWidth
            value={formCtx.ot.cosmetic_description}
            onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, cosmetic_description: e.target.value } })}
          />
        </div>
      </div>
    </>
  )
}

export default FaultInformation

import { TextField } from '@mui/material'
import { SuppliesRequestContextType, useSuppliesRequestContext } from '@pages/SuppliesRequest/context/RequestList.context'

import { webApiService } from '@services/index'
import React from 'react'

export interface IParam {
  id_request: string
  order_number: string
  name_client: string
  user_asignate: string
  date_register: string
}

export interface IOwnProps {
    nameField: any
}

const SearchColumn : React.FC<IOwnProps> = ({ nameField }) => {
  const { suppliesRequestCtx, saveSuppliesRequestCtx } = useSuppliesRequestContext() as SuppliesRequestContextType
  const [listStatus, setListStatus] = React.useState<{ data: any, loading: boolean }>({ data: null, loading: false })

  const loadData = async () => {
    setListStatus({ ...listStatus, loading: true })

    const response : any = await Promise.all([
      webApiService.getAllStatusFilter().then((response) => response)
    ]).catch((error) => {
      console.log(error)
    })
    setListStatus({ ...listStatus, data: response[0].items })
  }

  React.useEffect(() => {
    if (nameField === 'status') loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextField id={'search-' + nameField} label='buscar' type='search' size='medium' onChange={(e) => saveSuppliesRequestCtx({ ...suppliesRequestCtx, params: { ...suppliesRequestCtx.params, [nameField]: e.target.value } })} />
  )
}

export default SearchColumn

import { Typography } from '@mui/material'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Acquisitions from './components/Acquisitions/Acquisitions'
import Buttons from './components/Buttons'
import Category from './components/Category'
import LogSupply from './components/LogSupply'
import Name from './components/Name'
import Others from './components/Others'
import Sku from './components/Sku'
import { SupplyContextType, useSupplyContext } from './context/Supply.context'

const SuppliesPage = () => {
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { supplyCtx, saveSupplyCtx } = useSupplyContext() as SupplyContextType

  const loadData = async () => {
    saveSupplyCtx({ ...supplyCtx, loading: true })
    const response : any = await Promise.all([
      webApiService.getSupplyById(Number(id)).then((response) => response),
      webApiService.getHistoryById(Number(id)).then((response) => response)
    ]).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    })
    saveSupplyCtx({ ...supplyCtx, supply: response[0].data, loading: false, log: response[1].data })
  }

  useEffect(() => {
    if (id !== undefined && id !== 'new') loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className='container-xxl py-4'>
      <div className='row'>
        <div className='col-12 position-relative'>
          <Typography variant='h6' className='title mb-3'>
            {(id === undefined || id === 'new') ? 'Nuevo Insumo' : 'Edición insumo'}
          </Typography>
        </div>
        <div className='col-12'>
          <div className='shadow-sm rounded bg-white p-3'>
            {
            supplyCtx.loading
              ? (<div className='container-xxl bg-white'><div className='row gy-3'><div className='col-12'>Cargando...</div></div></div>)
              : (
                <div className='container-xxl bg-white'>
                  <div className='row gy-3'>
                    <div className='col-12 text-end' hidden={supplyCtx.supply.id === 0}>
                      <Typography variant='h6' className={supplyCtx.supply.inventory > 0 ? 'badge rounded-pill bg-success ms-2 m-0' : 'badge rounded-pill bg-danger ms-2 m-0'}><strong>{supplyCtx.supply.inventory}</strong> unidades disponibles</Typography>
                    </div>
                    <div className='col-12 col-lg-6'>
                      <Name idSupply={(id !== undefined && id !== 'new') ? Number(id) : 0} />
                    </div>
                    <div className='col-12 col-lg-6'>
                      <Sku />
                    </div>
                    <div className='col-12'><hr /></div>
                    <div className='col-12'>
                      <Category />
                    </div>
                    <div className='col-12'><hr /></div>
                    <div className='col-12'>
                      <Others />
                    </div>
                    <div className='col-12'><hr /></div>
                    <div className='col-12'>
                      <Acquisitions />
                    </div>
                    {
                      (id !== undefined && id !== 'new')
                        ? (
                          <>
                            <div className='col-12'><hr /></div>
                            <div className='col-12'>
                              <LogSupply />
                            </div>
                          </>
                          )
                        : null
                    }
                    <div className='col-12'><hr /></div>
                    <div className='col-12'>
                      <Buttons />
                    </div>
                  </div>
                </div>
                )
}

          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliesPage

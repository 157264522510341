import { TextField, Typography } from '@mui/material'
import { webApiService } from '@services/index'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import MyCustomToolbarSelect from './components/MyCustomToolbarSelect'
// import * as XLSX from 'xlsx'

const SuppliesStockPage = ({ open }: any) => {
  const [data, setData] = useState<{supplies:any[], loading: boolean, loadingExcel: boolean, files: any[], newSupplies: any[], loadingUpdate: boolean, logHtml: string, invoiceBase64: any, modificados: { id: number, stock: number, cost: number, my_serials: string[]}[], invoice: string, provider: string }>({ supplies: [], loading: false, loadingExcel: false, files: [], newSupplies: [], loadingUpdate: false, logHtml: '', invoiceBase64: '', modificados: [], invoice: '', provider: '' })

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'category',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'variant_color',
      label: 'Variante ó Color',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'family',
      label: 'Familia',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'subfamily',
      label: 'Sub Familia',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'inventory',
      label: 'Inventario',
      options: {
        filter: true,
        sort: true,
        display: true
      }
    },
    {
      name: 'new_stock',
      label: 'Stock Adicional',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return (
            <TextField
              required
              value={data.modificados.find(e => e.id === data.supplies[tableMeta.rowIndex].id) !== undefined ? data.modificados.find(e => e.id === data.supplies[tableMeta.rowIndex].id)?.stock : 0}
              className='input-stock'
              type='text'
              size='small'
              inputProps={{ inputMode: 'numeric' }}
              onChange={event => {
                if (event.target.value.match(/[^0-9]/)) { return event.preventDefault() }
                const newModificados = [...data.modificados]
                if (data.modificados.some(e => e.id === data.supplies[tableMeta.rowIndex].id)) {
                  const indice = newModificados.findIndex(e => e.id === data.supplies[tableMeta.rowIndex].id)
                  newModificados[indice].stock = Number(event.target.value)
                } else {
                  newModificados.push({ stock: Number(event.target.value), my_serials: [], id: data.supplies[tableMeta.rowIndex].id, cost: 0 })
                }
                setData({ ...data, modificados: newModificados })
              }}
            />
          )
        }
      }

    },
    {
      name: 'cost',
      label: 'Costo Unitario',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return (
            <TextField
              required
              value={data.modificados.find(e => e.id === data.supplies[tableMeta.rowIndex].id) !== undefined ? data.modificados.find(e => e.id === data.supplies[tableMeta.rowIndex].id)?.cost : 0}
              className='input-stock'
              type='text'
              size='small'
              inputProps={{ inputMode: 'numeric' }}
              onChange={event => {
                if (event.target.value.match(/[^0-9]/)) { return event.preventDefault() }
                const newModificados = [...data.modificados]
                if (data.modificados.some(e => e.id === data.supplies[tableMeta.rowIndex].id)) {
                  const indice = newModificados.findIndex(e => e.id === data.supplies[tableMeta.rowIndex].id)
                  newModificados[indice].cost = Number(event.target.value)
                } else {
                  newModificados.push({ cost: Number(event.target.value), my_serials: [], id: data.supplies[tableMeta.rowIndex].id, stock: 0 })
                }
                setData({ ...data, modificados: newModificados })
              }}
            />
          )
        }
      }
    },
    // {
    //   name: 'xls_serial',
    //   label: 'Adjuntar seriales',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
    //       if (data.loadingExcel) return 'Cargando'

    //       return (
    //         <Button
    //           variant='contained'
    //           component='label'
    //           className='d-none'
    //         >
    //           Cargar
    //           <input
    //             type='file'
    //             hidden
    //             accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    //             onChange={(e : React.ChangeEvent<HTMLInputElement>) => {
    //               setData({ ...data, loadingExcel: true })

    //               if (e.target.files?.length) {
    //                 const reader = new FileReader()
    //                 reader.onload = async (e) => {
    //                   const newModificados = [...data.modificados]
    //                   // validacion

    //                   const binaryData = e.target?.result
    //                   const workbook = XLSX.read(binaryData, { type: 'binary' })
    //                   const sheetName = workbook.SheetNames[0]
    //                   const worksheet = workbook.Sheets[sheetName]
    //                   const excelData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

    //                   const datos : string[] = []

    //                   excelData.forEach(e => {
    //                     datos.push(e[0])
    //                   })

    //                   if (data.modificados.some(e => e.id === data.supplies[tableMeta.rowIndex].id)) {
    //                     const indice = newModificados.findIndex(e => e.id === data.supplies[tableMeta.rowIndex].id)
    //                     newModificados[indice].my_serials = datos
    //                   } else {
    //                     newModificados.push({ cost: 0, my_serials: datos, id: data.supplies[tableMeta.rowIndex].id, stock: 0 })
    //                   }
    //                   setData({ ...data, modificados: newModificados, loadingExcel: false })
    //                 }

    //                 reader.readAsBinaryString(e.target.files[0])
    //               }
    //             }}
    //           />
    //         </Button>
    //       )
    //     }
    //   }
    // },
    {
      name: 'invoice',
      label: 'N° Boleta/Factura',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return (
            <TextField
              required value={data.invoice}
              className='input-stock'
              type='text'
              size='small'
              onChange={event => {
                setData({ ...data, invoice: event.target.value })
              }}
            />
          )
        }
      }
    },
    {
      name: 'provider',
      label: 'Proveedor',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return (
            <TextField
              required
              value={data.provider}
              className='input-stock'
              type='text'
              size='small'
              onChange={event => {
                setData({ ...data, provider: event.target.value })
              }}
            />
          )
        }
      }
    }
  ]

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getSuppliesList().then((response) => {
      response.data.forEach((element:any) => {
        element.new_stock = 0
        element.cost = 0
        element.xlx_serial = 0
        element.provider = ''
        element.invoice = ''
      })

      setData({ ...data, supplies: response.data, loading: false, provider: '', invoice: '', modificados: [] })
    }).catch((error) => {
      console.log(error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='container-xxl '>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            Actualización masiva Stock
          </Typography>

          <div className='shadow-sm rounded bg-white p-3'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='alert alert-info text-start p-3 d-block' role='alert'>
                    <strong>Instrucciones</strong>
                    <hr />
                    <p className='mb-0'>Deberá seleccionar los insumos a modificar, luego completar la cantidad y el costo <strong>unitario</strong>.</p>
                  </div>
                </div>
                <div className='col-12 dataTable'>
                  {
                    data.loading
                      ? (<p>Cargando...</p>)
                      : (
                        <MUIDataTable
                          title=''
                          data={data.supplies}
                          columns={columns}
                          options={{
                            selectableRowsHideCheckboxes: false,
                            print: false,
                            download: false,
                            viewColumns: false,
                            textLabels: {
                              selectedRows: { text: 'Filas seleccionadas' }
                            },
                            onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[] | undefined) => {

                            },
                            customToolbarSelect: selectedRows => {
                              // console.log('selectedRows', selectedRows)
                              return selectedRows.data.length && selectedRows.data.length === data.modificados.length && !data.modificados.some(e => e.cost === 0 || e.stock === 0)
                                ? (
                                  <MyCustomToolbarSelect
                                    supplies={data.supplies}
                                    modificados={data.modificados}
                                    provider={data.provider}
                                    invoice={data.invoice}
                                    updateTable={loadData}
                                  />
                                  )
                                : null
                            }
                          }}
                        />
                        )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliesStockPage

export const blobToBase64 = async (file: Blob): Promise<string | undefined> => {
  const reader = new FileReader()
  reader.readAsDataURL(file as Blob)

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as any)
    }
    reader.onerror = (error) => {
      reject(error)
    }
  })
}

import { BrowserRouter, Navigate, Route } from 'react-router-dom'
import './theme/App.scss'

import { lazy } from 'react'

import { Layout } from '@components/Layout'
import { Loadable } from '@components/Loader'
import { RoutesWithNotFound } from '@components/RoutesWithNotFound'
import ScrollToTop from '@components/ScrollTop/ScrollTop'
import { OTFormProvider } from '@pages/OTForm'
import { OTFormIMEIProvider } from '@pages/OTFormImei/context/OTFormIMEI.context'
import { PrivateRoutes, PublicRoutes } from './constants/routes.constants'
import { AuthGuard, RoleAndUserGuard } from './guards'
import { SuppliesProvider } from '@pages/SuppliesManagement'
import { SuppliesRequestProvider } from '@pages/SuppliesRequest'
import NewSupply from '@pages/NewSupply/components/NewSupply/NewSupply'
import EditSupply from '@pages/SuppliesEdit/components/EditSupply/EditSupply'
import { SuppliesPage } from '@pages/SuppliesPage'
import { SuppliesListPage } from '@pages/SuppliesList'
import { SupplyProvider } from '@pages/SuppliesPage/context/Supply.context'
import SuppliesStockPage from '@pages/SuppliesStock/SuppliesStockPage'
import OTSuppliesReport from '@pages/OTSuppliesReport/OTSuppliesReport'
import DeliverySuppliesPage from '@pages/DeliverySupplies/DeliverySuppliesPage'
import DeliverySuppliesOTPage from '@pages/DeliverySuppliesOT/DeliverySuppliesOTPage'
import SuppliesReportPage from '@pages/SuppliesReport/SuppliesReportPage'

/// page components
const LoginPage = Loadable(lazy(() => import('@pages/LoginPage/LoginPage')))
const OTFormPage = Loadable(lazy(() => import('@pages/OTForm/OTFormPage')))
const OTFormbyImeiPage = Loadable(lazy(() => import('@pages/OTFormImei/OTFormbyImei')))
const OTListPage = Loadable(lazy(() => import('@pages/OTList/OTListPage')))
const OTReport = Loadable(lazy(() => import('@pages/OTReport/OTReport')))
const OTReportStatus = Loadable(lazy(() => import('@pages/OTReportStatus/OTReportStatus')))
const ProfilePage = Loadable(lazy(() => import('@pages/Profile/ProfilePage')))
const OTlockedPage = Loadable(lazy(() => import('@pages/OTLocked/OTlockedPage')))
const SuppliesManagementPage = Loadable(lazy(() => import('@pages/SuppliesManagement/SuppliesManagementPage')))
const SuppliesRequestPage = Loadable(lazy(() => import('@pages/SuppliesRequest/SuppliesRequestPage')))
const ThanksPage = Loadable(lazy(() => import('@pages/Thanks/ThanksPage')))

function App () {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RoutesWithNotFound>
        <Route path='/' element={<Navigate to={PrivateRoutes.OTLIST} replace />} />
        <Route path={PublicRoutes.LOGIN} element={<LoginPage />} />
        <Route element={<Layout />}>
          <Route path={PrivateRoutes.THANKS} element={<ThanksPage />} />
          <Route element={<AuthGuard privateValidation />}>
            <Route path={PrivateRoutes.OTLIST} element={<OTListPage />} />
            <Route path={PrivateRoutes.OTFORM + ':id'} element={<OTFormProvider><OTFormPage /></OTFormProvider>} />
            <Route path={PrivateRoutes.OTFORMIMEI + ':id'} element={<OTFormIMEIProvider><OTFormbyImeiPage /></OTFormIMEIProvider>} />
            <Route element={<RoleAndUserGuard roles={String(process.env.REACT_APP_ROLE_VIEW_REPORTS).split(',').map(e => Number(e))} users={[11]} PublicValidationFragment={<Navigate replace to={PrivateRoutes.OTLIST} />} />}>
              <Route path={PrivateRoutes.OTREPORT} element={<OTReport />} />
              <Route path={PrivateRoutes.OTREPORTSTATUS} element={<OTReportStatus />} />
              <Route path={PrivateRoutes.OTSUPPLIESREPORT} element={<OTSuppliesReport />} />
              <Route path={PrivateRoutes.SUPPLIESREPORT} element={<SuppliesReportPage />} />
            </Route>
            <Route path={PrivateRoutes.PROFILE} element={<ProfilePage />} />
            <Route path={PrivateRoutes.OTBLOCKED} element={<OTlockedPage />} />
            <Route path={PrivateRoutes.SUPPLIES_MANAGEMENT} element={<SuppliesProvider><SuppliesManagementPage /></SuppliesProvider>} />
            <Route path={PrivateRoutes.NEW_SUPPLY} element={<NewSupply />} />
            <Route path={PrivateRoutes.EDIT_SUPPLY + ':id'} element={<EditSupply />} />
            <Route path={PrivateRoutes.SUPPLIES_REQUEST} element={<SuppliesRequestProvider><SuppliesRequestPage /></SuppliesRequestProvider>} />
            <Route path={PrivateRoutes.SUPPLIES_DELIVERY} element={<DeliverySuppliesPage />} />
            <Route path={PrivateRoutes.SUPPLIES_DELIVERYOT} element={<DeliverySuppliesOTPage />} />

            <Route path={PrivateRoutes.SUPPLIES_LIST} element={<SuppliesListPage />} />
            <Route path={PrivateRoutes.SUPPLY + ':id'} element={<SupplyProvider><SuppliesPage /></SupplyProvider>} />
            <Route path={PrivateRoutes.SUPPLIES_STOCK} element={<SuppliesStockPage />} />
          </Route>
        </Route>
      </RoutesWithNotFound>
    </BrowserRouter>
  )
}

export default App

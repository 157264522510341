import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import React from 'react'
import { SupplyContextType, useSupplyContext } from '../context/Supply.context'
import { useNavigate } from 'react-router-dom'
import { PrivateRoutes } from '@constants/routes.constants'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
// import { sendInvoiceToS3 } from '@utils/s3Utils'
// import { fileName } from '@utils/fileName.util'

const Buttons = () => {
  const { supplyCtx, saveSupplyCtx } = useSupplyContext() as SupplyContextType
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const saveSupply = async () => {
    saveSupplyCtx({ ...supplyCtx, loading: true })
    // let lastInvoice = ''

    // console.log('supplyCtx.supply.fileInvoice', supplyCtx.supply.fileInvoice)
    // if (supplyCtx.supply.fileInvoice !== null && supplyCtx.supply.fileInvoice !== undefined) {
    //   const invoiceS3 = await sendInvoiceToS3(fileName(supplyCtx.supply.fileInvoice.name, 'new'), supplyCtx.supply.fileInvoice)
    //   lastInvoice = invoiceS3.Location
    // }

    webApiService.saveSupplies(supplyCtx.supply).then(res => {
      if (supplyCtx.supply.id === 0) { enqueueSnackbar(`El insumo N° ${res.data.id} fue creado`, { variant: 'success' }) } else {
        enqueueSnackbar(`El insumo N° ${res.data.id} fue actualizado`, { variant: 'success' })
      }
      navigate(PrivateRoutes.SUPPLIES_LIST)
    }).catch((error) => {
      console.log(error)
      if (error.response.data.message.indexOf('Duplicate') >= 0) { enqueueSnackbar('Ya existe un insumo con estos datos.', { variant: 'error' }) } else {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    }).finally(() => {
      saveSupplyCtx({ ...supplyCtx, loading: false })
    })
  }

  return (
    <div className='d-flex justify-content-end mt-2'>
      <Button
        variant='contained'
        size='medium'
        color='secondary'
        className='m-2 btn-secondary'
        onClick={() => navigate(PrivateRoutes.SUPPLIES_LIST)}
      >
        Cancelar
      </Button>
      <LoadingButton
        variant='contained'
        size='medium'
        color='primary'
        className='m-2'
        loading={supplyCtx.loading}
        onClick={saveSupply}
      >
        Guardar
      </LoadingButton>
    </div>
  )
}

export default Buttons

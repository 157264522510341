import { SupplyEmpty } from '@constants/EmptyObjects.constants'
import { ISupply } from '@models/Supply.model'
import { createContext, useContext, useState } from 'react'

interface IOwnProps {
  supply: ISupply
  log: any[]
  loading: boolean,
  errorValidation: boolean
}

export type SupplyContextType = {
  supplyCtx: IOwnProps;
  saveSupplyCtx: (supplyCtx: IOwnProps) => void;
};

export const SupplyContext = createContext<SupplyContextType | null>(null)

export const SupplyProvider = ({ children } : any) => {
  const [supplyCtx, setSupplyCtx] = useState<IOwnProps>({ supply: SupplyEmpty, loading: false, errorValidation: false, log: [] })

  const saveSupplyCtx = (_supplyCtx: IOwnProps) => {
    setSupplyCtx(_supplyCtx)
  }

  return <SupplyContext.Provider value={{ supplyCtx, saveSupplyCtx }}>{children}</SupplyContext.Provider>
}

export const useSupplyContext = () => {
  const context = useContext(SupplyContext)
  if (context === undefined) {
    throw new Error('SupplyContext must be used within a SupplyProvider')
  }
  return context
}

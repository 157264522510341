import { CloseIcon, DeleteOutlineOutlinedIcon, ImageOutlinedIcon } from '@assets/icons/Icons'
import { ModalNames } from '@constants/modal.constants'
import { ModalState } from '@models/modals.model'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import * as XLSX from 'xlsx'
import { Transition } from '@constants/TransitionModal.contants'
import { useSnackbar } from 'notistack'
import { useDropzone } from 'react-dropzone'
import { webApiService } from '@services/index'
interface IOwnProps {
    supplies: any[],
    modificados: any[],
    provider: string,
    invoice:string,
    updateTable: () => void
}

const MyCustomToolbarSelect: React.FC<IOwnProps> = ({ supplies, modificados, provider, invoice, updateTable }) => {
  const [data, setData] = useState<{ modal: ModalState, files: any[], invoiceBase64: any, logHtml: string, loadingUpdate: boolean }>({ loadingUpdate: false, files: [], logHtml: '', invoiceBase64: '', modal: { visible: false, name: ModalNames.BULKSTOCKLOAD, parametros: {} } })
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setData({ ...data, modal: { ...data.modal, visible: false }, invoiceBase64: '', files: [], loadingUpdate: false, logHtml: '' })
    updateTable()
  }

  const removeFile = (file:any) => {
    setData({ ...data, files: [], invoiceBase64: '' })
  }

  const ifExist = (file :any) => {
    if (data.files.some(e => e.name === file.name)) {
      return {
        code: 'name-duplicate',
        message: 'El archivo esta duplicado'
      }
    }

    if (file.size > parseInt((process.env.REACT_APP_MAXFILESIZE !== undefined) ? process.env.REACT_APP_MAXFILESIZE : '2097152')) {
      return {
        code: 'big-size',
        message: 'El archivo excede el tamaño máximo permitido'
      }
    }

    return null
  }

  const { getRootProps, getInputProps, isDragAccept, isDragReject, fileRejections } = useDropzone({
    maxFiles: 1,
    maxSize: (process.env.REACT_APP_MAXFILESIZE !== undefined) ? parseInt(process.env.REACT_APP_MAXFILESIZE) : 2097152,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles.length) {
        const filePdf = acceptedFiles[0]
        const reader = new FileReader()
        reader.onload = async (e) => {
          setData({ ...data, files: (data.files.length === 0) ? acceptedFiles : data.files.concat(acceptedFiles), invoiceBase64: e.target?.result })
        }
        reader.readAsDataURL(filePdf)
      }
    },
    validator: ifExist
  })

  const validationFiles = () => {
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'name-duplicate'))) enqueueSnackbar('Ya existe un archivo con este nombre.', { variant: 'warning' })
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'big-size'))) enqueueSnackbar('El archivo excede el tamaño máximo permitido', { variant: 'warning' })
  }

  const updateStock = async () => {
    setData({ ...data, loadingUpdate: true })
    let html = '----- INICIO -----<br/>'

    const arregloIds : number[] = []

    // Recorremos el arreglo original
    modificados.forEach(objeto => {
    // Verificamos si la propiedad existe en el nuevo arreglo
      if (!arregloIds.includes(objeto.id)) {
        // Si no existe, la agregamos al nuevo arreglo
        arregloIds.push(objeto.id)
      }
    })

    supplies.filter((e: any) => arregloIds.includes(e.id)).forEach(async e => {
      const supplyTemp = {
        invoiceBase64: data.invoiceBase64,
        provider,
        invoice,
        id: e.id,
        stock: modificados.find(el => el.id === e.id).stock,
        costo: modificados.find(el => el.id === e.id).cost,
        my_serials: modificados.find(el => el.id === e.id).my_serials
      }

      // eslint-disable-next-line no-loop-func
      await webApiService.updateSupplyStock(supplyTemp).then((res) => {
        html += 'Insumo N°: ' + res.data.id + ' actualizado<br/>'
      }).catch(err => {
        html += 'Error al cargar insumo N°: ' + supplyTemp.id + '<br/>'
        console.log(err)
      })
      setData({ ...data, logHtml: html })
    })
  }

  useEffect(() => {
    validationFiles()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.files])

  return (
    <div>
      <Button component='label' hidden={modificados.length === 0 || provider === '' || invoice === ''} variant='contained' color='primary' className='mx-1' onClick={() => setData({ ...data, modal: { ...data.modal, visible: true } })}>Cargar Factura</Button>
      <Dialog
        open={data.modal.visible && data.modal.name === ModalNames.BULKSTOCKLOAD}
        TransitionComponent={Transition}
        aria-labelledby='responsive-dialog-title'
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
          <Toolbar>
            <Typography variant='h6' component='div' className='color-title'>
              <CloudUploadIcon className='me-2' /><span>CARGAR FACTURA PARA INSUMOS</span>
            </Typography>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#fff'
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </DialogTitle>
        <DialogContent dividers>
          <Card className='shadow-sm'>
            <CardContent>
              <div className='row gy-2'>
                <div className='col-12'>
                  <div className='alert alert-info text-start p-3 d-block' role='alert'>
                    <strong>Instrucciones</strong>
                    <hr />
                    <ul className='small'>
                      <li>Cargar archivo <strong>PDF</strong> de la factura de los insumos a comprados</li>
                    </ul>
                  </div>
                </div>
                {
                data.logHtml === '' && data.files.map((file: any) => (

                  <div key={file.nameFile !== undefined ? file.nameFile : file.name} className='col-12'>
                    <div className={file.nameFile !== undefined ? 'fileContainer rounded border border-primary p-2 d-flex flex-row' : 'fileContainer rounded border border-success p-2 d-flex flex-row'}>
                      <div className='fileTypeImg'><ImageOutlinedIcon color='primary' sx={{ fontSize: 25 }} /></div>
                      <div className='fileInfo ms-2'>
                        <p className='small mb-0 fw-bold'>{file.nameFile !== undefined ? file.nameFile : file.name}</p>
                        <p className='small mb-0'>{(file.size / 1024 / 1024).toFixed(3)} Mb</p>
                      </div>
                      <div className='fileActions align-self-end'>
                        <div className='fileActionImg'>
                          <DeleteOutlineOutlinedIcon className='fileTrash' onClick={() => removeFile(file)} color='action' sx={{ fontSize: 25 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
                <div className='col-12'>
                  <LoadingButton
                    variant='contained'
                    size='medium'
                    color='primary'
                    className='m-2'
                    onClick={updateStock}
                    loading={data.loadingUpdate}
                    hidden={data.files.length < 1 || data.logHtml !== ''}
                  >
                    Actualizar Stocks
                  </LoadingButton>
                </div>
                <div className={(data.files.length === 1) ? 'd-none' : 'col-12'}>
                  <div {...getRootProps({ className: isDragAccept ? 'p-5 dropzone dropzone-accept' : isDragReject ? 'p-5 dropzone dropzone-reject' : 'p-5 dropzone' })}>
                    <input {...getInputProps()} />
                    <div className='alert alert-danger text-center p-3' role='alert'>
                      <p>El archivo que esta intentando adjuntar no esta permitido</p>
                      <hr />
                      <p className='mb-0 small'>* Archivo permitido solo: <strong>PDF</strong></p>
                    </div>
                    <div className='text-center instrucciones'>

                      <Button
                        variant='contained'
                        size='medium'
                        color='primary'
                        className='m-2'
                      >
                        Seleccione PDF
                      </Button>
                      <p className='mt-3 mb-0'><strong>Haga click para subir el PDF</strong> o arrastre y suelte aquí.</p>
                      <p>PDF (tamaño máximo 2 mb)</p>
                    </div>
                  </div>
                </div>
                <div className='col-12' hidden={data.logHtml === ''}>
                  <small>Log</small>
                  <div className='bg-dark p-4 text-white small fw-bold' dangerouslySetInnerHTML={{ __html: data.logHtml }} />
                </div>
                <div className='col-12 text-end' />
              </div>

            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MyCustomToolbarSelect

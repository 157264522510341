
import { IParamRequestReportService, IParamRequestReportStatusService, IParamRequestService, IParamSuppliesRequestService, IParamSuppliesService } from '@models/index'
import { jsonToQueryStringParam } from '@utils/parse.util'
import axios, { CancelTokenSource } from 'axios'

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8', Accept: 'text/json' }
})

export const sourceAxios = axios.CancelToken.source()

export const webApiService = {
  getUserProfile: async (_email: string, _poolId: string) => {
    const url = `${process.env.REACT_APP_SERVICE_USER_PROFILE}getByEmailAndPoolId/${_email}/${_poolId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getRequest: async (_idOT: number) => {
    const url = `${process.env.REACT_APP_CRUD_REQUEST}get/${_idOT}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllRequest: async (_objParam: IParamRequestService) => {
    const url = `${process.env.REACT_APP_CRUD_REQUEST}getAll${jsonToQueryStringParam(_objParam)}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllRequestReport: async (_objParam: IParamRequestReportService) => {
    const url = `${process.env.REACT_APP_CRUD_REQUEST}getAllReport${jsonToQueryStringParam(_objParam)}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllByOrderList: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getAllByOrderList`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllRequestReportStatus: async (_objParam: IParamRequestReportStatusService) => {
    const url = `${process.env.REACT_APP_CRUD_HISTORY_REQUEST}getAllHistory`
    return await axiosInstance.request({ method: 'POST', url, data: _objParam }).then((res: any) => res.data)
  },
  saveRequest: async (_request: any) => {
    const url = `${process.env.REACT_APP_CRUD_REQUEST}save/`
    return await axiosInstance.request({ method: 'POST', url, data: _request }).then((res: any) => res.data)
  },
  saveRequestIMEI: async (_request: any) => {
    const url = `${process.env.REACT_APP_CRUD_REQUEST}saveImei/`
    return await axiosInstance.request({ method: 'POST', url, data: _request }).then((res: any) => res.data)
  },
  lockRequest: async (_idOT: number, _idUser: number, _lock: boolean) => {
    const url = `${process.env.REACT_APP_SERVICE_LOCK_REQUEST}lockRequest`
    return await axiosInstance.request({ method: 'POST', url, data: { id_request: _idOT, id_user: _idUser, lock: (_lock) ? 1 : 0 } }).then((res: any) => res.data)
  },
  getAllLocked: async () => {
    const url = `${process.env.REACT_APP_SERVICE_LOCK_REQUEST}getAllLocked/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllUsers: async (_idRoles: string, _cognitoId: string) => {
    const url = `${process.env.REACT_APP_CRUD_USER}getAllAreaByRole/${_idRoles}/${_cognitoId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getHistoryStatus: async (_idOT: number) => {
    const url = `${process.env.REACT_APP_CRUD_HISTORY_REQUEST}GetAllByIdRequest/${_idOT}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllStatusFilter: async () => {
    const url = `${process.env.REACT_APP_CRUD_STATUS_REQUEST}getAllByFilter/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllListStatus: async (idStatus: number, idCompanyArea: number, idFlow: number) => {
    const url = `${process.env.REACT_APP_CRUD_STATUS_REQUEST}getAllStatus/${idStatus}/${idCompanyArea}/${idFlow}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getDocumentBsale: async (_tipo: string, _invoiceId: string) => {
    const url = `${process.env.REACT_APP_SERVICE_DOCUMENT_BSALE}getDocument/${_tipo}/${_invoiceId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getDocumentOfficeGuideBsale: async (idOfficeGuide: string) => {
    const url = `${process.env.REACT_APP_SERVICE_DOCUMENT_BSALE}getOfficeGuide/${idOfficeGuide}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },

  getAllPCheck: async (_serial: string) => {
    const url = `${process.env.REACT_APP_CRUD_PHONECHECK}getByImei/${_serial}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getRecordByImei: async (_serial: string) => {
    const url = `${process.env.REACT_APP_CRUD_PHONECHECK}getRecordByImei/${_serial}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllFailure: async () => {
    const url = `${process.env.REACT_APP_CRUD_FAILURE}GetAll/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllComponents: async () => {
    const url = `${process.env.REACT_APP_CRUD_COMPONENTS}GetAll/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllWarehouses: async () => {
    const url = `${process.env.REACT_APP_CRUD_WAREHOUSES}GetAll/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllChannels: async () => {
    const url = `${process.env.REACT_APP_CRUD_CHANNEL}GetAll/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllSbu: async () => {
    const url = `${process.env.REACT_APP_CRUD_SBU}GetAll/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllCosmeticStatus: async () => {
    const url = `${process.env.REACT_APP_CRUD_COSMETICS}getAll/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllFunctionality: async () => {
    const url = `${process.env.REACT_APP_CRUD_PRODUCT_FUNCTIONALITY}getAll/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSKUByName: async (name: string) => {
    const url = `${process.env.REACT_APP_SERVICE_SKU_BSALE}GetSku/${name}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  searchProductBsale: async (q: string, type: number) => {
    const url = (type === 1) ? `${process.env.REACT_APP_SERVICE_SEARCH_BSALE}searchByName/${q}` : `${process.env.REACT_APP_SERVICE_SEARCH_BSALE}searchBySku/${q}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllSuppliesCategory: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getType/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllSuppliesFamily: async (_parentId: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getAllFamily/${_parentId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSupply: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getSupply/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getByOrder: async (_idRequestSt: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getByOrder/${_idRequestSt}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getByRequestStatus: async (_idRequestSt: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getByRequestStatusPurchased/${_idRequestSt}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllSupplies: async (_objParam: IParamSuppliesService) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getSupplyAndTypeName${jsonToQueryStringParam(_objParam)}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  saveSupplies: async (_supply: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}saveSupplies`
    return await axiosInstance.request({ method: 'POST', url, data: _supply }).then((res: any) => res.data)
  },
  getAllForUpdate: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getAllForUpdate`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSuppliesList: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getAllSupplies`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSuppliesRequest: async (_objParam: IParamSuppliesRequestService, source: CancelTokenSource) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getSuppliesRequest${jsonToQueryStringParam(_objParam)}`
    return await axiosInstance.request({ method: 'GET', url, cancelToken: source.token }).then((res: any) => res.data)
  },
  getSuppliesRequestById: async (_idRequestSt: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getSuppliesRequestById/${_idRequestSt}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllById: async (_idSupply: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getAllById/${_idSupply}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSupplyById: async (_idSupply: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}get/${_idSupply}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getHistoryById: async (_idSupply: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getHistoryById/${_idSupply}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSuppliesByCatAndFam: async (_idCat: number, _idFam: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getByCatAndFam/${_idCat}/${_idFam}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getByRequestId: async (_idType: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getByRequestId/${_idType}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  createSupply: async (_supply: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}createSupplyType`
    return await axiosInstance.request({ method: 'POST', url, data: _supply }).then((res: any) => res.data)
  },
  updateSupply: async (_supply: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}updateSupply`
    return await axiosInstance.request({ method: 'POST', url, data: _supply }).then((res: any) => res.data)
  },
  registerInvoice: async (_supply: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}registerInvoice`
    return await axiosInstance.request({ method: 'POST', url, data: _supply }).then((res: any) => res.data)
  },
  createHistoryRecord: async (_supply: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}createHistoryRecord`
    return await axiosInstance.request({ method: 'POST', url, data: _supply }).then((res: any) => res.data)
  },
  deleteSupplyOrder: async (_supply: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}deleteSupplyOrder`
    return await axiosInstance.request({ method: 'POST', url, data: _supply }).then((res: any) => res.data)
  },
  otBulkImport: async (otPendings: any) => {
    const url = `${process.env.REACT_APP_CRUD_BSALE_PRODUCT as string}otBulkImport`
    return await axiosInstance.request({ method: 'POST', data: otPendings, url }).then((res: any) => res.data)
  },
  getAllProvider: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getAllProvider`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  updateSupplyStock: async (supply: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}updateSupplyStock`
    return await axiosInstance.request({ method: 'POST', url, data: supply }).then((res: any) => res.data)
  },
  getAllSerial: async (idSupply: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getAllSerial/${idSupply}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSupplyForDelivery: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getSupplyForDelivery`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getOtBySupply: async (idSupply: number) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getOtBySupply/${idSupply}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  updateSupplyOrder: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}updateSupplyOrder`
    return await axiosInstance.request({ method: 'POST', data, url }).then((res: any) => res.data)
  },
  getOTSuppliesDelivered: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getOTSuppliesDelivered`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSuppliesByOT: async (idOT: string) => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getSuppliesByOT/${idOT}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getDeliveredReport: async () => {
    const url = `${process.env.REACT_APP_CRUD_SUPPLIES}getDeliveredReport`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  setPriority: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_REQUEST}setPriority`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  }
}

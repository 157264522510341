import { ReactNode } from 'react'

interface IOwnProps {
    listDiagnostic: string[]
    diagnostic: string
    PublicValidationFragment: any
    children?: ReactNode
}

export const OTDiagnosticGuard = ({ listDiagnostic, diagnostic, children, PublicValidationFragment }: IOwnProps) => {
  return listDiagnostic.includes(diagnostic)
    ? (children)
    : (
        PublicValidationFragment
      )
}

export default OTDiagnosticGuard

import { Autocomplete, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { CloseIcon, DeleteOutlineOutlinedIcon, ImageOutlinedIcon } from '@assets/icons/Icons'
import { Transition } from '@constants/TransitionModal.contants'
import { ModalNames } from '@constants/modal.constants'
import { IAcquisitions } from '@models/Supply.model'
import { LoadingButton } from '@mui/lab'
import { webApiService } from '@services/index'
// import { fileName } from '@utils/fileName.util'
// import { sendInvoiceToS3 } from '@utils/s3Utils'
import { useSnackbar } from 'notistack'

import ReceiptIcon from '@mui/icons-material/Receipt'
import { ModalProps } from '@models/modals.model'
import { useDropzone } from 'react-dropzone'
import { SupplyContextType, useSupplyContext } from '@pages/SuppliesPage/context/Supply.context'

const ModalAddAcquisition: React.FC<ModalProps> = ({ modal, setModal }) => {
  const { supplyCtx, saveSupplyCtx } = useSupplyContext() as SupplyContextType
  const [data, setData] = useState<{factura: IAcquisitions, loading: boolean, listProvider: any[], fileList: any[] }>({ factura: { id: 0, quantity: 0, cost: 0, invoice: { id: 0, provider: { id: 0, name: '' }, invoice_number: '', url: '', invoiceBase64: '' } }, loading: false, listProvider: [], fileList: [] })

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setData({ ...data, factura: { id: 0, quantity: 0, cost: 0, invoice: { id: 0, provider: { id: 0, name: '' }, invoice_number: '', url: '', invoiceBase64: '' } }, fileList: [] })
    setModal({ ...modal, visible: false, name: ModalNames.ADDACQUISITION })
  }

  const loadData = async () => {
    setData({ ...data, loading: true })

    webApiService.getAllProvider().then((res) => {
      if (res.success) {
        setData({ ...data, loading: false, listProvider: res.data })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    })
  }

  const removeFile = (file:any) => {
    const newFiles = [...data.fileList]
    newFiles.splice(newFiles.indexOf(file), 1)
    setData({ ...data, fileList: newFiles })
  }

  const ifExist = (file :any) => {
    if (data.fileList.some(e => e.name === file.name)) {
      return {
        code: 'name-duplicate',
        message: 'El archivo esta duplicado'
      }
    }

    if (file.size > parseInt((process.env.REACT_APP_MAXFILESIZE !== undefined) ? process.env.REACT_APP_MAXFILESIZE : '2097152')) {
      return {
        code: 'big-size',
        message: 'El archivo excede el tamaño máximo permitido'
      }
    }

    return null
  }

  const { getRootProps, getInputProps, isDragAccept, isDragReject, fileRejections } = useDropzone({
    maxFiles: 1,
    maxSize: (process.env.REACT_APP_MAXFILESIZE !== undefined) ? parseInt(process.env.REACT_APP_MAXFILESIZE) : 2097152,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop: acceptedFiles => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = async (e : any) => {
          setData({ ...data, factura: { ...data.factura, invoice: { ...data.factura.invoice, invoiceBase64: e.target.result } }, fileList: acceptedFiles })
        }
        reader.readAsDataURL(file)
      })
    },
    validator: ifExist
  })

  const validationFiles = () => {
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'name-duplicate'))) enqueueSnackbar('Ya existe un archivo con este nombre.', { variant: 'warning' })
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'big-size'))) enqueueSnackbar('El archivo excede el tamaño máximo permitido', { variant: 'warning' })
  }

  const addAcquisition = () => {
    const myAcquisitions = supplyCtx.supply.my_acquisitions.concat(data.factura)
    saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, my_acquisitions: myAcquisitions } })
    setData({ ...data, factura: { id: 0, quantity: 0, cost: 0, invoice: { id: 0, provider: { id: 0, name: '' }, invoice_number: '', url: '', invoiceBase64: '' } }, fileList: [] })
    setModal({ ...modal, visible: false, name: ModalNames.ADDACQUISITION })
  }

  useEffect(() => {
    validationFiles()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.fileList])

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={modal.visible && modal.name === ModalNames.ADDACQUISITION}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <Typography variant='h6' component='div' className='color-title'>
            <ReceiptIcon className='me-2' /><span>AGREGAR COMPRA</span>
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <Card className='shadow-sm'>
          <CardContent>
            <div className='row gy-3'>
              <div className='col-12 col-md-6'>
                <Autocomplete
                  value={data.factura.invoice.provider}
                  onChange={(event, newValue) => {
                    setData({ ...data, factura: { ...data.factura, invoice: { ...data.factura.invoice, provider: { id: (newValue === null) ? '' : (newValue.id === undefined) ? 0 : newValue.id, name: (newValue === null) ? '' : (newValue.name === undefined) ? newValue : newValue.name } } } })
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id='free-solo-with-text-demo'
                  options={data.listProvider}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue
                    }
                    // Regular option
                    return option.name
                  }}
                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label='Proveedor' helperText='Para ingresar un valor nuevo se debe presionar Enter' />
                  )}
                />
              </div>
              <div className='col-12 col-md-6'>
                <TextField
                  type='text'
                  name='cliente'
                  label='N° Factura'
                  value={data.factura.invoice.invoice_number}
                  onChange={(e) => setData({ ...data, factura: { ...data.factura, invoice: { ...data.factura.invoice, invoice_number: e.target.value } } })}
                  fullWidth
                />
              </div>
              <div className='col-12 col-md-6'>
                <TextField
                  type='text'
                  name='cliente'
                  label='Costo'
                  value={data.factura.cost}
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={(e) => {
                    if (e.target.value.match(/[^0-9]/)) { return e.preventDefault() }
                    setData({ ...data, factura: { ...data.factura, cost: Number(e.target.value) } })
                  }}
                  fullWidth
                />
              </div>
              <div className='col-12 col-md-6'>
                <TextField
                  type='text'
                  name='cliente'
                  label='Cantidad'
                  value={data.factura.quantity}
                  onChange={(e) => {
                    if (e.target.value.match(/[^0-9]/)) { return e.preventDefault() }
                    setData({ ...data, factura: { ...data.factura, quantity: Number(e.target.value) } })
                  }}
                  fullWidth
                />
              </div>
              <div className='col-12'>
                <div hidden={data.fileList.length > 0} {...getRootProps({ className: isDragAccept ? 'p-5 dropzone dropzone-accept' : isDragReject ? 'p-5 dropzone dropzone-reject' : 'p-5 dropzone' })}>
                  <input {...getInputProps()} />
                  <div className='alert alert-danger text-center p-3' role='alert'>
                    <p>El archivo que esta intentando adjuntar no esta permitido</p>
                    <hr />
                    <p className='mb-0 small'>* Archivos permitidos son: <strong>PDF</strong></p>
                  </div>
                  <div className='text-center instrucciones'>

                    <Button
                      variant='contained'
                      size='medium'
                      color='primary'
                      className='m-2'
                    >
                      Seleccione archivo
                    </Button>
                    <p className='mt-3 mb-0'><strong>Haga click para adjuntar la factura</strong> o arrastre y suelte el archivo aquí.</p>
                    <p>PDF (tamaño máximo 2 mb)</p>
                  </div>
                </div>
                {
                    data.fileList.map((file: any) => (
                      <div key={file.nameFile !== undefined ? file.nameFile : file.name}>
                        <div className={file.nameFile !== undefined ? 'fileContainer rounded border border-primary p-2 d-flex flex-row' : 'fileContainer rounded border border-success p-2 d-flex flex-row'}>
                          <div className='fileTypeImg'><ImageOutlinedIcon color='primary' sx={{ fontSize: 25 }} /></div>
                          <div className='fileInfo ms-2'>
                            <p className='small mb-0 fw-bold'>{file.nameFile !== undefined ? file.nameFile : file.name}</p>
                            <p className='small mb-0'>{(file.size / 1024 / 1024).toFixed(3)} Mb</p>
                          </div>
                          <div className='fileActions align-self-end'>
                            <div className='fileActionImg'>
                              <DeleteOutlineOutlinedIcon className='fileTrash' onClick={() => removeFile(file)} color='action' sx={{ fontSize: 25 }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
              </div>
              <div className='col-12 text-end'>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  className='m-2'
                  onClick={addAcquisition}
                  disabled={data.factura.cost <= 0 || data.factura.quantity <= 0 || data.factura.invoice.invoiceBase64 === '' || data.factura.invoice.invoice_number === '' || data.factura.invoice.provider.name === ''}
                >
                  Agregar al insumo
                </LoadingButton>
              </div>
            </div>

          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

export default ModalAddAcquisition

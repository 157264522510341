import { IAppStore } from '@models/store.model'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

interface IOwnProps {
  roles: number[]
  PublicValidationFragment: any
}

const PrivateValidationFragment = <Outlet />

export const RoleGuard = ({ roles, PublicValidationFragment }: IOwnProps) => {
  const userState = useSelector((store: IAppStore) => store.user)

  return roles.indexOf(userState.id_role) >= 0
    ? (PrivateValidationFragment)
    : (
        PublicValidationFragment
      )
}

export default RoleGuard

import React, { useState } from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Alert, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { SupplyContextType, useSupplyContext } from '../../context/Supply.context'
import { ModalNames } from '@constants/modal.constants'
import { ModalState } from '@models/modals.model'
import ModalAddAcquisition from './components/ModalAddAcquisition'
import { CurrencyFormat } from '@utils/Currency.utility'

const Acquisitions = () => {
  const { supplyCtx } = useSupplyContext() as SupplyContextType
  const [data, setData] = useState<{ modal: ModalState }>({ modal: { visible: false, name: ModalNames.ADDACQUISITION, parametros: {} } })

  const saveModal = ({ visible, name, parametros }: ModalState) => {
    setData({ ...data, modal: { visible, name, parametros } })
  }

  return (
    <div className='row gy-3'>
      <div className='col-8 d-flex align-items-center'>
        <Typography variant='h6' className='py-2 color-title'>
          <ShoppingCartIcon className='color-azul' /> COMPRAS DE INSUMO
        </Typography>
      </div>
      <div className='col-4 text-end'>
        <Button
          variant='contained'
          size='medium'
          color='warning'
          className='m-2 btn-secondary'
          onClick={() => setData({ ...data, modal: { ...data.modal, visible: true } })}
        >
          + Agregar
        </Button>
      </div>
      <div className='col-12'>
        {
          supplyCtx.supply.my_acquisitions.length === 0
            ? <Alert severity='info'>Insumo sin compras</Alert>
            : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table' className='tableMUI tbl-reuse'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Proveedor</TableCell>
                      <TableCell>N° Factura</TableCell>
                      <TableCell>Factura</TableCell>
                      <TableCell>Cantidad </TableCell>
                      <TableCell>Costo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {supplyCtx.supply.my_acquisitions.map((row, idx) => (
                      <TableRow
                        key={idx}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell scope='row'>{row.invoice.provider.name}</TableCell>
                        <TableCell>{row.invoice.invoice_number}</TableCell>
                        <TableCell>
                          <Button
                            variant='contained'
                            href={row.invoice.url}
                            size='small'
                            color='primary'
                            hidden={row.id === 0}
                          >
                            Descargar
                          </Button>
                        </TableCell>
                        <TableCell>{row.quantity}</TableCell>
                        <TableCell>{CurrencyFormat(row.cost)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              )
        }
      </div>
      <ModalAddAcquisition modal={data.modal} setModal={saveModal} />
    </div>
  )
}

export default Acquisitions

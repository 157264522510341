import React from 'react'
import InventoryIcon from '@mui/icons-material/Inventory'
import NotificationsIcon from '@mui/icons-material/Notifications'
import VerifiedIcon from '@mui/icons-material/Verified'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
interface IOwnProps{
    type: string
}

const IconHistory:React.FC<IOwnProps> = ({ type }) => {
  let icon
  switch (Number(type)) {
    case 6:
      // deleted
      icon = <DeleteIcon />
      break
    case 1:
      // used
      icon = <LibraryAddIcon />
      break
    case 2:
      // request
      icon = <NotificationsIcon />
      break
    case 3:
      // requestDone
      icon = <VerifiedIcon />
      break
    case 4:
      // requestCanceled
      icon = <FileDownloadDoneIcon />
      break
    default:
      // load
      icon = <InventoryIcon />
      break
  }
  return icon
}

export default IconHistory

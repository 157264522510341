import { PrivateRoutes, PublicRoutes } from '@constants/routes.constants'
import { IAppStore } from '@models/store.model'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

interface Props {
  privateValidation: boolean;
}

export const AuthGuard = ({ privateValidation }: Props) => {
  const userState = useSelector((store: IAppStore) => store.user)
  const location = useLocation()
  const PublicValidationFragment = <Navigate replace to={PrivateRoutes.OTLIST} />

  const PrivateValidationFragment = userState.name_role === 'solo_reportes_st' && location.pathname !== '/ot-report' ? <Navigate replace to={PrivateRoutes.OTREPORT} /> : <Outlet />

  // console.log('name_role --->', userState.name_role)

  if (!userState.logged && location.pathname === '/ot-form/new') return PrivateValidationFragment

  return userState.logged
    ? (
        privateValidation
          ? (
              PrivateValidationFragment
            )
          : (
              PublicValidationFragment
            )
      )
    : (
      <Navigate replace to={PublicRoutes.LOGIN} />
      )
}

export default AuthGuard

import { AccountCircleIcon } from '@assets/icons/Icons'
import { IAppStore } from '@models/store.model'
import { Alert, CircularProgress, TextField, Typography } from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { validateEmail } from '@utils/validateEmail'
import { validateInputNumbers, valiteInputCharacters } from '@utils/validateInput'
import { formateRut } from '@utils/validateRut'
import { useSnackbar } from 'notistack'
import { webApiService } from '@services/index'

import React from 'react'
import { useSelector } from 'react-redux'

const Client = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const userState = useSelector((store: IAppStore) => store.user)
  const [data, setData] = React.useState<{ loading: boolean, listChannel: any[] }>({ loading: false, listChannel: [] })
  const { enqueueSnackbar } = useSnackbar()

  const loadClientBsale = () => {
    setData({ ...data, loading: true })

    let invoiceType = (formCtx.ot.invoice.type === 1) ? process.env.REACT_APP_ID_FACTURA_BSALE : (formCtx.ot.invoice.type === 0) ? process.env.REACT_APP_ID_BOLETA_BSALE : (formCtx.ot.invoice.type === 2) ? process.env.REACT_APP_ID_SHIPPING_BSALE : ''
    invoiceType = (invoiceType === undefined) ? '' : invoiceType
    webApiService.getDocumentBsale(invoiceType, formCtx.ot.invoice.id_invoice).then((response) => {
      if (response.success) {
        saveFormCtx({
          ...formCtx,
          ot: {
            ...formCtx.ot,
            client: {
              ...formCtx.ot.client,
              identification_number: response.data.cliente.rut === null || response.data.cliente.rut === '' ? '1-9' : response.data.cliente.rut,
              email: response.data.cliente.email === null || response.data.cliente.email === '' ? 'temp-' + String(formCtx.ot.invoice.id_invoice) + '@reuse.cl' : response.data.cliente.email, // email: temp-idinvoice@reuse.cl
              name: response.data.cliente.firstName === null || response.data.cliente.firstName === '' ? 'Sin dato' : response.data.cliente.firstName + ' ' + response.data.cliente.lastName,
              phone: response.data.cliente.phone === null || response.data.cliente.phone === '' ? String(formCtx.ot.invoice.id_invoice) : response.data.cliente.phone
            }
          }
        })
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
      }
      setData({ ...data, loading: false })
    }).catch((error) => {
      console.log(error)
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
      setData({ ...data, loading: false })
    })
  }

  return (
    <div className='row gy-3' hidden={userState.id_role === 3 || (formCtx.ot.id !== 0 && (process.env.REACT_APP_MARKETPLACE_NOTIFICA === undefined ? [] : process.env.REACT_APP_MARKETPLACE_NOTIFICA.split(',').map(e => Number(e))).includes(Number(formCtx.ot.channel.id)))}>
      <div className='col-12 d-flex align-items-center'>
        <Typography variant='h6' className='py-2 color-title'>
          <AccountCircleIcon className='color-azul' /> CLIENTE
        </Typography>
      </div>
      <div className='col-12 col-lg-4'>
        <TextField
          disabled={
            (formCtx.objBsale.cliente.rut !== '' || formCtx.ot.id !== 0) && userState.id !== 0
          }
          type='text'
          name='rut_cliente'
          label='Rut'
          fullWidth
          value={formCtx.ot.client.identification_number}
          onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, client: { ...formCtx.ot.client, identification_number: e.target.value } } })}
          error={formCtx.ot.client.identification_number === '' && formCtx.errorValidation}
          onInput={(e) => formateRut(e)}
        />
      </div>
      <div className='col-12 col-lg-8'>
        <TextField
          disabled={formCtx.ot.id > 0 ? (formCtx.ot.client.identification_number === '' || formCtx.objBsale.cliente.firstName !== '') : (!(formCtx.ot.id === 0 || formCtx.objBsale.cliente.rut === ''))}
          type='text'
          name='cliente'
          label='Nombre'
          fullWidth
          value={formCtx.ot.client.name}
          onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, client: { ...formCtx.ot.client, name: e.target.value } } })}
          error={formCtx.ot.client.name === ' ' && formCtx.errorValidation}
          onKeyPress={(e) => valiteInputCharacters(e)}// valido que solo acepte letra
        />
      </div>
      <div className='col-12 col-lg-8'>
        <TextField
          disabled={formCtx.ot.id > 0 ? (formCtx.ot.client.identification_number === '' || formCtx.objBsale.cliente.firstName !== '') : (!(formCtx.ot.id === 0 || formCtx.objBsale.cliente.rut === ''))}
          type='email'
          name='email'
          label='Email'
          fullWidth
          value={formCtx.ot.client.email}
          onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, client: { ...formCtx.ot.client, email: e.target.value } } })}
          error={formCtx.ot.client.email !== '' && validateEmail(formCtx.ot.client.email) === false ? true : formCtx.ot.client.email === '' && formCtx.errorValidation}
        />
      </div>
      <div className='col-12 col-lg-4'>
        <TextField
          disabled={formCtx.ot.id > 0 ? (formCtx.ot.client.identification_number === '' || formCtx.objBsale.cliente.firstName !== '') : (!(formCtx.ot.id === 0 || formCtx.objBsale.cliente.rut === ''))}
          type='text'
          name='telefono'
          label='Telefono'
          fullWidth
          value={formCtx.ot.client.phone}
          onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, client: { ...formCtx.ot.client, phone: e.target.value } } })}
          error={formCtx.ot.client.phone === '' && formCtx.errorValidation}
          onKeyPress={(e) => validateInputNumbers(e)}
        />
      </div>
      {!(formCtx.ot.id === 0 || (formCtx.ot.client.email && formCtx.ot.client.identification_number && formCtx.ot.client.name && formCtx.ot.client.phone)) && (
        <div className='col-12'>
          <Alert severity='error' className='clientAlert'>
            {data.loading
              ? (
                <Typography>Actualizando, por favor espere...<CircularProgress size={15} /></Typography>
                )
              : (
                <Typography>Faltan datos del cliente para actualizarlos
                  <button
                    style={{
                      border: 'none',
                      background: 'none',
                      padding: 0,
                      font: 'inherit',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      paddingLeft: '2px'
                    }}
                    onClick={loadClientBsale}
                  > presione aquí
                  </button>
                </Typography>
                )}
          </Alert>
        </div>
      )}
      {userState.id === 0
        ? (
          <div className='col-12'>
            <Alert severity='info'>
              Si los datos proporcionados no son válidos puedes actualizarlos directamente en cada campo.
            </Alert>
          </div>
          )
        : null}
    </div>
  )
}

export default Client

import { ReactNode } from 'react'

interface IOwnProps {
  listStatus: number[]
  idStatus: number
  PublicValidationFragment: any
  children?: ReactNode
}

export const OTStatusGuard = ({ listStatus, idStatus, children, PublicValidationFragment }: IOwnProps) => {
  return listStatus.indexOf(idStatus) >= 0
    ? (children)
    : (
        PublicValidationFragment
      )
}

export default OTStatusGuard

import { TableFooter, TablePagination, TableRow } from '@mui/material'
import { SuppliesContextType, useSuppliesContext } from '@pages/SuppliesManagement/context/Supplies.context'
import React from 'react'

const EnhancedTableFooter = () => {
  const { suppliesCtx, saveSuppliesCtx } = useSuppliesContext() as SuppliesContextType

  const handleChangePage = (event: unknown, newPage: number) => {
    saveSuppliesCtx({ ...suppliesCtx, params: { ...suppliesCtx.params, page: newPage + 1 } })
  }

  if (suppliesCtx.data === null) return <></>

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          colSpan={11}
          rowsPerPageOptions={[10, 15, 25, 50]}
          labelRowsPerPage='Filas'
          SelectProps={{
            inputProps: {
              'aria-label': 'page number'
            }
          }}
          showFirstButton
          showLastButton
          count={suppliesCtx.data.totalRows}
          rowsPerPage={suppliesCtx.params.limit}
          page={suppliesCtx.params.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(e) => { saveSuppliesCtx({ ...suppliesCtx, params: { ...suppliesCtx.params, limit: parseInt(e.target.value), page: 1 } }) }}
        />
      </TableRow>
    </TableFooter>
  )
}

export default EnhancedTableFooter

import { HistoryIcon } from '@assets/icons/Icons'
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

const ManagementData = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const [data, setData] = useState<{ loading: boolean, hasCreditNote: boolean, listWarehouses: any[] }>({ loading: false, hasCreditNote: false, listWarehouses: [] })
  const { enqueueSnackbar } = useSnackbar()

  const loadWarehouses = async () => {
    setData({ ...data, loading: true, hasCreditNote: formCtx.ot.NC_number > 0 })
    webApiService.getAllWarehouses().then((response) => {
      setData({ ...data, listWarehouses: response.items, loading: false })
    }).catch((error) => {
      console.log(error)
      setData({ ...data, listWarehouses: [], loading: false })
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    })
  }

  useEffect(() => {
    if (formCtx.ot.id > 0) loadWarehouses()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCtx.ot.id])

  return (
    <>
      <div className='row gy-4'>

        <div className='col-12'>
          <Typography variant='h6' component='div' className='color-title'>
            <HistoryIcon className='color-azul' /> DATOS DE GESTION
          </Typography>
        </div>
        <div className='col-12 col-lg-3'>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={formCtx.ot.NC_number !== -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, status: formCtx.otInicial.status, id_warehouse_NC: !e.target.checked ? '' : formCtx.ot.id_warehouse_NC, id_warehouse_transfer: !e.target.checked ? '' : formCtx.ot.id_warehouse_transfer, NC_number: e.target.checked ? 0 : -1, NC_date: (e.target.checked) ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss') : '0000-00-00' } }) }} />}
              label='Nota de Credito'
            />
          </FormGroup>
        </div>
      </div>
      {formCtx.ot.NC_number !== -1
        ? (
          <div className='row gy-4 mt-1'>
            <div className='col-12 col-lg-6'>
              <TextField
                type='number'
                name='number_credit_note'
                label='Número Nota Credito'
                fullWidth
                value={formCtx.ot.NC_number === 0 ? '' : formCtx.ot.NC_number}
                onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, NC_number: (isNaN(parseInt(e.target.value))) ? 0 : parseInt(e.target.value) } })}
                error={formCtx.ot.NC_number === 0}
              />
            </div>
            <div className='col-12 col-lg-6'>

              <DesktopDatePicker
                label='Fecha Nota credito'
                disableFuture
                minDate={formCtx.ot.created_at}
                inputFormat='dd-MM-yyyy'
                value={formCtx.ot.NC_date}
                onChange={(newValue: any) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, NC_date: moment(newValue).format('YYYY-MM-DD HH:mm:ss') } })}
                renderInput={(params: any) => (
                  <TextField fullWidth {...params} />
                )}
              />
            </div>
            <div className='col-12 col-lg-6'>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Bodega de Nota Credito
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={(formCtx.ot.id_warehouse_NC === null) ? 0 : formCtx.ot.id_warehouse_NC}
                  label='Bodega de Nota Credito'
                  onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, id_warehouse_NC: e.target.value.toString(), id_warehouse_transfer: '' } })}
                  error={formCtx.ot.id_warehouse_NC === ''}
                >
                  <MenuItem value=''>Seleccione Bodega...</MenuItem>
                  {
                    data.listWarehouses.map(e => {
                      return (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className='col-12 col-lg-6'>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Bodega Traspaso
                </InputLabel>
                <Select
                  disabled={formCtx.ot.id_warehouse_NC === ''}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={(formCtx.ot.id_warehouse_transfer === null) ? 0 : formCtx.ot.id_warehouse_transfer}
                  label='Bodega de Ingreso'
                  onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, id_warehouse_transfer: e.target.value.toString() } })}
                >
                  <MenuItem value={0}>Seleccione Bodega...</MenuItem>
                  {
                    data.listWarehouses.filter(e => e.id !== parseInt(formCtx.ot.id_warehouse_NC)).map(e => {
                      return (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </div>

          </div>
          )
        : null}
    </>
  )
}

export default ManagementData

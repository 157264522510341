import { IAppStore } from '@models/store.model'
import { UserPool } from '@utils/userPool.util'
import { CognitoUser } from 'amazon-cognito-identity-js'
import React from 'react'
import { BsPower } from 'react-icons/bs'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { useAppDispatch } from 'src/store/store'
import { resetUser } from 'src/store/user'
export interface LogoutButtonInterface {}

const LogoutButton : React.FC<LogoutButtonInterface> = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  const dispatch = useAppDispatch()

  const logout = () => {
    const user = new CognitoUser({
      Username: userState.email,
      Pool: UserPool
    })
    user.signOut(() => {
      dispatch(resetUser())
    })
  }

  return <BsPower style={{ fontSize: '25px', cursor: 'pointer' }} className='ms-3' onClick={logout} />
}

export default LogoutButton

import React, { createContext, useContext, useEffect, useState } from 'react'
import { webApiService } from '../../../services'
import axios, { CancelTokenSource } from 'axios'
import { IParamSuppliesRequestService } from '../../../models'
import useDebounce from '../../../utils/useDebounce.util'
interface IOwnProps {
  data: any
  loading: boolean,
  params: IParamSuppliesRequestService
}

export type SuppliesRequestContextType = {
  suppliesRequestCtx: IOwnProps;
  saveSuppliesRequestCtx: (suppliesRequestCtx: IOwnProps) => void;
};

export const SuppliesRequestContext = createContext<SuppliesRequestContextType | null>(null)

export const SuppliesRequestProvider = ({ children } : any) => {
  const [suppliesRequestCtx, setSuppliesRequestCtx] = useState<IOwnProps>({ data: null, loading: false, params: { limit: 10, page: 1 } })
  let source : CancelTokenSource

  const debouncedValue = useDebounce(suppliesRequestCtx.params, 300)

  const getSuppliesRequest = async () => {
    setSuppliesRequestCtx({ ...suppliesRequestCtx, loading: true })
    if (source) source.cancel('Operation canceled by the user.')
    source = axios.CancelToken.source()
    await webApiService.getSuppliesRequest(suppliesRequestCtx.params, source).then((response) => {
      if (response.success) {
        response.data.items = response.data.items.map((e:any) => { return { ...e, selected: false } })
        setSuppliesRequestCtx({ ...suppliesRequestCtx, data: response.data, loading: false })
      }
    }).catch((error) => {
      if (axios.isCancel(error)) { console.log('Request canceled', error.message) } else {
        console.log(error)
      }

      setSuppliesRequestCtx({ ...suppliesRequestCtx, loading: false })
    })
  }

  const saveSuppliesRequestCtx = (_suppliesRequestCtx: IOwnProps) => {
    setSuppliesRequestCtx(_suppliesRequestCtx)
  }

  useEffect(() => {
    getSuppliesRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return <SuppliesRequestContext.Provider value={{ suppliesRequestCtx, saveSuppliesRequestCtx }}>{children}</SuppliesRequestContext.Provider>
}

export const useSuppliesRequestContext = () => {
  const context = useContext(SuppliesRequestContext)
  if (context === undefined) {
    throw new Error('useSuppliesRequestContext must be used within a DashboardProvider')
  }
  return context
}

import { IAppStore } from '@models/store.model'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

interface IOwnProps {
  roles: number[]
  PublicValidationFragment: any
  children?: ReactNode
}

export const RoleChildrenGuard = ({ children, roles, PublicValidationFragment }: IOwnProps) => {
  const userState = useSelector((store: IAppStore) => store.user)

  return roles.includes(userState.id_role)
    ? (children)
    : (
        PublicValidationFragment
      )
}

export default RoleChildrenGuard

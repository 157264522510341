
import { IParamRequestReportService } from '@models/param.model'

import { createContext, useContext, useState } from 'react'

interface IOwnProps{
  data: any
  loading: boolean
  params: IParamRequestReportService
}

export type ReportContextType = {
  ctxReport: IOwnProps;
  saveCtxReport: (ctxReport: IOwnProps) => void;
};

export const OTSuppliesReportContext = createContext<ReportContextType | null>(null)
// const date: Date = new Date()
export const objReportEmpty = { data: null, loading: false, params: { id_request: '', order_number: '', date_init: null, date_end: null } }

export const OTSuppliesReportProvider = ({ children } : any) => {
  const [ctxReport, setCtxReport] = useState<IOwnProps>(objReportEmpty)

  const saveCtxReport = (_ctxReport: IOwnProps) => {
    setCtxReport(_ctxReport)
  }

  return <OTSuppliesReportContext.Provider value={{ ctxReport, saveCtxReport }}>{children}</OTSuppliesReportContext.Provider>
}

export const useOTSuppliesReportContext = () => {
  const context = useContext(OTSuppliesReportContext)
  if (context === undefined) {
    throw new Error('OTSuppliesReportContext must be used within a DashboardProvider')
  }
  return context
}

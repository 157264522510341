import { GppMaybeIcon } from '@assets/icons/Icons'
import { inputsOT } from '@constants/InputsFormOT.constants'
import { IAppStore } from '@models/store.model'
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { disableInputsRole } from '@utils/Roles.util'
import React from 'react'
import { useSelector } from 'react-redux'

const RequiredInformation = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const userState = useSelector((store: IAppStore) => store.user)
  console.log('formCtx.ot =>', formCtx.ot)
  return (
    <div className='row gy-4' hidden={formCtx.ot.reception_type === ''}>
      <div className='col-12'>
        <Typography variant='h6' component='div' className='color-title'>
          <GppMaybeIcon className='color-azul' /> INFORMACIÓN REQUERIDA
        </Typography>
      </div>

      <div className='col-12' hidden={formCtx.ot.id === 0 && formCtx.ot.reception_type === 'CLIENTE'}>
        <FormGroup>
          <FormControlLabel disabled={formCtx.ot.id > 0 && disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.codeUnlock)} control={<Checkbox checked={!!(formCtx.ot.flag_unlock_code)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, status: formCtx.otInicial.status, flag_unlock_code: (e.target.checked) ? 1 : 0 } }) }} />} label='CÓDIGO DESBLOQUEO' />
          <FormControlLabel disabled={formCtx.ot.id > 0 && disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.disassociation)} control={<Checkbox checked={!!(formCtx.ot.flag_disassociation)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, status: formCtx.otInicial.status, flag_disassociation: e.target.checked } }) }} />} label='DESVINCULACIÓN' />
        </FormGroup>

      </div>
      <div className='col-12'>
        <TextField
          hidden={
            (formCtx.ot.reception_type === 'COURIER' && (formCtx.ot.id === 0 || ![2].includes(formCtx.ot.status.id)) && formCtx.ot.information_status.toUpperCase() !== 'DATOS ENTREGADOS') ||
            (formCtx.ot.unlock_code === '' && formCtx.ot.id !== 0 && formCtx.ot.flag_unlock_code === 0)
            }
          disabled={formCtx.ot.id > 0 && formCtx.ot.status.id !== 27 && formCtx.ot.status.id !== 26}
          type='text'
          name='code'
          label='Código Desbloqueo'
          fullWidth
          value={formCtx.ot.unlock_code}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, unlock_code: e.target.value } }) }}
          error={formCtx.ot.unlock_code === '' && formCtx.ot.flag_unlock_code === 1 && formCtx.ot.information_status.toUpperCase() === 'DATOS ENTREGADOS'}
        />
      </div>
      {userState.id === 0
        ? (
          <div className='col-12'>
            <Alert severity='info'>
              Por favor, considerar que el equipo debe ser entregado desvinculado de cuentas iCloud u otras.
            </Alert>
          </div>
          )
        : null}
    </div>
  )
}

export default RequiredInformation

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { UTCDateToCountryDate } from '@utils/countryDate.util'

const TblReportExcel = ({ dataReport }: any) => {
  return (
    <div>
      <Table
        size='small'
        aria-labelledby='tableTitle'
        id='tblReport'
        border={1}
        hidden
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              OT
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Técnico
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Tipo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Nombre
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Links de referencia
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >Fecha de Solicitud
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Cantidad solicitada
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {dataReport
            ? dataReport.map((row: any, index: number) => (
              <TableRow key={index + 1}>
                <TableCell style={{ textAlign: 'center' }}>{row.id_request}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.technician}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.type}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.supply}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.reference_links}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{UTCDateToCountryDate(row.created_at)}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.quantity}</TableCell>
              </TableRow>
            ))
            : <TableRow><TableCell colSpan={21}>Sin información</TableCell></TableRow>}
        </TableBody>
      </Table>
    </div>
  )
}

export default TblReportExcel

import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Table, TextField, Toolbar, Typography } from '@mui/material'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Transition } from '@constants/TransitionModal.contants'
import { LoadingButton } from '@mui/lab'
import { CloseIcon } from '@assets/icons/Icons'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'

interface IOwnProps {
  modal: { open: boolean, idOT: null | number }
  setModal: Dispatch<SetStateAction<{ open: boolean, idOT: null | number }>>;
  inventory: number
}

const ModalInsumoList: FC<any> = ({ modal, setModal, inventory }: IOwnProps) => {
  const [data, setData] = useState<{ loading: boolean, saving: boolean, supplies: any[], reqId: number, totalDelivery: boolean, partialDelivery: boolean, qty: number }>({ loading: false, saving: false, supplies: [], reqId: 0, totalDelivery: false, partialDelivery: false, qty: 0 })
  const { enqueueSnackbar } = useSnackbar()

  const loadData = async () => {
    setData({ ...data, loading: true })
    try {
      const supplies = modal.idOT && await webApiService.getSuppliesByOT(String(modal.idOT)).then(res => res.data)
      console.log('supplies => ', supplies)

      setData({ ...data, supplies, loading: false })
    } catch (error) {
      console.log(error)
      setData({ ...data, loading: false })
      enqueueSnackbar('Hubo un error al cargar la información, por favor, vuelva a intentar.', { variant: 'error' })
    }
  }

  const deliver = async (idSupplyRequest: number, quantity: number) => {
    console.log('Entregando insumo')

    setData({ ...data, saving: true, reqId: idSupplyRequest })
    // console.log('data => ', data)

    try {
      const body: any = {
        idSupplyRequest,
        quantitySupplyRequest: data.totalDelivery ? quantity : data.qty
      }
      console.log('🚀 ~ deliver ~ body:', body)

      // console.log('body => ', body)

      const res = await webApiService.updateSupplyOrder(body)
      // console.log('res => ', res)
      if (res.success) {
        let newRequest
        if (data.totalDelivery) { newRequest = data.supplies.filter((req: any) => req.id !== idSupplyRequest) } else {
          newRequest = data.supplies.map((req: any) => {
            console.log('comp =>', req, idSupplyRequest)
            if (req.id === idSupplyRequest) { return { ...req, entregado: req.entregado + data.qty } } else { return req }
          })
        }

        console.log('newRequest => ', newRequest, data.totalDelivery)

        setData({ ...data, saving: false, supplies: newRequest, reqId: 0, totalDelivery: false, partialDelivery: false })
        enqueueSnackbar('Insumo entregado correctamente.', { variant: 'success' })
      }
    } catch (error) {
      setData({ ...data, saving: false, reqId: 0 })
      enqueueSnackbar('Hubo un error al entregar el insumo, por favor, vuelva a intentar.', { variant: 'error' })
    }
  }

  useEffect(() => {
    loadData()
  }, []) // eslint-disable-line

  return (
    <Dialog
      open={modal.open}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <Typography variant='h6' component='div' className='color-title'>
            Insumos de OT:
          </Typography>
          <span className='badge rounded-pill text-bg-light ms-2'>{modal.idOT}</span>
          <IconButton
            aria-label='close'
            onClick={() => setModal({ ...modal, open: !modal.open })}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <Card className='shadow-sm'>
          <CardContent>
            <Table>
              <thead>
                <tr className='text-center'>
                  <th>Nombre insumo</th>
                  <th>Cantidad solicitada</th>
                  <th>Entregados</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  data.supplies.map((supply: any, idx: number) => {
                    return (
                      <tr className='text-center border-bottom ' key={idx}>
                        <td className='py-2'>{supply.name} {supply.variant_color}</td>
                        <td className='py-2'>{supply.quantity}</td>
                        <td className='py-2'>{supply.delivered}</td>
                        {data.partialDelivery && data.reqId === supply.id
                          ? (
                            <>
                              <td className='py-2'>
                                <TextField
                                  disabled={data.saving && data.reqId === supply.id}
                                  label='Qty'
                                  size='small'
                                  sx={{ width: 128.33 }}
                                  type='number'
                                  value={data.qty || ''}
                                  onChange={(e) => {
                                    console.log(JSON.stringify(supply))
                                    const number = Number(e.target.value)
                                    if (number >= 0 && number <= supply.quantity - supply.delivered) {
                                      setData({ ...data, qty: number })
                                    }
                                  }}
                                />
                              </td>
                              <td className='py-2'>
                                <LoadingButton
                                  loading={data.saving && data.reqId === supply.id}
                                  variant='contained'
                                  onClick={() => deliver(supply.id, data.qty)}
                                  color='warning'
                                >
                                  Entregar
                                </LoadingButton>
                              </td>
                            </>
                            )
                          : data.totalDelivery && data.reqId === supply.id
                            ? (
                              <>
                                <td className='py-2'>
                                  <LoadingButton
                                    disabled={(data.saving && data.reqId !== supply.id) || data.partialDelivery}
                                    loading={data.saving && data.reqId === supply.id}
                                    variant='contained'
                                    color='warning'
                                    className='shadow-none'
                                    onClick={() => deliver(supply.id, supply.quantity)}
                                  >
                                    Confirmar
                                  </LoadingButton>
                                </td>
                                <td className='py-2'>
                                  <Button
                                    disabled={data.saving}
                                    variant='contained'
                                    onClick={() => setData({ ...data, totalDelivery: false })}
                                  >
                                    Cancelar
                                  </Button>
                                </td>
                              </>
                              )
                            : (
                              <>
                                <td className='py-2'>
                                  <Button
                                    hidden={supply.quantity - supply.delivered === 1}
                                    variant='contained'
                                    disabled={data.partialDelivery || data.totalDelivery}
                                    onClick={() => setData({ ...data, partialDelivery: true, reqId: supply.id })}
                                  >
                                    Entrega parcial
                                  </Button>
                                </td>
                                <td className='py-2'>
                                  <Button
                                    variant='contained'
                                    color='warning'
                                    disabled={data.partialDelivery || data.totalDelivery}
                                    onClick={() => setData({ ...data, totalDelivery: true, reqId: supply.id })}
                                  >
                                    Entrega Total
                                  </Button>
                                </td>
                              </>
                              )}
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </CardContent>
        </Card>

      </DialogContent>
    </Dialog>
  )
}

export default ModalInsumoList

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './theme/App.scss'
import { Provider as ReduxProvider } from 'react-redux'
import store from './store/store'
import { createTheme, ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { es } from 'date-fns/locale'

const theme = createTheme({
  components: {
    MUIDataTable: {
      styleOverrides: {
        paper: {
          boxShadow: 'none !important'
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7ff'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#006ae9'
    },
    secondary: {
      main: '#6c757d'
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <SnackbarProvider maxSnack={5} autoHideDuration={4000}>
          <App />
        </SnackbarProvider>
      </LocalizationProvider>

    </ThemeProvider>
  </ReduxProvider>
)

import { Route, Routes } from 'react-router-dom'
import React from 'react'
import { NotFound } from '@pages/NotFound'

function RoutesWithNotFound ({ children }: any) {
  return (
    <Routes>
      {children}
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
export default RoutesWithNotFound

import { saveAs } from 'file-saver'
import ExcelJS from 'exceljs'
import { webApiService } from '@services/index'

export const remplaceCaracteresRaro = (cadena : string) => {
  // Los eliminamos todos
  // for (var i = 0; i < specialChars.length; i++) {
  // cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
  // }

  // Lo queremos devolver limpio en minusculas
  // cadena = cadena.toLowerCase();

  // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
  // cadena = cadena.replace(/ /g,"_");

  // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro

  cadena = cadena.replace(/á/gi, 'a')
  cadena = cadena.replace(/Á/gi, 'a')
  cadena = cadena.replace(/é/gi, 'e')
  cadena = cadena.replace(/É/gi, 'e')
  cadena = cadena.replace(/í/gi, 'i')
  cadena = cadena.replace(/Í/gi, 'i')
  cadena = cadena.replace(/ó/gi, 'o')
  cadena = cadena.replace(/Ó/gi, 'o')
  cadena = cadena.replace(/ú/gi, 'u')
  cadena = cadena.replace(/Ú/gi, 'u')
  cadena = cadena.replace(/ñ/gi, 'n')
  cadena = cadena.replace(/Ñ/gi, 'n')
  cadena = cadena.replace(/N°/gi, 'n')
  cadena = cadena.replace(/2°/gi, '2')
  cadena = cadena.replace(/°/gi, '')
  cadena = cadena.replace(/#/gi, '')
  return cadena
}

export function exportTableToExcel (
  tableID : string,
  nameReporter : string,
  filename = `${nameReporter}`
) {
  const BOM = '\uFEFF'
  const dataType = 'application/vnd.ms-excel'
  const tableSelect = document.getElementById(tableID)
  const tableHTML = (tableSelect) ? tableSelect.outerHTML : ''
  // Specify file name
  filename = filename ? filename + '.xls' : 'excel_data.xls'

  // Create download link element
  const downloadLink = document.createElement('a')

  document.body.appendChild(downloadLink)

  downloadLink.href = 'data:' + dataType + ', ' + encodeURI(BOM + tableHTML)

  // Setting the file name
  downloadLink.download = filename

  // triggering the function
  downloadLink.click()
}

export function ExportarExcel (tableID : string, nameReporter : string) {
  const uri = 'data:application/vnd.ms-excel;base64,'
  const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
  const base64 = function (s : any) {
    return window.btoa(unescape(encodeURIComponent(s)))
  }
  const format = function (s: any, c: any) {
    return s.replace(/{(\w+)}/g, function (m : any, p : any) {
      return c[p]
    })
  }

  const name = nameReporter
  const table = document.getElementById(tableID)
  const ctx = { worksheet: name || 'Worksheet', table: (table) ? table.innerHTML : '' }
  window.location.href = uri + base64(format(template, ctx))
}

export const excelFormatoCM = async () => {
  // Crear un nuevo libro de Excel
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Insumos')
  const catWorksheet = workbook.addWorksheet('Categorias')
  const familyWorksheet = workbook.addWorksheet('Family')

  // Agregar encabezados a la hoja de trabajo
  worksheet.columns = [
    { header: 'categoria', key: 'categoria' },
    { header: 'sku', key: 'sku' },
    { header: 'nombre', key: 'Nombre' },
    { header: 'variante_color', key: 'Variante o Color' },
    { header: 'familia', key: 'Familia' },
    { header: 'subfamilia', key: 'Subfamilia' },
    { header: 'modelos_compatibles', key: 'Modelos Compatibles' },
    { header: 'sku_rewa', key: 'SKU REWA' },
    { header: 'origin_code', key: 'Codigo de origen' }
  ]

  let options : any[] = await webApiService.getAllSuppliesCategory().then(res => res.data)
  options = options.map((e: any) => e.name)
  catWorksheet.getColumn('A').values = options
  catWorksheet.state = 'hidden'
  const dataValidation: ExcelJS.DataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [`Categorias!A1:A${options.length}`]
  }
  worksheet.getCell('A2').dataValidation = dataValidation // Aplicar la validación a la celda A2 (puedes ajustar esto según tus necesidades)

  // Family validation
  let optionsFamily : any[] = await webApiService.getAllSuppliesFamily(0).then(res => res.data)
  optionsFamily = optionsFamily.map((e: any) => e.name)
  familyWorksheet.getColumn('A').values = optionsFamily
  familyWorksheet.state = 'hidden'
  const familyValidation: ExcelJS.DataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [`Family!A1:A${optionsFamily.length}`]
  }
  worksheet.getCell('E2').dataValidation = familyValidation

  // Generar el archivo Excel y descargarlo
  const blob = await workbook.xlsx.writeBuffer()
  saveAs(new Blob([blob]), 'FormatoCM.xlsx')
}


export const valiteInputCharacters = (event: any) => {
  const regex = /^[a-zA-Z ]+$/
  const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
  if (!regex.test(key)) {
    event.preventDefault()
    return false
  }
}

export const validateInputNumbers = (event: any) => {
  const regex = /^[0-9]+$/
  const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
  if (!regex.test(key)) {
    event.preventDefault()
    return false
  }
}

import { InfoIcon } from '@assets/icons/Icons'
import { inputsOT } from '@constants/InputsFormOT.constants'
import { IAppStore } from '@models/store.model'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { disableInputsRole } from '@utils/Roles.util'
import { useSelector } from 'react-redux'

const StatusInformation = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const userState = useSelector((store: IAppStore) => store.user)

  return (
    <div className='row gy-4' hidden={formCtx.ot.reception_type === ''}>
      <div className='col-12'>
        <Typography variant='h6' component='div' className='color-title'>
          <InfoIcon className='color-azul' /> ESTADO INFORMACIÓN
        </Typography>
      </div>
      <div className='col-12 '>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>
            Estado Información
          </InputLabel>
          <Select
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.slStatusInformation)}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={formCtx.ot.information_status}
            label='Estado Información'
            onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, information_status: e.target.value, unlock_code: '' } })}
          >
            <MenuItem value=''>Seleccione estado de información</MenuItem>
            <MenuItem value='Datos Solicitados' hidden={formCtx.ot.status.id === 27}>Datos Solicitados</MenuItem>
            <MenuItem value='Datos Entregados'>Datos Entregados</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default StatusInformation

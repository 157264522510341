import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { TextField, Typography } from '@mui/material'
import { SupplyContextType, useSupplyContext } from '../context/Supply.context'

const Sku = () => {
  const { supplyCtx, saveSupplyCtx } = useSupplyContext() as SupplyContextType

  return (
    <div className='row gy-3'>
      <div className='col-12 d-flex align-items-center'>
        <Typography variant='h6' className='py-2 color-title'>
          <AccountCircleIcon className='color-azul' /> SKU
        </Typography>
      </div>
      <div className='col-12'>
        <TextField
          type='text'
          name='cliente'
          label='SKU REUSE'
          value={supplyCtx.supply.sku}
          onChange={(e) => saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, sku: e.target.value } })}
          fullWidth
        />
      </div>
      <div className='col-12'>
        <TextField
          type='text'
          name='cliente'
          label='SKU PROVEEDOR'
          value={supplyCtx.supply.sku_rewa}
          onChange={(e) => saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, sku_rewa: e.target.value } })}
          fullWidth
        />
      </div>
    </div>
  )
}

export default Sku


import { CloseIcon, DeleteOutlineOutlinedIcon, ImageOutlinedIcon } from '@assets/icons/Icons'
import { Transition } from '@constants/TransitionModal.contants'
import { ModalNames } from '@constants/modal.constants'
import { ISupply } from '@models/Supply.model'
import { ModalProps } from '@models/modals.model'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material'
// import { webApiService } from '@services/index'
import { webApiService } from '@services/index'
import { excelFormatoCM } from '@utils/Excel.util'
// import { fileName } from '@utils/fileName.util'
// import { sendInvoiceToS3 } from '@utils/s3Utils'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'

const ModalBulkLoad : React.FC<ModalProps> = ({ modal, setModal }) => {
  const [data, setData] = useState<{supplies:any[], loading: boolean, files: File[], newSupplies: ISupply[], loadingUpdate: boolean, logHtml: string, invoiceBase64: any }>({ supplies: [], loading: false, files: [], newSupplies: [], loadingUpdate: false, logHtml: '', invoiceBase64: '' })
  const { enqueueSnackbar } = useSnackbar()

  const removeFile = (file:any) => {
    const newFiles = [...data.files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setData({ ...data, files: newFiles })
  }

  const ifExist = (file :any) => {
    if (data.files.some(e => e.name === file.name)) {
      return {
        code: 'name-duplicate',
        message: 'El archivo esta duplicado'
      }
    }

    if (file.size > parseInt((process.env.REACT_APP_MAXFILESIZE !== undefined) ? process.env.REACT_APP_MAXFILESIZE : '2097152')) {
      return {
        code: 'big-size',
        message: 'El archivo excede el tamaño máximo permitido'
      }
    }

    return null
  }

  const { getRootProps, getInputProps, isDragAccept, isDragReject, fileRejections } = useDropzone({
    maxFiles: 1,
    maxSize: (process.env.REACT_APP_MAXFILESIZE !== undefined) ? parseInt(process.env.REACT_APP_MAXFILESIZE) : 2097152,
    accept: {
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    onDrop: acceptedFiles => {
      setData({ ...data, files: (data.files.length === 0) ? acceptedFiles : data.files.concat(acceptedFiles) })
    },
    validator: ifExist
  })

  const validationFiles = () => {
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'name-duplicate'))) enqueueSnackbar('Ya existe un archivo con este nombre.', { variant: 'warning' })
    if (fileRejections.some((e) => e.errors.some(error => error.code === 'big-size'))) enqueueSnackbar('El archivo excede el tamaño máximo permitido', { variant: 'warning' })
  }

  const bulkStock = async () => {
    setData({ ...data, loadingUpdate: true })
    let html = '----- INICIO -----<br/>'

    const excel = data.files[0]

    const reader = new FileReader()
    reader.onload = async (e) => {
      const binaryData = e.target?.result
      if (binaryData) {
        const workbook = XLSX.read(binaryData, { type: 'binary' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const excelData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

        if (excelData[1].length === 0) {
          enqueueSnackbar('Debe cargar al menos 1 insumo en el archivo excel', { variant: 'error' })
          setData({ ...data, loadingUpdate: false })
          return false
        }

        let idx = 1
        for (const row of excelData.splice(1, excelData.length)) {
          if (row[0] === '' || row[0] === undefined || row[1] === '' || row[1] === undefined || row[2] === '' || row[2] === undefined || row[4] === '' || row[4] === undefined || row[5] === '' || row[5] === undefined) {
            html += 'fila ' + idx + ': Error al cargar insumo faltan campos obligatorios<br/>'
          } else {
            const supply: ISupply = {
              id: 0,
              category: {
                id: 0,
                name: row[0]
              },
              family: {
                id: 0,
                name: row[4],
                parent_id: 0
              },
              subfamily: {
                id: 0,
                name: row[5] === undefined ? '' : row[5],
                parent_id: 0
              },
              sku: row[1],
              name: row[2],
              variant_color: row[3] === undefined ? '' : row[3],
              compatible_models: row[6] === undefined ? '' : row[6],
              origin_code: row[8] === undefined ? '' : row[8],
              sku_rewa: row[7] === undefined ? '' : row[7],
              active: 1,
              inventory: 0,
              reference_links: '',
              serial: '',
              my_acquisitions: []
            }
            // eslint-disable-next-line no-loop-func
            await webApiService.saveSupplies(supply).then((res) => {
              if (res.success) {
                html += 'fila ' + idx + ': Insumo creado N°: ' + res.data.id + '<br/>'
              } else {
                if (res.message.includes('Duplicate')) {
                  html += 'fila ' + idx + ': Error al cargar insumo: Insumo ya existe.<br/>'
                } else {
                  html += 'fila ' + idx + ': Error al cargar insumo: ' + res.message + '<br/>'
                }
              }
              // eslint-disable-next-line no-loop-func
            }).catch(err => {
              if (err.response.data !== undefined) { html += 'fila ' + idx + ': Error al cargar insumo: ' + supply.name + ' -- Error tipo: ' + err.response.data.message + '<br/>' } else { html += 'fila ' + idx + ': Error al cargar insumo: ' + supply.name + '<br/>' }
              console.log(err)
            })
            setData({ ...data, logHtml: html })
          }
          idx = idx + 1
        }
        setData({ ...data, logHtml: html + '<br/>----- FIN -----', loadingUpdate: false })
      }
    }

    reader.readAsBinaryString(excel)

    /*
    for (const element of data.newSupplies) {
      const objSupply: ISupply = {
        id: 0,
        sku: element.sku,
        name: element.name,
        variant_color: element.variant_color,
        category: element.category,
        family: element.family,
        subfamily: element.subfamily,
        origin_code: element.origin_code,
        sku_rewa: element.sku_rewa,
        active: element.active,
        compatible_models: '',
        inventory: 0,
        reference_links: '',
        serial: '',
        my_acquisitions: []
      }
      console.log('🚀 ~ file: ModalBulkLoad.tsx:182 ~ bulkStock ~ objSupply:', objSupply)

      // eslint-disable-next-line no-loop-func
      await webApiService.saveSupplies(objSupply).then(async (res) => {
        html += 'Insumo N°: ' + res.data.id + ' creado<br/>'
        console.log('🚀 ~ file: CustomToolbar.tsx:40 ~ complete: ~ retSupply:', res.data)
        // await webApiService.registerInvoice({ supplyID: objSupply.id, fileName: fileName(filePdf.name, 'bl') }).then(response => response.data)
      // eslint-disable-next-line no-loop-func
      }).catch(err => {
        html += 'Error en Insumo N°: ' + objSupply.id + '<br/>'
        console.log(err)
      })

      setData({ ...data, logHtml: html })
    }
    setData({ ...data, logHtml: html, loadingUpdate: false })
    */
  }

  const handleClose = () => {
    setData({ ...data, logHtml: '', files: [] })
    setModal({ ...modal, visible: false, name: ModalNames.BULKLOAD })
  }

  useEffect(() => {
    validationFiles()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections])

  return (
    <Dialog
      open={modal.visible && modal.name === ModalNames.BULKLOAD}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <Typography variant='h6' component='div' className='color-title'>
            <CloudUploadIcon className='me-2' /><span>CREACIÓN MASIVA</span>
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <Card className='shadow-sm'>
          <CardContent>
            <div className='row gy-2'>
              <div className='col-12 text-end'>
                <Button
                  variant='contained'
                  size='medium'
                  color='primary'
                  className='m-2'
                  onClick={excelFormatoCM}
                >
                  Descargar formato
                </Button>
              </div>
              <div className='col-12'>
                <div className='alert alert-info text-start p-3 d-block' role='alert'>
                  <strong>Instrucciones</strong>
                  <hr />
                  <ul className='small'>
                    <li>Subir <strong>XLSX</strong> con el formato descargado.</li>
                    <li>Campos obligatorios: <strong>categoria, sku, nombre, familia, subfamilia</strong>.</li>
                  </ul>
                </div>
              </div>
              {
                data.logHtml === '' && data.files.map((file: any) => (

                  <div key={file.nameFile !== undefined ? file.nameFile : file.name} className='col-12'>
                    <div className={file.nameFile !== undefined ? 'fileContainer rounded border border-primary p-2 d-flex flex-row' : 'fileContainer rounded border border-success p-2 d-flex flex-row'}>
                      <div className='fileTypeImg'><ImageOutlinedIcon color='primary' sx={{ fontSize: 25 }} /></div>
                      <div className='fileInfo ms-2'>
                        <p className='small mb-0 fw-bold'>{file.nameFile !== undefined ? file.nameFile : file.name}</p>
                        <p className='small mb-0'>{(file.size / 1024 / 1024).toFixed(3)} Mb</p>
                      </div>
                      <div className='fileActions align-self-end'>
                        <div className='fileActionImg'>
                          <DeleteOutlineOutlinedIcon className='fileTrash' onClick={() => removeFile(file)} color='action' sx={{ fontSize: 25 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div className='col-12'>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  className='m-2'
                  onClick={bulkStock}
                  loading={data.loadingUpdate}
                  hidden={data.files.length < 1 || data.logHtml !== ''}
                >
                  Cargar
                </LoadingButton>
              </div>
              <div className={(data.loading || data.files.length === 1) ? 'd-none' : 'col-12'}>
                <div {...getRootProps({ className: isDragAccept ? 'p-5 dropzone dropzone-accept' : isDragReject ? 'p-5 dropzone dropzone-reject' : 'p-5 dropzone' })}>
                  <input {...getInputProps()} />
                  <div className='alert alert-danger text-center p-3' role='alert'>
                    <p>El archivo que esta intentando adjuntar no esta permitido</p>
                    <hr />
                    <p className='mb-0 small'>* Archivos permitido es: <strong>XLSX</strong></p>
                  </div>
                  <div className='text-center instrucciones'>

                    <Button
                      variant='contained'
                      size='medium'
                      color='primary'
                      className='m-2'
                    >
                      Seleccione archivos
                    </Button>
                    <p className='mt-3 mb-0'><strong>Haga click para subir el archivo</strong> o arrastre y suelte aquí.</p>
                    <p>XLSX (tamaño máximo 2 mb)</p>
                  </div>
                </div>
              </div>
              <div className='col-12' hidden={data.logHtml === ''}>
                <small>Log</small>
                <div className='bg-dark p-4 text-white small fw-bold' dangerouslySetInnerHTML={{ __html: data.logHtml }} />
              </div>
              <div className='col-12 text-end' />
            </div>

          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>

  )
}

export default ModalBulkLoad

import { ReceiptIcon, SearchIcon } from '@assets/icons/Icons'
import { FORMAT_DATE } from '@constants/formateDate.constants'
import { IAppStore } from '@models/store.model'
import {
  Alert,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { FormContextType, objBsaleEmpty, OTEmpty, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { KeyboardEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'

const PurchaseDetail = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const [data, setData] = React.useState<{ loading: boolean, listChannel: any[] }>({ loading: false, listChannel: [] })
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveFormCtx({ ...formCtx, objBsale: objBsaleEmpty, ot: { ...OTEmpty, invoice: { ...OTEmpty.invoice, id_invoice: formCtx.ot.invoice.id_invoice, type: parseInt(event.target.value) } } })
  }

  const loadDocumentBsale = () => {
    saveFormCtx({ ...formCtx, errorValidation: false, ot: { ...OTEmpty, invoice: { ...formCtx.ot.invoice, id_invoice: formCtx.ot.invoice.id_invoice } } })
    setData({ ...data, loading: true })

    let invoiceType = (formCtx.ot.invoice.type === 1) ? process.env.REACT_APP_ID_FACTURA_BSALE : (formCtx.ot.invoice.type === 0) ? process.env.REACT_APP_ID_BOLETA_BSALE : (formCtx.ot.invoice.type === 2) ? process.env.REACT_APP_ID_SHIPPING_BSALE : ''
    invoiceType = (invoiceType === undefined) ? '' : invoiceType

    webApiService.getDocumentBsale(invoiceType, formCtx.ot.invoice.id_invoice).then((response) => {
      console.log('response =>', response)
      if (response.success) {
        saveFormCtx({
          ...formCtx,
          errorValidation: true,
          objBsale: response.data,
          ot: {
            ...formCtx.ot,
            id_flow: 4,
            reception_type: [6, 4].includes(userState.id_role) ? 'COURIER' : 'CLIENTE',
            created_by: userState.id,
            product: { ...formCtx.ot.product, sku: (response.data.productos.length === 1) ? response.data.productos[0].sku : '', serial: (response.data.productos.length !== 1) ? '' : response.data.productos[0].serial === '' ? 'SINSERIAL-' + formCtx.ot.invoice.id_invoice + '-0' : response.data.productos[0].serial, sbu: (response.data.productos.length === 1) ? response.data.productos[0].sbu : formCtx.ot.product.sbu },
            channel: { id: (response.data.channel.id_channel === 0) ? '' : response.data.channel.id_channel, name: response.data.channel.channel_name, bsale_channel_id: response.data.channel.bsale_channel_id },
            invoice: {
              ...formCtx.ot.invoice,
              id_channel: response.data.channel.id_channel,
              date_invoice: response.data.emissionDate,
              url: response.data.document_type.urlPublicView,
              name: (formCtx.ot.invoice.type) ? response.data.cliente.company : formCtx.ot.client.name,
              rut: response.data.cliente.rut,
              address: response.data.cliente.address,
              commune: response.data.cliente.city,
              commercial_activity: response.data.cliente.activity
            },

            typeDocument: 2, // response.data.typeDocument === 39 ? 0 : 1,
            numberDocument: 0, // formCtx.ot.invoice.type === 2 ? response.data.document_type_shipping.number : '',
            client: {
              ...formCtx.ot.client,
              identification_number: response.data.cliente.rut === null || response.data.cliente.rut === '' ? '1-9' : response.data.cliente.rut,
              email: response.data.cliente.email === null || response.data.cliente.email === '' ? 'temp-' + String(formCtx.ot.invoice.id_invoice) + '@reuse.cl' : response.data.cliente.email, // email: temp-idinvoice@reuse.cl
              name: response.data.cliente.firstName === null || response.data.cliente.firstName === '' ? 'Sin dato' : response.data.cliente.firstName + ' ' + response.data.cliente.lastName,
              phone: response.data.cliente.phone === null || response.data.cliente.phone === '' ? String(formCtx.ot.invoice.id_invoice) : response.data.cliente.phone
            }
          }
        })
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
        saveFormCtx({ ...formCtx, errorValidation: false, ot: { ...OTEmpty, invoice: { ...formCtx.ot.invoice, id_invoice: formCtx.ot.invoice.id_invoice } } })
      }
      setData({ ...data, loading: false })
    }).catch((error) => {
      console.log(error)
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
      setData({ ...data, loading: false })
    })
  }

  const searchBsale = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') { loadDocumentBsale() }
  }

  const loadChannel = () => {
    webApiService.getAllChannels().then((response) => {
      setData({ ...data, listChannel: response.items })
    }).catch((error) => {
      console.log(error)
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    })
  }

  useEffect(() => {
    loadChannel()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='row gy-2 gy-lg-3'>
      <div className='col-12 col-lg-6'>
        <Typography variant='h6' className='py-2 color-title'>
          <ReceiptIcon className='color-azul' /> DETALLE DE COMPRA
        </Typography>
      </div>
      <div className='col-12 col-lg-6'>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby='radio-invoice-type'
            name='row-radio-buttons-group'
            // value={(formCtx.ot.invoice.type) ? 1 : 0}
            value={formCtx.ot.invoice.type}
            onChange={handleChangeRadio}
          >
            <FormControlLabel
              disabled={formCtx.ot.id > 0}
              value={0}
              control={<Radio />}
              label='Boleta'
            />
            <FormControlLabel
              disabled={formCtx.ot.id > 0}
              value={1}
              control={<Radio />}
              label='Factura'
            />
            {userState.id !== 0
              ? <FormControlLabel
                  disabled={formCtx.ot.id > 0}
                  value={2}
                  control={<Radio />}
                  label='Guia de despacho'
                />
              : null}

          </RadioGroup>

        </FormControl>
      </div>
      <div className='col-12 col-lg-6'>
        <TextField
          autoFocus
          type='number'
          name='number_invoice'
          label={`${formCtx.ot.invoice.type <= 1 ? 'Número de Boleta/Factura' : 'Número Guia de despacho'}`}
          fullWidth
          disabled={data.loading || formCtx.ot.id > 0}
          InputProps={{ endAdornment: (data.loading) ? <CircularProgress size={15} /> : <IconButton aria-label='buscar' onClick={loadDocumentBsale} edge='end'> <SearchIcon /></IconButton> }}
          value={formCtx.ot.invoice.id_invoice}
          onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, invoice: { ...formCtx.ot.invoice, id_invoice: e.target.value } } })}
          onKeyDown={(e) => searchBsale(e)}
          error={formCtx.ot.invoice.id_invoice === '' && formCtx.errorValidation}
        />
      </div>
      <div className='col-12 col-lg-6'>
        <TextField
          disabled
          type='text'
          name='date_emission'
          label='Fecha de Venta'
          fullWidth
          value={(formCtx.ot.invoice.date_invoice !== '') ? moment(formCtx.ot.invoice.date_invoice.split('T')[0]).format(FORMAT_DATE) : ''}
        />
      </div>
      <div className='col-12'>
        <FormControl fullWidth>
          <InputLabel id='label-sl-id-channel'>Canal</InputLabel>
          <Select
            disabled={formCtx.ot.id > 0 && formCtx.otInicial.status.id !== 2}
            labelId='label-sl-id-channel'
            id='sl-id-channel'
            label='Canal'
            value={formCtx.ot.channel.id}
            onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, channel: { ...formCtx.ot.channel, id: e.target.value } } })}
            error={(formCtx.ot.channel.id === null || formCtx.ot.channel.id === '' || parseInt(formCtx.ot.channel.id) === 0) && formCtx.errorValidation}
          >
            <MenuItem value=''>Seleccione Canal</MenuItem>
            {
              data.listChannel.map(e => {
                return (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)
              })
            }
          </Select>
        </FormControl>
      </div>
      {userState.id === 0
        ? (
          <div className='col-12'>
            <Alert severity='info'>
              Presiona el icono de "Lupa <SearchIcon />" para que podamos buscar automáticamente la información de tu Boleta/Factura.
            </Alert>
          </div>
          )
        : null}
    </div>
  )
}

export default PurchaseDetail

import { AssessmentIcon } from '@assets/icons/Icons'
import { inputsOT } from '@constants/InputsFormOT.constants'
import { IAppStore } from '@models/store.model'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import { disableInputsRole } from '@utils/Roles.util'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Supplies } from '../Supplies'

const Diagnostic = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const [data, setData] = useState<{ loading: boolean, listComponent: any[], listFunctionality: any[]}>({ loading: false, listComponent: [], listFunctionality: [] })
  const { enqueueSnackbar } = useSnackbar()
  const userState = useSelector((store: IAppStore) => store.user)

  const loadData = async () => {
    setData({ ...data, loading: true })

    const response : any = await Promise.all([
      webApiService.getAllComponents().then((res) => res.items),
      webApiService.getAllFunctionality().then((res) => res)
    ]).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    })
    const responseData = await response
    const dataFuncionality = await response[1].data
    setData({ ...data, loading: false, listComponent: responseData[0], listFunctionality: dataFuncionality })
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='row gy-4'>
      <div className='col-12'>
        <Typography variant='h6' component='div' className='color-title'>
          <AssessmentIcon className='color-azul' /> DIAGNOSTICO
        </Typography>
      </div>
      <div className='col-12 col-lg-6'>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>
            Diagnostico Información Cliente
          </InputLabel>
          <Select
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.slDiagnostic)}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={formCtx.ot.diagnostic_information}
            label='Diagnostico Información Cliente'
            onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, diagnostic_information: e.target.value } })}
          >
            <MenuItem value=''>Seleccione diagnostico de información</MenuItem>
            <MenuItem value='APLICAR GARANTIA CAMBIO DE EQUIPO' hidden={formCtx.ot.id_flow === 2 || formCtx.ot.id_flow === 4 || formCtx.ot.id_flow === 1 || formCtx.ot.id_flow === 10}>APLICAR GARANTIA CAMBIO DE EQUIPO</MenuItem>
            <MenuItem value='APLICAR GARANTIA REPARAR' hidden={formCtx.ot.id_flow === 2 || formCtx.ot.id_flow === 4 || formCtx.ot.id_flow === 1 || formCtx.ot.id_flow === 10}>APLICAR GARANTIA REPARAR</MenuItem>
            <MenuItem value='NO APLICA GARANTIA MAL USO' hidden={formCtx.ot.id_flow === 2 || formCtx.ot.id_flow === 4 || formCtx.ot.id_flow === 1 || formCtx.ot.id_flow === 10}>NO APLICA GARANTIA MAL USO</MenuItem>
            <MenuItem value='NO APLICA GARANTIA FUNCIONAMIENTO OK' hidden={formCtx.ot.id_flow === 2 || formCtx.ot.id_flow === 4 || formCtx.ot.id_flow === 1 || formCtx.ot.id_flow === 10}>NO APLICA GARANTIA FUNCIONAMIENTO OK</MenuItem>
            <MenuItem value='NO APLICA DEVOLUCIÓN MAL USO' hidden={formCtx.ot.id_flow === 3 || formCtx.ot.id_flow === 4 || formCtx.ot.id_flow === 1 || formCtx.ot.id_flow === 10}>NO APLICA DEVOLUCIÓN MAL USO</MenuItem>
            <MenuItem value='FUNCIONAMIENTO OK' hidden={formCtx.ot.id_flow === 3}>FUNCIONAMIENTO OK</MenuItem>
            <MenuItem value='FUNCIONAMIENTO NO OK' hidden={formCtx.ot.id_flow === 2 || formCtx.ot.id_flow === 3 || formCtx.ot.id_flow === 10}>FUNCIONAMIENTO NO OK</MenuItem>
            <MenuItem value='FUNCIONAMIENTO NO OK, REPARAR' hidden={formCtx.ot.id_flow === 3 || formCtx.ot.id_flow === 4 || formCtx.ot.id_flow === 1}>FUNCIONAMIENTO NO OK, REPARAR</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='col-12 col-lg-6'>
        <FormControl fullWidth>
          <InputLabel id='label-sl-snd'>
            Funcionalidad del Producto
          </InputLabel>
          <Select
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.slScd)}
            labelId='label-sl-snd'
            label='Funcionalidad del Producto'
            id='sl-snd'
            value={formCtx.ot.id_product_functionality === null ? 0 : formCtx.ot.id_product_functionality}
            onChange={(e) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, id_product_functionality: e.target.value.toString() } }) }}
            error={formCtx.ot.diagnostic_information !== '' && (formCtx.ot.id_product_functionality === '')}
          >
            <MenuItem value=''>Seleccione Funcionalidad</MenuItem>
            {
              data.listFunctionality.map(e => {
                return (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)
              })
            }
          </Select>
        </FormControl>
      </div>
      <div className='col-12 col-lg-6'>
        <FormControl fullWidth>
          <InputLabel id='label-sl-ppl'>
            Pieza con falla Principal
          </InputLabel>
          <Select
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.slPpl)}
            labelId='label-sl-ppl'
            label='Pieza con falla Principal'
            id='sl-ppl'
            value={formCtx.ot.technical_service.id_component_ppl_failure === null ? '' : formCtx.ot.technical_service.id_component_ppl_failure}
            onChange={(e) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, technical_service: { ...formCtx.ot.technical_service, id_component_ppl_failure: e.target.value.toString(), id_component_snd_failure: (parseInt(e.target.value.toString()) === 0) ? 0 : formCtx.ot.technical_service.id_component_snd_failure, comments_ppl_failure: (parseInt(e.target.value.toString()) === 0) ? '' : formCtx.ot.technical_service.comments_ppl_failure, comments_snd_failure: (parseInt(e.target.value.toString()) === 0) ? '' : formCtx.ot.technical_service.comments_snd_failure } } }) }}
            error={formCtx.ot.diagnostic_information !== '' && (formCtx.ot.technical_service.id_component_ppl_failure === null || formCtx.ot.technical_service.id_component_ppl_failure === '')}
          >
            <MenuItem value=''>Seleccione Pieza</MenuItem>
            {
              data.listComponent.map(e => {
                return (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)
              })
            }
          </Select>
        </FormControl>
        <div className='my-3'>
          <TextField
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.observacionPpl) || formCtx.ot.technical_service.id_component_ppl_failure === '' || formCtx.ot.technical_service.id_component_ppl_failure === null}
            type='text'
            name='observacion_principal'
            label='Observación Principal'
            multiline
            rows={5}
            fullWidth
            value={formCtx.ot.technical_service.comments_ppl_failure}
            onChange={(e) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, technical_service: { ...formCtx.ot.technical_service, comments_ppl_failure: e.target.value } } }) }}
          />
        </div>
      </div>
      <div className='col-12 col-lg-6'>
        <FormControl fullWidth>
          <InputLabel id='label-sl-snd'>
            Pieza con falla Secundaria
          </InputLabel>
          <Select
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.slScd) || formCtx.ot.technical_service.id_component_ppl_failure === '' || formCtx.ot.technical_service.id_component_ppl_failure === null}
            labelId='label-sl-snd'
            label='Pieza con falla Secundaria'
            id='sl-snd'
            value={formCtx.ot.technical_service.id_component_snd_failure === null ? 0 : formCtx.ot.technical_service.id_component_snd_failure}
            onChange={(e) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, technical_service: { ...formCtx.ot.technical_service, id_component_snd_failure: parseInt(e.target.value.toString()) } } }) }}
          >
            <MenuItem value={0}>Seleccione Pieza </MenuItem>

            {
              data.listComponent.map(e => {
                return (<MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>)
              })
            }
          </Select>
        </FormControl>
        <div className='my-3'>
          <TextField
            disabled={disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.observacionScd) || formCtx.ot.technical_service.id_component_snd_failure === 0 || formCtx.ot.technical_service.id_component_snd_failure === null}
            type='text'
            name='observacion_secundaria'
            label='Observación Secundaria'
            multiline
            rows={5}
            fullWidth
            value={formCtx.ot.technical_service.comments_snd_failure}
            onChange={(e) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, technical_service: { ...formCtx.ot.technical_service, comments_snd_failure: e.target.value } } }) }}
          />
        </div>
      </div>
      {formCtx.loading ? null : <Supplies />}
      <div className='col-12 col-lg-2' hidden={(process.env.REACT_APP_MARKETPLACE_NOTIFICA === undefined ? [] : process.env.REACT_APP_MARKETPLACE_NOTIFICA.split(',').map(e => Number(e))).includes(Number(formCtx.ot.channel.id))}>
        <FormControl className='px-4' hidden={(process.env.REACT_APP_MARKETPLACE_NOTIFICA === undefined ? [] : process.env.REACT_APP_MARKETPLACE_NOTIFICA.split(',').map(e => Number(e))).includes(Number(formCtx.ot.channel.id))}>
          <FormLabel id='chk-notificacion' className='fw-bold'>Usuario Notificado</FormLabel>
          <FormControlLabel
            disabled={
              disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.chkNotification)
            // || formCtx.ot.listSuppliesTypesValues.some(e => e.buy !== 0)
            // 06/03/2024 se quita esta parte para permitir notificar al cliente aun cuando haya insumos pendientes (caso Francisco Montero)
            }
            control={<Checkbox checked={!!(formCtx.ot.client_notification)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, client_notification: e.target.checked ? 1 : 0 } }) }} />} label='Si'
          />
        </FormControl>
      </div>

      <div className='col-12 col-lg-4'>
        <FormControl className='px-4'>
          <FormLabel id='demo-radio-buttons-group-label fw-bold'>
            <b>Resolución</b>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby='demo-radio-buttons-group-label'
            name='radio-buttons-group'
            value={formCtx.ot.diagnostic_resolution}
            onChange={(e) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, diagnostic_resolution: e.target.value } }) }}
          >
            <FormControlLabel
              disabled={
                disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.radioReparado, Number(formCtx.ot.channel.id)) ||
                ((formCtx.ot.client_notification === 0 || formCtx.ot.client_notification === null) && !(process.env.REACT_APP_MARKETPLACE_NOTIFICA === undefined ? [] : process.env.REACT_APP_MARKETPLACE_NOTIFICA.split(',').map(e => Number(e))).includes(Number(formCtx.ot.channel.id))) ||
              (formCtx.ot.diagnostic_information !== 'APLICAR GARANTIA CAMBIO DE EQUIPO' && formCtx.ot.diagnostic_information !== 'APLICAR GARANTIA REPARAR' && formCtx.ot.diagnostic_information !== 'FUNCIONAMIENTO NO OK, REPARAR') ||
              formCtx.ot.status.id === 29 ||
              ![25, 15, 16].includes(formCtx.ot.status.id)
              } value='REPARADO' control={<Radio />} label='REPARADO'
            />
            <FormControlLabel
              disabled={

              disableInputsRole(userState.id_role, formCtx.otInicial.status.id, inputsOT.radioSinReparar, Number(formCtx.ot.channel.id)) || // falso
              ((formCtx.ot.client_notification === 0 || formCtx.ot.client_notification === null) && !(process.env.REACT_APP_MARKETPLACE_NOTIFICA === undefined ? [] : process.env.REACT_APP_MARKETPLACE_NOTIFICA.split(',').map(e => Number(e))).includes(Number(formCtx.ot.channel.id))) || // verdadero
              formCtx.ot.status.id === 29 || // falso
              ![25, 15, 16].includes(formCtx.ot.status.id) || // falso
              (formCtx.ot.diagnostic_information === 'APLICAR GARANTIA REPARAR') // falso
} value='SIN REPARACIÓN' control={<Radio />} label='SIN REPARACIÓN'
            />
          </RadioGroup>
        </FormControl>

      </div>

    </div>
  )
}

export default Diagnostic

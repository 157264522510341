export const ModalNames = {
  HISTORYSTATUS: 'HISTORYSTATUS',
  ASSIGNMENTS: 'ASSIGNMENTS',
  OTVIEW: 'OTVIEW',
  HISTORYPHONECHECK: 'HISTORYPHONECHECK',
  ASSIGNMENTSTECHNICIAN: 'ASSIGNMENTSTECHNICIAN',
  FILES: 'FILES',
  NEWSUPPLY: 'NEWSUPPLY',
  MASSIVEOT: 'MASSIVEOT',
  BULKLOAD: 'BULKLOAD',
  ADDACQUISITION: 'ADDACQUISITION',
  BULKSTOCKLOAD: 'BULKSTOCKLOAD'
}

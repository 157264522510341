import { SchemaIcon } from '@assets/icons/Icons'
import { IAppStore } from '@models/store.model'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import React from 'react'
import { useSelector } from 'react-redux'

const Flows = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const userState = useSelector((store: IAppStore) => store.user)

  return (
    <div className='row gy-4'>
      <div className='col-12'>
        <Typography variant='h6' component='div' className='color-title'>
          <SchemaIcon className='color-azul' /> FLUJO
        </Typography>
      </div>
      <div className='col-12 col-lg-4'>
        <FormControl>
          <FormLabel id='radio-id_flow' className='fw-bold'>Tipo de Flujo</FormLabel>
          <RadioGroup aria-labelledby='radio-id_flow' name='radio-buttons-group' value={formCtx.ot.id_flow} onChange={(e) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, id_flow: parseInt(e.target.value) } }) }}>
            <FormControlLabel disabled={formCtx.ot.id > 0} value='4' control={<Radio />} label='RETRACTO' />
            <FormControlLabel disabled={formCtx.ot.id > 0} value='1' control={<Radio />} label='CAMBIO' />
            <FormControlLabel disabled={formCtx.ot.id > 0} value='2' control={<Radio />} label='DEVOLUCIÓN' />
            <FormControlLabel disabled={formCtx.ot.id > 0} value='3' control={<Radio />} label='REPARACIÓN' />
            <FormControlLabel disabled={formCtx.ot.id > 0} value='10' control={<Radio />} style={{ display: formCtx.ot.id_flow === 10 ? 'block' : 'none' }} label='REPARACIONREUSE' />
          </RadioGroup>
        </FormControl>
      </div>
      {
        userState.id !== 0
          ? (
            <div className='col-12 col-lg-4'>

              <FormControl className='px-4'>
                <FormLabel id='chk-prioridad' className='fw-bold'>Prioridad</FormLabel>
                <FormControlLabel disabled={formCtx.ot.id > 0} control={<Checkbox checked={!!(formCtx.ot.priority)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, priority: e.target.checked ? 1 : 0 } }) }} />} label='Si' />
              </FormControl>

            </div>)
          : null
        }
      <div className='col-12 col-lg-4' style={{ display: userState.id !== 0 ? undefined : 'none' }}>
        <FormControl>
          <FormLabel id='radio-reception_type' className='fw-bold'>Tipo de Ingreso</FormLabel>
          <RadioGroup
            aria-labelledby='radio-reception_type'
            name='radio-buttons-group'
            value={formCtx.ot.reception_type}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, reception_type: e.target.value, unlock_code: '' } }) }}
          >
            <FormControlLabel hidden={[6, 4, Number(process.env.REACT_APP_ROLE_SUPERVISOR)].includes(userState.id_role) && formCtx.ot.id === 0} disabled={formCtx.ot.id > 0} value='CLIENTE' control={<Radio />} label='CLIENTE' />
            <FormControlLabel disabled={formCtx.ot.id > 0} value='COURIER' control={<Radio />} label='COURIER' />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  )
}

export default Flows

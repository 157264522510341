import LogoReuse from '@assets/img/logo-reuse-alargado.png'
import { ButtonMenu } from '@components/ButtonMenu'
import { LogoutButton } from '@components/LogoutButton'
import { NavLink /* Navigate */ } from 'react-router-dom'

import { NavDropdown, Offcanvas } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import { RoleChildrenGuard } from 'src/guards'
import { PrivateRoutes } from '../../constants/routes.constants'
import RoleUserChildrenGuard from 'src/guards/roleUserChildren.guard'

const Header = () => {
  /* const userState = useSelector((store: IAppStore) => store.user) */

  return (
    <Navbar expand='md' className='bg-white shadow-sm fixed-top'>
      <Container fluid>
        <Navbar.Brand href='/' className='text-dark'>
          <img src={LogoReuse} alt='Logo Reuse' width='100px' />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='offcanvasNavbar-expand-md' />
        <Navbar.Offcanvas
          id='offcanvasNavbar-expand-expand-md'
          aria-labelledby='offcanvasNavbarLabel-expand-expand-md'
          placement='end'
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id='offcanvasNavbarLabel-expand-expand-md'>
              Menú
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className='p-0'>

            <Nav className='w-100 d-flex'>
              <div className='d-flex flex-column flex-md-row h-100 align-items-lg-center'>
                <NavLink to={PrivateRoutes.OTLIST} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Inicio</NavLink>

                <RoleChildrenGuard roles={[4, 6, 10, 11, 17]} PublicValidationFragment={null}>
                  <NavDropdown
                    title='OT'
                    className='navbar px-2 text-decoration-none py-1 py-lg-0'
                  >
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.OTFORM + 'new'} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Nueva Orden</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.OTFORMIMEI + 'new'} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Nueva Orden (IMEI)</NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                </RoleChildrenGuard>

                <RoleUserChildrenGuard roles={String(process.env.REACT_APP_ROLE_VIEW_REPORTS).split(',').map(e => Number(e))} users={[11]} PublicValidationFragment={null}>
                  <NavDropdown
                    title='Reportes'
                    className='navbar px-2 text-decoration-none py-1 py-lg-0'
                  >
                    <NavDropdown.Item a>
                      <NavLink to={PrivateRoutes.OTREPORT} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Reporte OT</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.OTREPORTSTATUS} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Reporte Estados</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.OTSUPPLIESREPORT} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Reporte OT - Insumos</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.SUPPLIESREPORT} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Reporte Stock Insumos</NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                </RoleUserChildrenGuard>
                <RoleUserChildrenGuard roles={[11, 17, Number(process.env.REACT_APP_ROLE_SUPERVISOR)]} users={[]} PublicValidationFragment={null}>
                  <NavLink to={PrivateRoutes.OTBLOCKED} className='navbar px-2 text-decoration-none py-1 py-lg-0'>OT Bloquedas</NavLink>
                </RoleUserChildrenGuard>
                <RoleChildrenGuard roles={[11, 20, 17]} PublicValidationFragment={null}>
                  <NavDropdown
                    title='Insumos'
                    className='navbar px-2 text-decoration-none py-1 py-lg-0'
                  >
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.SUPPLIES_LIST} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Gestionar insumos</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item hidden>
                      <NavLink to={PrivateRoutes.SUPPLIES_MANAGEMENT} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Gestionar insumos</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.SUPPLIES_REQUEST} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Gestionar solicitudes</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.SUPPLIES_STOCK} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Actualizar Stocks</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.SUPPLIES_DELIVERY} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Entrega insumos</NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.SUPPLIES_DELIVERYOT} className='navbar px-2 text-decoration-none py-1 py-lg-0'>OT entrega insumo</NavLink>
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item>
                      <NavLink to={PrivateRoutes.SUPPLIES_REQUEST} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Gestionar mermas</NavLink>
                    </NavDropdown.Item> */}
                  </NavDropdown>
                </RoleChildrenGuard>
              </div>
              <Navbar.Collapse className='justify-content-end mt-auto px-2'>
                <Navbar.Text className='text-dark fw-bold'>
                  <ButtonMenu />
                  <LogoutButton />
                </Navbar.Text>
              </Navbar.Collapse>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header

import { PrivateRoutes } from '@constants/routes.constants'
import { IAppStore } from '@models/store.model'
import { LoadingButton } from '@mui/lab'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import { isValidForm } from '@utils/ValidateForm.util'
import { blobToBase64 } from '@utils/blobToBase64.util'
import { fromImage } from 'imtool'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
interface BtnSaveProps {
  loadData: () => Promise<void>;
}

const BtnSave: React.FC<BtnSaveProps> = ({ loadData }) => {
  const userState = useSelector((store: IAppStore) => store.user)
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  // Validacion usuario Cliente vista publica
  if (userState.id === 0) {
    formCtx.ot.created_by = Number(`${process.env.REACT_APP_ID_NOREPLY_USER}`) // cliente
  }

  const saveData = async () => {
    saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, invoice: { ...formCtx.ot.invoice, id_invoice: formCtx.ot.invoice.type === 2 ? formCtx.ot.invoice.id_invoice : formCtx.objBsale.number.toString() } }, loading: true })

    for await (const element of formCtx.ot.files) {
      if (element.nameFile === undefined) {
        delete element.preview
        element.blob = await fromImage(element).then(tool => tool.thumbnail(800, false).toBlob())
        element.base64 = await blobToBase64(element.blob)
        delete element.blob
      }
    }

    webApiService.saveRequest(formCtx.ot).then((response) => {
      // console.log(response)
      if (response.success) {
        console.log('formCtx.ot.id =>', response)
        enqueueSnackbar(response.message, { variant: 'success' })
        if (userState.id !== 0) {
          if (
            (formCtx.ot.id === 0) ||
            (userState.id_role !== 3 && userState.id_role !== 11 && userState.id_role !== Number(process.env.REACT_APP_ROLE_SUPERVISOR)) ||
            (formCtx.ot.diagnostic_resolution !== '' && (userState.id_role === 3 || userState.id_role === 11))
          ) {
            navigate(PrivateRoutes.OTLIST)
          } else {
            loadData()
          }
        } else {
          const queryParams = new URLSearchParams()
          queryParams.append('message', response.message)
          queryParams.append('clientName', formCtx.ot.client.name)

          navigate(`${PrivateRoutes.THANKS}?${queryParams.toString()}`)
        }
      } else {
        enqueueSnackbar(response.message, { variant: 'warning' })
        saveFormCtx({ ...formCtx, loading: false })
      }
    }).catch((error) => {
      console.log(error)
      saveFormCtx({ ...formCtx, loading: false })
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    })
  }

  return (
    <LoadingButton
      disabled={JSON.stringify(formCtx.ot) === JSON.stringify(formCtx.otInicial) || !isValidForm(formCtx.ot, formCtx.otInicial)}
      variant='contained'
      size='medium'
      color='primary'
      className='m-2'
      onClick={saveData}
      loading={formCtx.loading}
    >
      Guardar
    </LoadingButton>
  )
}

export default BtnSave

import { SupplyEmpty } from '@constants/EmptyObjects.constants'
import { INSupply } from '@models/OT.model'
import { ISupply } from '@models/Supply.model'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, TextField } from '@mui/material'
import { webApiService } from '@services/index'
import { isValidFormAddNewSupply } from '@utils/ValidateForm.util'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent, useEffect, useState } from 'react'

interface IOwnData {
  loading: boolean
  listSuppliesTypes: any[]
  listFamily: any[]
  isNew: boolean
  initialSupply: INSupply
  newSupply: INSupply
}

interface OwnProps {
  saveData: (el : { idType: number, idSupply: string, cantidad: number, stock: number, idFamily: number }) => void
}

const NewSupply: React.FC<OwnProps> = ({ saveData }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState<IOwnData>({
    loading: false,
    listSuppliesTypes: [],
    listFamily: [],
    isNew: true,
    initialSupply: {
      type: {
        id: '',
        name: ''
      },
      family: {
        id: '',
        name: ''
      },
      name: '',
      quantity: 0,
      reference_links: [''],
      isRequest: true
    },
    newSupply: {
      type: {
        id: '',
        name: ''
      },
      family: {
        id: '',
        name: ''
      },
      name: '',
      quantity: 0,
      reference_links: [''],
      isRequest: true
    }
  })

  const loadList = async () => {
    setData({ ...data, loading: true })

    const res : any = await Promise.all([
      webApiService.getAllSuppliesCategory().then((r) => r.data),
      webApiService.getAllSuppliesFamily(0).then((r) => r.data)
    ]).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    })
    console.log('res[1]', res[1])
    setData({ ...data, loading: false, listSuppliesTypes: res[0], listFamily: res[1] })
  }

  const handleSumbmit = async () => {
    setData({ ...data, loading: true })

    const supply : ISupply = SupplyEmpty

    supply.name = data.newSupply.name
    supply.category.name = data.newSupply.type.name
    supply.family.name = data.newSupply.family.name
    supply.reference_links = data.newSupply.reference_links.toString()

    // Completar supply

    await webApiService.saveSupplies(supply).then(res => {
      console.log('res =>', res)
      enqueueSnackbar(`El insumo N° ${res.data.id} fue creado`, { variant: 'success' })
      saveData({ idType: Number(res.data.category.id), idFamily: Number(res.data.family.id), idSupply: String(res.data.id), cantidad: data.newSupply.quantity, stock: 0 })
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    }).finally(() => {
      setData({ ...data, loading: false })
    })
  }

  const handleChangeName = (_e: ChangeEvent<HTMLInputElement>) => {
    const supplyName = _e.target.value
    setData({ ...data, newSupply: { ...data.newSupply, name: supplyName as string } })
  }

  const handleChangeQuantity = (_e: ChangeEvent<HTMLInputElement>) => {
    const supplyQuantity = _e.target.value
    setData({ ...data, newSupply: { ...data.newSupply, quantity: Number(supplyQuantity) } })
  }

  useEffect(() => {
    loadList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section>
      <div className='row gy-3'>
        <div className='col-6'>
          <Autocomplete
            value={data.newSupply.type}
            onChange={(event, newValue) => {
              setData({ ...data, newSupply: { ...data.newSupply, type: { id: (newValue === null) ? '' : (newValue.id === undefined) ? 0 : newValue.id, name: (newValue === null) ? '' : (newValue.name === undefined) ? newValue : newValue.name } } })
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='free-solo-with-text-demo'
            options={data.listSuppliesTypes}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue
              }
              // Regular option
              return option.name
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label='Categoría' helperText='Para ingresar un valor nuevo se debe presionar Enter' />
            )}
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            value={data.newSupply.family}
            onChange={(event, newValue) => {
              setData({ ...data, newSupply: { ...data.newSupply, family: { id: (newValue === null) ? '' : (newValue.id === undefined) ? 0 : newValue.id, name: (newValue === null) ? '' : (newValue.name === undefined) ? newValue : newValue.name } } })
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='free-solo-with-text-demo'
            options={data.listFamily}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option
              }
              // Add "xxx" option created dynamically
              if (option.name) {
                return option.name
              }
              // Regular option
              return option.name
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label='Familia' helperText='Para ingresar un valor nuevo se debe presionar Enter' />
            )}
          />
        </div>
        <div className='col-10'><TextField id='outlined-basic' value={data.newSupply.name} label='Nombre' variant='outlined' onChange={handleChangeName} fullWidth /></div>
        <div className='col-2'><TextField id='outlined-basic' value={data.newSupply.quantity} type='number' label='Cantidad' variant='outlined' onChange={handleChangeQuantity} fullWidth /></div>
        {
          data.newSupply.reference_links.map((e: string, i: number) => {
            return (
              <React.Fragment key={i}>
                <div className='col-11'>
                  <TextField
                    // InputProps={{
                    //   startAdornment: <InputAdornment position='start'>https://</InputAdornment>
                    // }}
                    id='outlined-basic' value={e} onChange={(e) => {
                      const newArray = data.newSupply.reference_links
                      newArray[i] = e.target.value
                      setData({ ...data, newSupply: { ...data.newSupply, reference_links: newArray } })
                    }} label='Link referencia de compra' variant='outlined' className='col-lg-5' fullWidth
                  />
                </div>
                <div className='col-1 d-flex align-items-center'>
                  {
                  (data.newSupply.reference_links.length > 1)
                    ? <RemoveCircleIcon
                        className='text-danger cursor-pointer' color='action' sx={{ fontSize: 25 }} titleAccess='Quitar link' onClick={() => {
                          data.newSupply.reference_links.splice(i, 1)
                          setData({ ...data, newSupply: { ...data.newSupply, reference_links: data.newSupply.reference_links } })
                        }}
                      />
                    : null
                }
                  {
                  (data.newSupply.reference_links.length === i + 1)
                    ? (
                      <AddCircleIcon
                        className='text-primary cursor-pointer' sx={{ fontSize: 25 }} titleAccess='Agregar link'
                        onClick={() => {
                          const newArray = data.newSupply.reference_links.concat('')
                          setData({ ...data, newSupply: { ...data.newSupply, reference_links: newArray } })
                        }}
                      />
                      )
                    : null
                }
                </div>
              </React.Fragment>
            )
          })
        }
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex justify-content-end mt-2'>
            <LoadingButton
              disabled={!isValidFormAddNewSupply(data.newSupply, data.initialSupply)}
              variant='contained'
              size='medium'
              color='primary'
              type='submit'
              loading={data.loading}
              onClick={handleSumbmit}
            >
              Solicitar nuevo insumo
            </LoadingButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewSupply

import { TableHead, TableRow, TableCell, Box, Checkbox } from '@mui/material'
import React from 'react'
import { visuallyHidden } from '@mui/utils'
import TableSortLabel from '@mui/material/TableSortLabel'
import { DataReport } from '@models/reportData.model'
import { headCellsReport } from '@constants/headcell.constants'
import { Order } from '../../../../types'

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DataReport
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-undef
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHeadCellsOt (props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort
  } = props
  const createSortHandler =
    (property: keyof DataReport) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox' className='tbl-header'>
          <Checkbox
            color='primary'
            indeterminate={props.numSelected > 0 && props.numSelected < props.rowCount}
            checked={props.rowCount > 0 && props.numSelected === props.rowCount}
            onChange={props.onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts'
            }}

          />
        </TableCell>
        {headCellsReport.map((headCell) => (

          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'none'}
            sortDirection={orderBy === headCell.id ? order : false}
            className='tbl-header'
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>)
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHeadCellsOt

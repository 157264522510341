import { TableFooter, TablePagination, TableRow } from '@mui/material'
import { SuppliesRequestContextType, useSuppliesRequestContext } from '@pages/SuppliesRequest/context/RequestList.context'
import React from 'react'

const EnhancedTableReportFooter = () => {
  const { suppliesRequestCtx, saveSuppliesRequestCtx } = useSuppliesRequestContext() as SuppliesRequestContextType

  const handleChangePage = (event: unknown, newPage: number) => {
    saveSuppliesRequestCtx({ ...suppliesRequestCtx, params: { ...suppliesRequestCtx.params, page: newPage + 1 } })
  }

  if (suppliesRequestCtx.data === null) return <></>

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          colSpan={11}
          rowsPerPageOptions={[10, 15, 25, 50]}
          labelRowsPerPage='Filas'
          SelectProps={{
            inputProps: {
              'aria-label': 'page number'
            }
          }}
          showFirstButton
          showLastButton
          count={suppliesRequestCtx.data.totalRows}
          rowsPerPage={suppliesRequestCtx.params.limit}
          page={suppliesRequestCtx.params.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(e) => { saveSuppliesRequestCtx({ ...suppliesRequestCtx, params: { ...suppliesRequestCtx.params, limit: parseInt(e.target.value), page: 1 } }) }}
        />
      </TableRow>
    </TableFooter>
  )
}

export default EnhancedTableReportFooter

// forma antigua
// export const formateRut = (rut: any) => {
//   // Despejar Puntos
//   let valor = rut.target.value.replace('.', '')
//   // Despejar Guión
//   valor = valor.replace('-', '')

//   // Aislar Cuerpo y Dígito Verificador
//   const cuerpo = valor.slice(0, -1)
//   let dv = valor.slice(-1).toUpperCase()

//   // Formatear RUN
//   rut = rut.target.value = cuerpo + '-' + dv

//   // Si no cumple con el mínimo ej. (n.nnn.nnn)
//   if (cuerpo.length < 7) { rut.setCustomValidity('RUT Incompleto'); return false }

//   // Calcular Dígito Verificador
//   let suma = 0
//   let multiplo = 2

//   // Para cada dígito del Cuerpo
//   for (let i = 1; i <= cuerpo.length; i++) {
//     // Obtener su Producto con el Múltiplo Correspondiente
//     const index = multiplo * valor.charAt(cuerpo.length - i)

//     // Sumar al Contador General
//     suma = suma + index

//     // Consolidar Múltiplo dentro del rango [2,7]
//     if (multiplo < 7) { multiplo = multiplo + 1 } else { multiplo = 2 }
//   }

//   // Calcular Dígito Verificador en base al Módulo 11
//   const dvEsperado = 11 - (suma % 11)

//   // Casos Especiales (0 y K)
//   dv = (dv === 'K') ? 10 : dv
//   dv = (dv === 0) ? 11 : dv

//   // Validar que el Cuerpo coincide con su Dígito Verificador
//   if (dvEsperado !== dv) { rut.setCustomValidity('RUT Inválido'); return false }

//   // Si todo sale bien, eliminar errores (decretar que es válido)
//   rut.setCustomValidity('')
// }

// forma nueva
export const formateRut = (e: any) => {
  // Obtén el valor del input
  let valor = e.target.value
  // Despejar Puntos
  valor = valor.replace('.', '')
  // Despejar Guión
  valor = valor.replace('-', '')

  // Aislar Cuerpo y Dígito Verificador
  const cuerpo = valor.slice(0, -1)
  const dv = valor.slice(-1).toUpperCase()

  // Formatear RUN
  const formattedRut = cuerpo + '-' + dv

  // Actualiza el valor del input con el RUT formateado
  e.target.value = formattedRut

  // Restablecer mensajes de validación personalizados
  e.target.setCustomValidity('')

  // e.target.setCustomValidity('RUT Inválido');
}

import DescriptionIcon from '@mui/icons-material/Description'
import PaidIcon from '@mui/icons-material/Paid'
import { AttachFile } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { BtnCancel } from '../BtnCancel'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ImageIcon from '@mui/icons-material/Image'
import { sendImageToS3, sendInvoiceToS3 } from '@utils/s3Utils'
import { useNavigate } from 'react-router-dom'
import { PrivateRoutes } from '@constants/routes.constants'
import { isValidFormNewSupply } from '@utils/ValidateForm.util'
import { ISupply } from '@models/OT.model'
interface IOwnData {
  loading: boolean
  listSuppliesTypes: any[]
  isNew: boolean
  triggerSave: boolean
  initialSupply: ISupply
  newSupply: ISupply
  supplyID: number
  supply: any[]
}

const NewSupply = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState<IOwnData>({
    loading: false,
    listSuppliesTypes: [],
    isNew: true,
    triggerSave: false,
    initialSupply: {
      type: {
        id: '',
        name: ''
      },
      supply: {
        id: 0,
        idType: '',
        name: '',
        quantity: 0,
        reference_links: [''],
        provider: '',
        cost: 0,
        last_invoice: '',
        brand: '',
        model: '',
        sku: '',
        serial: '',
        selectedFile: null,
        selectedInvoice: null,
        isRequest: false
      }
    },
    newSupply: {
      type: {
        id: '',
        name: ''
      },
      supply: {
        id: 0,
        idType: '',
        name: '',
        quantity: 0,
        reference_links: [''],
        provider: '',
        cost: 0,
        last_invoice: '',
        brand: '',
        model: '',
        sku: '',
        serial: '',
        selectedFile: null,
        selectedInvoice: null,
        isRequest: false
      }
    },
    supplyID: 0,
    supply: []
  })
  const navigate = useNavigate()
  const loadSupplyTypes = async () => {
    setData({ ...data, loading: true })
    await webApiService.getAllSuppliesCategory().then((response) => {
      if (response.success) {
        setData({ ...data, loading: false, listSuppliesTypes: response.data })
      }
    }).catch(error => {
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
      setData({ ...data, loading: false })
    })
  }

  const handleSumbmit = async () => {
    setData({ ...data, loading: true })
    const fileName = String(data.newSupply.supply.selectedFile?.name)
    await webApiService.createSupply(data.newSupply).then(async res => {
      if (res.success) {
        setData({ ...data, loading: false, supplyID: res.data.idSupply })
        if (data.newSupply.supply.selectedFile !== null) {
          sendImageToS3(Number(res.data.idSupply), data.newSupply.supply.selectedFile)
        }
        if (data.newSupply.supply.selectedInvoice !== null) {
          sendInvoiceToS3(Number(res.data.idSupply), data.newSupply.supply.selectedInvoice)
        }
        await webApiService.registerInvoice({ supplyID: data.supplyID, fileName }).then(response => response.data)
        enqueueSnackbar('Se ha creado el insumo con éxito.', { variant: 'success' })
        await webApiService.createHistoryRecord({ supplyID: data.supplyID, type: 4, history: 'Se cargan ' + data.newSupply.supply.quantity + ' unidades al insumo.' }).then(response => response.data)
        navigate(PrivateRoutes.SUPPLIES_MANAGEMENT, { replace: true })
      }
    }).catch(error => {
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
      setData({ ...data, loading: false })
    })
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files && event.target.files[0]
      setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, selectedFile: file } } })
    }
  }
  const handleInvoiceSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files && event.target.files[0]
      setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, selectedInvoice: file } } })
    }
  }
  const handleResetFile = () => {
    setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, selectedFile: null } } })
  }
  const handleResetInvoice = () => {
    setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, selectedInvoice: null } } })
  }
  useEffect(() => {
    loadSupplyTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.isNew])

  return (
    <div className='container-lg'>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            CREAR INSUMO
          </Typography>
          <div className='shadow-sm rounded bg-white p-3'>
            <div className='container-xxl bg-white'>
              <div className='bg-white py-3 row'>
                <div className='col-12'>
                  <Typography variant='h6' component='div' className='color-title'>
                    <DescriptionIcon className='color-azul' /> INFORMACIÓN PRINCIPAL
                  </Typography>
                  <div className='row mt-4'>
                    <div className='col-12 col-lg-8'>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        defaultValue='new-type'
                        value={(data.isNew) ? 'new' : 'exist'}
                        onChange={(e) => {
                          setData({ ...data, isNew: (e.target.value === 'new'), newSupply: { ...data.newSupply, type: { id: '0', name: '' } } })
                        }}
                      >
                        <FormControlLabel value='exist' control={<Radio />} label='Usar tipo existente' />
                        <FormControlLabel value='new' control={<Radio />} label='Nuevo tipo' />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-4 mt-2'>
                      <FormControl fullWidth hidden={!data.isNew}>
                        <TextField
                          id='outlined-basic'
                          label='Nuevo tipo'
                          value={data.newSupply.type.name}
                          onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, type: { ...data.newSupply.type, name: e.target.value } } })}
                          fullWidth
                        />
                      </FormControl>
                      <FormControl fullWidth hidden={data.isNew}>
                        <InputLabel id='label-sl-tipo' hidden={data.isNew}>
                          Tipo de insumo:
                        </InputLabel>
                        <Select
                          labelId='label-sl-tipo'
                          label='Tipo de insumo:'
                          hidden={data.isNew}
                          value={data.newSupply.type.id}
                          onChange={(e) => {
                            console.log('e:', e)
                            setData({
                              ...data,
                              newSupply: {
                                ...data.newSupply,
                                type: {
                                  id: e.target.value,
                                  name: data.listSuppliesTypes.find(el => el.id === e.target.value).name
                                }
                              }
                            })
                          }}
                          fullWidth
                        >
                          <MenuItem value=''>Seleccione un tipo de insumo</MenuItem>
                          {
                          data.listSuppliesTypes.map((e: any, i: number) => {
                            return (
                              <MenuItem value={e.id} key={i}>{e.name}</MenuItem>
                            )
                          })
                        }
                        </Select>
                      </FormControl>
                    </div>
                    <div className='col-4 mt-2'>
                      <TextField
                        id='outlined-basic' label='Nombre' variant='outlined' fullWidth
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, name: e.target.value } } })}
                      />
                    </div>
                    <div className='col-4 mt-2'>
                      <TextField
                        id='outlined-basic' label='Marca' variant='outlined' fullWidth value={data.newSupply.supply.brand}
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, brand: e.target.value } } })}
                      />
                    </div>
                    <div className='col-4 mt-2'>
                      <TextField
                        id='outlined-basic' label='Modelo' variant='outlined' fullWidth value={data.newSupply.supply.model}
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, model: e.target.value } } })}
                      />
                    </div>
                    <div className='col-4 mt-2'>
                      <TextField
                        id='outlined-basic' label='Sku' variant='outlined' fullWidth value={data.newSupply.supply.sku}
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, sku: e.target.value } } })}
                      />
                    </div>
                    <div className='col-4 mt-2'>
                      <TextField
                        id='outlined-basic' label='Serie' variant='outlined' fullWidth value={data.newSupply.supply.serial}
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, serial: e.target.value } } })}
                      />
                    </div>
                    <div className='col-12 mt-4 row'>
                      <div className='col-2'>
                        <Button component='label' variant='contained' startIcon={<ImageIcon />}>
                          Adjuntar imagen
                          <input type='file' accept='image/jpeg' hidden onChange={(event) => handleFileSelect(event)} />
                        </Button>
                      </div>
                      <div className='col-10'>
                        {data.newSupply.supply.selectedFile
                          ? (
                            <div>
                              <p>
                                <Alert severity='success'>{data.newSupply.supply.selectedFile.name}
                                  <RemoveCircleIcon onClick={handleResetFile} className='text-danger cursor-pointer' color='action' sx={{ fontSize: 20, mx: 1 }} titleAccess='Quitar archivo'>
                                    Borrar archivo
                                  </RemoveCircleIcon>
                                </Alert>

                              </p>

                            </div>
                            )
                          : <Alert severity='warning'>Debe adjuntar una imagen (se permiten archivos .jpeg).</Alert>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-4 mb-4'><hr /></div>
                <div className='col-12'>
                  <Typography variant='h6' component='div' className='color-title mb-4'>
                    <PaidIcon className='color-azul' /> CARGAR NUEVAS UNIDADES
                  </Typography>
                  <div className='row gy-3 mt-2'>

                    <div className='col-5'>
                      <TextField
                        id='outlined-basic'
                        label='Proveedor'
                        variant='outlined'
                        fullWidth
                        value={data.newSupply.supply.provider}
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, provider: e.target.value } } })}
                      />
                    </div>

                    <div className='col-3'>
                      <TextField
                        id='outlined-basic'
                        label='N° Factura/Boleta'
                        variant='outlined'
                        fullWidth
                        value={data.newSupply.supply.last_invoice}
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, last_invoice: e.target.value } } })}
                      />
                    </div>
                    <div className='col-2'>
                      <TextField
                        id='outlined-basic'
                        label='Valor por unidad'
                        variant='outlined'
                        value={data.newSupply.supply.cost}
                        onChange={(e) => {
                          const value = Number(e.target.value)
                          if (!isNaN(value)) {
                            setData({
                              ...data,
                              newSupply: {
                                ...data.newSupply,
                                supply: {
                                  ...data.newSupply.supply,
                                  cost: value
                                }
                              }
                            })
                          }
                        }}
                      />

                    </div>
                    <div className='col-2'>
                      <TextField
                        id='outlined-basic'
                        type='number'
                        label='Cantidad'
                        variant='outlined'
                        fullWidth
                        value={data.newSupply.supply.quantity}
                        onChange={(e) => setData({ ...data, newSupply: { ...data.newSupply, supply: { ...data.newSupply.supply, quantity: Number(e.target.value) } } })}
                      />
                    </div>
                  </div>
                  <div className='col-12 mt-4 row'>
                    <div className='col-2'>
                      <Button component='label' variant='contained' startIcon={<AttachFile />}>
                        Adjuntar factura
                        <input type='file' accept='image/jpeg' hidden onChange={(event) => handleInvoiceSelect(event)} />
                      </Button>
                    </div>
                    <div className='col-10'>
                      {data.newSupply.supply.selectedInvoice
                        ? (
                          <div>
                            <p>
                              <Alert severity='success'>{data.newSupply.supply.selectedInvoice.name}
                                <RemoveCircleIcon onClick={handleResetInvoice} className='text-danger cursor-pointer' color='action' sx={{ fontSize: 20, mx: 1 }} titleAccess='Quitar archivo'>
                                  Borrar archivo
                                </RemoveCircleIcon>
                              </Alert>

                            </p>

                          </div>
                          )
                        : <Alert severity='warning'>Debe adjuntar la factura (se permiten archivos .pdf, .jpeg y .png).</Alert>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex justify-content-end mt-2'>
                    <BtnCancel />
                    <LoadingButton
                      disabled={JSON.stringify(data.initialSupply) === JSON.stringify(data.newSupply) || !isValidFormNewSupply(data.newSupply, data.initialSupply)}
                      variant='contained'
                      size='medium'
                      color='primary'
                      type='submit'
                      className='m-2 btn-secondary'
                      loading={data.loading}
                      onClick={handleSumbmit}
                    >
                      Crear insumo
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewSupply

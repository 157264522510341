import {
  CognitoUserPool
} from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID || '', // Your user pool id here
  ClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID || ''// Your client id here
}

export const UserPool = new CognitoUserPool(poolData)

import { TextField } from '@mui/material'
import { SuppliesContextType, useSuppliesContext } from '@pages/SuppliesManagement/context/Supplies.context'
import React from 'react'

export interface IOwnProps {
    nameField: any
}

const SearchColumn : React.FC<IOwnProps> = ({ nameField }) => {
  const { suppliesCtx, saveSuppliesCtx } = useSuppliesContext() as SuppliesContextType

  return (
    <TextField id={'search-' + nameField} label='buscar' type='search' size='medium' onChange={(e) => saveSuppliesCtx({ ...suppliesCtx, params: { ...suppliesCtx.params, [nameField]: e.target.value } })} />
  )
}

export default SearchColumn

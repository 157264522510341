import { BootstrapTooltip } from '@components/Tooltips/CustomizedTooltips'
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { objReportEmpty, ReportContextType, useOTSuppliesReportContext } from '@pages/OTSuppliesReport/context/OTSuppliesReport.context'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import filterIcon from '@assets/img/filter.png'
import moment from 'moment'
import { getTimeDiff } from '@utils/countryDate.util'
import { DBOARD_FORMATEND, DBOARD_FORMATSTART } from '@constants/formateDate.constants'
export interface IParamReport {
  id_request: string
  order_number: string
}

const InputSearchFilter = () => {
  const { ctxReport, saveCtxReport } = useOTSuppliesReportContext() as ReportContextType
  const { enqueueSnackbar } = useSnackbar()

  const handleFilter = () => {
    saveCtxReport({ ...ctxReport, loading: true })
    const _params = {
      id_request: ctxReport.params.id_request,
      order_number: ctxReport.params.order_number,
      date_init: ctxReport.params.date_init !== null ? moment(ctxReport.params.date_init).format(DBOARD_FORMATSTART) : null,
      date_end: ctxReport.params.date_end !== null ? moment(ctxReport.params.date_end).format(DBOARD_FORMATEND) : null,
      timeDiff: getTimeDiff()
    }

    webApiService.getAllRequestReport(_params).then(async (response) => {
      if (response.success) {
        // webApiService.getAllByOrderList({ orders : })

        const ordenes: number[] = response.data.items.map((e: any) => e.id)
        console.log('🚀 ~ webApiService.getAllRequestReport ~ ordenes:', ordenes.toString())

        const supplies = await webApiService.getAllByOrderList({ orders: ordenes.toString() }).then(res => res.data)
        console.log('🚀 ~ webApiService.getAllRequestReport ~ Supplies:', supplies)

        const newArray : any[] = []

        response.data.items.forEach((element: any) => {
          if (supplies.some((e: any) => e.id_request_st === element.id)) {
            supplies.filter((s: any) => s.id_request_st === element.id).forEach((supEl: any) => {
              newArray.push({ ...element, ...supEl })
            })
          } else {
            newArray.push({ ...element })
          }
        })

        console.log('🚀 ~ webApiService.getAllRequestReport ~ newArray:', newArray)

        saveCtxReport({ ...ctxReport, data: { items: newArray, totalRows: newArray.length }, loading: false })
      } else {
        enqueueSnackbar(response.message, { variant: 'warning' })
        saveCtxReport({ ...ctxReport, loading: false })
      }
    }).catch((error) => {
      console.log(error)
      saveCtxReport({ ...ctxReport, loading: false })
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    })
  }

  const handleClearFilter = () => {
    saveCtxReport(objReportEmpty)
  }

  return (
    <>

      <div className='row  gy-4'>

        <div className='col-12 col-lg-4'>
          <TextField
            type='search'
            label='Número de OT'
            name='id_request'
            value={ctxReport.params.id_request}
            onChange={(e) => {
              saveCtxReport({ ...ctxReport, params: { ...ctxReport.params, id_request: e.target.value } })
            }}
            inputProps={{ placeholder: 'Search...' }}
            fullWidth
          />
        </div>
        <div className='col-12 col-lg-4'>
          <TextField
            type='search'
            label='Número de Boleta/Factura'
            name='order_number'
            value={ctxReport.params.order_number}
            onChange={(e) => {
              saveCtxReport({ ...ctxReport, params: { ...ctxReport.params, order_number: e.target.value } })
            }}
            inputProps={{ placeholder: 'Search...' }}
            fullWidth
          />
        </div>
      </div>
      <div className='row my-2 gy-4'>
        <div className='col-12 col-lg-4'>
          <DesktopDatePicker
            label='Fecha Desde'
            disableFuture
            inputFormat='dd-MM-yyyy'
            value={ctxReport.params.date_init}
            onChange={(newValue) => {
              saveCtxReport({ ...ctxReport, params: { ...ctxReport.params, date_init: newValue } })
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} />
            )}
          />

        </div>
        <div className='col-12 col-lg-4'>
          <DesktopDatePicker
            label='Fecha Hasta'
            disableFuture
            minDate={ctxReport.params.date_init}
            inputFormat='dd-MM-yyyy'
            value={ctxReport.params.date_end}
            onChange={(newValue) => {
              saveCtxReport({ ...ctxReport, params: { ...ctxReport.params, date_end: newValue } })
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} />
            )}
          />
        </div>
        <div className='col-12 col-lg-4'>
          <div className='flex-lg-row h-100 align-items-lg-center'>
            <BootstrapTooltip title='Aplicar Filtro'>
              <LoadingButton
                variant='contained'
                color='primary'
                size='small'
                className='m-2'
                loading={ctxReport.loading}
                disabled={
                  (ctxReport.params.date_end !== null && moment(ctxReport.params.date_end).isAfter(new Date())) ||
                  (ctxReport.params.date_init !== null && ctxReport.params.date_end === null) ||
                  (ctxReport.params.date_end !== null && ctxReport.params.date_init === null)
                }
                onClick={() => handleFilter()}
              >
                Aplicar Filtro
              </LoadingButton>
            </BootstrapTooltip>
            <BootstrapTooltip title='Limpiar Filtro'>
              <img
                src={filterIcon} alt='limpiar filtro' width='35' height='35'
                style={{ fontSize: '25px', cursor: 'pointer' }}
                onClick={handleClearFilter}
              />
            </BootstrapTooltip>
          </div>
        </div>
      </div>

    </>
  )
}

export default InputSearchFilter

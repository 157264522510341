import { Button } from '@mui/material'
import React from 'react'
import { BootstrapTooltip } from '../../../../components/Tooltips/CustomizedTooltips'
import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import { exportTableToExcel } from '@utils/Excel.util'
const BtnExcel = () => {
  return (
    <div className='d-flex justify-content-end h-100  my-2'>
      <BootstrapTooltip title='Descargar Reporte en .xsl'>
        <Button
          variant='contained' color='success' size='small' className='m-2'
          onClick={() => exportTableToExcel(
            'tblReport',
            'Reporte OT'
          )}
        >
          <Excel className='me-2' /> Descargar Informe
        </Button>
      </BootstrapTooltip>
    </div>
  )
}

export default BtnExcel

import { HeadCell } from '@models/listOT.model'
import { HeadCellSupplies, HeadCellSuppliesRequest } from '@models/supplies.model'
import { HeadCellReport, HeadCellReportStatus } from '@models/reportData.model'

export const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    disablePadding: true,
    label: 'OT',
    hasFilter: true
  },
  {
    id: 'created_by',
    disablePadding: false,
    label: 'Creado por',
    hasFilter: true
  },
  {
    id: 'priority',
    disablePadding: false,
    label: 'Prioridad',
    hasFilter: false
  },
  {
    id: 'serial',
    disablePadding: false,
    label: 'Serial',
    hasFilter: true
  },
  {
    id: 'id_invoice',
    disablePadding: false,
    label: 'Número Boleta',
    hasFilter: true
  },
  {
    id: 'client_name',
    disablePadding: false,
    label: 'Cliente',
    hasFilter: true
  },
  {
    id: 'name_user_assigned',
    disablePadding: false,
    label: 'Usuario Asignado',
    hasFilter: true
  },
  {
    id: 'name_assigned_technician',
    disablePadding: false,
    label: 'Tecnico Asignado',
    hasFilter: true
  },
  {
    id: 'created_at',
    disablePadding: false,
    label: 'F. Creación OT',
    hasFilter: true
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'Estado OT',
    hasFilter: true
  },
  {
    id: 'information_status',
    disablePadding: false,
    label: 'Estado Información',
    hasFilter: false
  },
  {
    id: 'supplies_status',
    disablePadding: false,
    label: 'Estado Insumos',
    hasFilter: false
  },
  {
    id: 'diagnostic_information',
    disablePadding: false,
    label: 'Estado Diagnostico',
    hasFilter: false
  }
  // {
  //   id: 'day_diagnostico_ST',
  //   disablePadding: false,
  //   label: 'Días Disponible ST',
  //   hasFilter: false
  // },
  // {
  //   id: 'diagnosis_notifications_days',
  //   disablePadding: false,
  //   label: 'Días Notificados diagnostico',
  //   hasFilter: false
  // },
  // {
  //   id: 'day_total',
  //   disablePadding: false,
  //   label: 'Días Totales',
  //   hasFilter: false
  // }
]

export const suppliesHeadCells: readonly HeadCellSupplies[] = [
  {
    id: 'id',
    disablePadding: true,
    label: 'id',
    hasFilter: true
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Nombre',
    hasFilter: true
  },
  {
    id: 'variant_color',
    disablePadding: true,
    label: 'Variante ó Color',
    hasFilter: true
  },
  {
    id: 'category',
    disablePadding: true,
    label: 'Categoria',
    hasFilter: true
  },
  {
    id: 'family',
    disablePadding: true,
    label: 'Familia',
    hasFilter: true
  },
  {
    id: 'subfamily',
    disablePadding: true,
    label: 'Sub-Familia',
    hasFilter: true
  },
  {
    id: 'stock',
    disablePadding: true,
    label: 'Inventario',
    hasFilter: true
  }
]

export const suppliesRequestHeadCells: readonly HeadCellSuppliesRequest[] = [
  {
    id: 'ot',
    disablePadding: true,
    label: 'OT',
    hasFilter: true
  },
  {
    id: 'technician',
    disablePadding: true,
    label: 'Tecnico',
    hasFilter: true
  },
  {
    id: 'type',
    disablePadding: true,
    label: 'Tipo Insumo',
    hasFilter: true
  },
  {
    id: 'supply',
    disablePadding: true,
    label: 'Insumo',
    hasFilter: true
  },
  {
    id: 'reference_links',
    disablePadding: true,
    label: 'Links de referencia',
    hasFilter: false
  },
  {
    id: 'created_at',
    disablePadding: true,
    label: 'Fecha de solicitud',
    hasFilter: true
  },
  {
    id: 'quantity',
    disablePadding: true,
    label: 'Cantidad solicitada',
    hasFilter: false
  }
]

export const headCellsReport: readonly HeadCellReport[] = [
  {
    id: 'id',
    disablePadding: true,
    label: 'OT'
  },
  {
    id: 'order_number',
    disablePadding: false,
    label: 'Número Boleta'
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'Estado OT'
  },
  {
    id: 'status_manager',
    disablePadding: false,
    label: 'Responsable estado'
  },
  {
    id: 'created_at',
    disablePadding: false,
    label: 'Fecha Creación'
  },
  {
    id: 'name_client',
    disablePadding: false,
    label: 'Cliente'
  }
]

export const headCellsReportStatus: readonly HeadCellReportStatus[] = [
  {
    column: 'id',
    sort: false,
    disablePadding: false,
    label: ''
  },
  {
    column: 'id_request',
    sort: true,
    disablePadding: false,
    label: 'OT'
  },
  {
    column: 'actual_state',
    sort: true,
    disablePadding: false,
    label: 'Estado actual'
  }
  // ,
  // {
  //   column: 'id',
  //   sort: true,
  //   disablePadding: false,
  //   label: 'Dias en ST'
  // }
]

import { INSupply } from '@models/OT.model'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Autocomplete, FormControl, TextField, Typography } from '@mui/material'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { SupplyContextType, useSupplyContext } from '../context/Supply.context'

const Category = () => {
  const { supplyCtx, saveSupplyCtx } = useSupplyContext() as SupplyContextType
  const [data, setData] = useState<{ loading: boolean, listCat: any[], listFamily: any[], listSubFamily: any[], newSupply: INSupply }>({
    loading: false,
    listCat: [],
    listFamily: [],
    listSubFamily: [],
    newSupply: {
      type: {
        id: '',
        name: ''
      },
      family: {
        id: '',
        name: ''
      },
      name: '',
      quantity: 0,
      reference_links: [''],
      isRequest: true
    }
  })
  const { enqueueSnackbar } = useSnackbar()

  const onChangeFamily: (event: React.SyntheticEvent<Element, Event>, value: any) => void = async (event, newValue) => {
    setData({ ...data, loading: true })
    const family = data.listFamily.find(fam => fam.name === newValue.name)

    saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, family: { parent_id: 0, id: (newValue === null) ? '' : (newValue.id === undefined) ? 0 : newValue.id, name: (newValue === null) ? '' : (newValue.name === undefined) ? newValue : newValue.name }, subfamily: { id: 0, name: '', parent_id: 0 } } })
    if (family === undefined) {
      setData({ ...data, loading: false, listSubFamily: [] })
    } else {
      const response : any = await webApiService.getAllSuppliesFamily(family.id).then((res) => res).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
      })
      const listSubFamily = await response.data
      setData({ ...data, loading: false, listSubFamily })
    }
  }

  const loadData = async () => {
    setData({ ...data, loading: true })
    const response : any = await Promise.all([
      webApiService.getAllSuppliesCategory().then((res) => res),
      webApiService.getAllSuppliesFamily(0).then((res) => res)
    ]).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    })
    const listCat = await response[0].data
    const listFamily = await response[1].data

    if (supplyCtx.supply.id > 0) {
      const family = listFamily.find((fam: any) => fam.name === supplyCtx.supply.family.name)

      const response : any = await webApiService.getAllSuppliesFamily(family.id).then((res) => res).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
      })
      const listSubFamily = await response.data
      setData({ ...data, loading: false, listCat, listFamily, listSubFamily })
    } else {
      setData({ ...data, loading: false, listCat, listFamily })
    }
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='row gy-3'>
      <div className='col-12 d-flex align-items-center'>
        <Typography variant='h6' className='py-2 color-title'>
          <AccountCircleIcon className='color-azul' /> CATEGORÍA
        </Typography>
      </div>
      <div className='col-12 col-lg-3'>
        <FormControl fullWidth>
          <Autocomplete
            value={supplyCtx.supply.category}
            onChange={(event, newValue) => {
              saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, category: { id: (newValue === null) ? '' : (newValue.id === undefined) ? 0 : newValue.id, name: (newValue === null) ? '' : (newValue.name === undefined) ? newValue : newValue.name } } })
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='free-solo-with-text-demo'
            options={data.listCat}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue
              }
              // Regular option
              return option.name
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label='Categoría' helperText='Para ingresar un valor nuevo se debe presionar Enter' />
            )}
          />
        </FormControl>
      </div>
      <div className='col-12 col-lg-3'>
        <TextField
          type='text'
          name='variant_color'
          label='Variante o Color'
          fullWidth
          value={supplyCtx.supply.variant_color}
          onChange={(e) => saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, variant_color: e.target.value } })}
        />
      </div>
      <div className='col-12 col-lg-3'>
        <FormControl fullWidth>
          <Autocomplete
            value={supplyCtx.supply.family}
            onChange={onChangeFamily}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='free-solo-with-text-demo'
            options={data.listFamily}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option
              }
              // Add "xxx" option created dynamically
              if (option.name) {
                return option.name
              }
              // Regular option
              return option.name
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label='Familia' helperText='Para ingresar un valor nuevo se debe presionar Enter' />
            )}
          />
        </FormControl>
      </div>
      <div className='col-12 col-lg-3'>
        <FormControl fullWidth>
          <Autocomplete
            value={supplyCtx.supply.subfamily}
            onChange={(event, newValue) => {
              saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, subfamily: { parent_id: supplyCtx.supply.family.id, id: (newValue === null) ? '' : (newValue.id === undefined) ? 0 : newValue.id, name: (newValue === null) ? '' : (newValue.name === undefined) ? newValue : newValue.name } } })
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='free-solo-with-text-demo'
            options={data.listSubFamily}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option
              }
              // Add "xxx" option created dynamically
              if (option.name) {
                return option.name
              }
              // Regular option
              return option.name
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label='Sub-Familia' helperText='Para ingresar un valor nuevo se debe presionar Enter' />
            )}
          />
        </FormControl>
      </div>
    </div>
  )
}

export default Category

import { LoadingButton } from '@mui/lab'
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ModalOTList from './components/ModalOTList'
import { webApiService } from '@services/index'

const DeliverySuppliesPage = () => {
  const [modal, setModal] = useState({ open: false, idSupply: null, inventory: 0 })
  const [data, setData] = useState<any>([])
  const [page, setPage] = useState<number>(0)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1)
  }

  const loadData = async () => {
    const data = await webApiService.getSupplyForDelivery().then(res => res.data)
    console.log('Data de useEffect => ', data)
    setData(data)
  }

  useEffect(() => {
    if (!modal.open) {
      loadData()
    }
  }, [modal.open])

  return (
    <div>
      <div className='container-xxl '>
        <div className='row gy-3 my-4'>
          <div className='col-12 my-2'>
            <Typography variant='h6' className='title mb-3'>
              ENTREGA DE INSUMOS
            </Typography>

            <div className='shadow-sm rounded bg-white p-3'>
              <div className='tabla-ot' data-tour='tabla-ot'>
                <TableContainer className='rounded'>
                  <Table
                    aria-labelledby='tableTitle'
                    stickyHeader
                    className='tableMUI tbl-reuse'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className='tbl-header border-bottom text-center'>id</TableCell>
                        <TableCell className='tbl-header border-bottom text-center'>Nombre</TableCell>
                        <TableCell className='tbl-header border-bottom text-center'>Cantidad de solicitudes asignadas por OT</TableCell>
                        <TableCell className='tbl-header border-bottom text-center'>Cantidad insumo pendiete entrega</TableCell>
                        <TableCell className='tbl-header border-bottom text-center'>Entregados</TableCell>
                        <TableCell className='tbl-header border-bottom text-end' />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.slice(page * 15, (page * 15) + 15).map((supply: any, i: number) => (
                        <TableRow key={i}>
                          <TableCell className='tbl-header border-bottom text-center'>{supply.id}</TableCell>
                          <TableCell className='tbl-header border-bottom text-center'>{supply.name}</TableCell>
                          <TableCell className='tbl-header border-bottom text-center'>{supply.cantidad_ot}</TableCell>
                          <TableCell className='tbl-header border-bottom text-center'>{supply.cantidad_total - supply.entregados}</TableCell>
                          <TableCell className='tbl-header border-bottom text-center'>{supply.entregados}</TableCell>
                          <TableCell className='tbl-header border-bottom text-end'>
                            <LoadingButton variant='contained' color='warning' className='shadow-none' onClick={() => setModal({ open: true, idSupply: supply.id, inventory: supply.inventory })}>Entregar</LoadingButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {data.length ? <Pagination className='my-4' count={Math.ceil(data.length / 15)} onChange={handleChangePage} color='primary' /> : null}
                {modal.open && <ModalOTList modal={modal} setModal={setModal} inventory={modal.inventory} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeliverySuppliesPage

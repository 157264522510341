import { PrivateRoutes } from '@constants/routes.constants'
import { Button } from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BtnCancel = () => {
  const navigate = useNavigate()
  const { formCtx } = useOTFormContext() as FormContextType

  return (
    <Button
      variant='contained'
      size='medium'
      color='secondary'
      className='m-2 btn-secondary'
      onClick={() => navigate(PrivateRoutes.OTLIST)}
      disabled={formCtx.loading}
    >
      Cancelar
    </Button>

  )
}

export default BtnCancel

import { Typography } from '@mui/material'
import TblSuppliesRequestList from './components/SuppliesRequestList/TblSuppliesRequestList'

const SuppliesRequestPage = () => {
  return (
    <div className='container-xxl '>
      <div className='row gy-3 my-4'>
        <div className='row col-12 my-2'>
          <div className='col-8 my-2'>
            <Typography variant='h6' className='title mb-3'>
              Gestión de Solicitudes
            </Typography>
          </div>
          <div className='shadow-sm rounded bg-white p-3'>
            <TblSuppliesRequestList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliesRequestPage

import React from 'react'
import { InputSearchFilter } from './components/InputSearchFilter'
import { TblReport } from './components/TblReport'
import { OTSuppliesReportProvider } from './context/OTSuppliesReport.context'

const OTSuppliesReport = () => {
  return (
    <OTSuppliesReportProvider>
      <div className='container-xxl bg-white mt-0 mt-lg-3'>
        <div className='row gy-3 my-4 my-xs-2'>
          <div className='col-12'>
            <InputSearchFilter />
          </div>
          <div className='col-12'>
            <TblReport />
          </div>
        </div>
      </div>
    </OTSuppliesReportProvider>

  )
}

export default OTSuppliesReport

import { ReactComponent as Excel } from '@assets/svg/excel.svg'
import { SuppliesDataReport } from '@models/reportData.model'
import { Badge, Button, Checkbox } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { SuppliesRequestContextType, useSuppliesRequestContext } from '@pages/SuppliesRequest/context/RequestList.context'
import { exportTableToExcel } from '@utils/Excel.util'
import { getComparator, stableSort } from '@utils/tables.util'
import * as React from 'react'
import { Order } from '../../../../types'

import { UTCDateToCountryDate } from '@utils/countryDate.util'
import EnhancedTableReportFooter from './EnhancedTableFooter'
import EnhancedTableHead from './EnhancedTableHeadCells'
import TblSuppliesRequestListExcel from './TblSuppliesRequestListExcel'

const TblSuppliesRequestList = () => {
  const { suppliesRequestCtx, saveSuppliesRequestCtx } = useSuppliesRequestContext() as SuppliesRequestContextType
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof SuppliesDataReport>('id')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SuppliesDataReport
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  // const [checkedCount, setCheckedCount] = React.useState<number>(0)

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     setCheckedCount((count) => count + 1)
  //   } else {
  //     setCheckedCount((count) => count - 1)
  //   }
  // }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveSuppliesRequestCtx({
      ...suppliesRequestCtx,
      data:
      {
        ...suppliesRequestCtx.data,
        items: suppliesRequestCtx.data.items.map((e:any) => {
          return {
            ...e, selected: event.target.checked
          }
        })
      }
    })
  }

  const handleClick = (event: React.MouseEvent<unknown>, row: string) => {
    const selectedIndex = selected.indexOf(row)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (id: string) =>
    selected.indexOf(id) !== -1

  return (
    <div>
      <div className='d-flex justify-content-end h-100  my-2'>
        <Badge
          badgeContent={suppliesRequestCtx.data && suppliesRequestCtx.data.items.filter((e:any) => e.selected).length}
          color='warning'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          className='btn-excel'
        >
          <Button
            variant='contained' color='success' size='small' className='m-2'
            onClick={() => exportTableToExcel(
              'tblReport',
              'Reporte GESTIÓN DE SOLICITUDES'
            )}
          >
            <Excel className='me-2' />Descargar Informe
          </Button>
        </Badge>

      </div>
      <TableContainer className='rounded'>
        <Table
          aria-labelledby='tableTitle'
          stickyHeader
          className='tableMUI tbl-reuse'
        >
          <EnhancedTableHead
            numSelected={suppliesRequestCtx.data === null ? 0 : suppliesRequestCtx.data.items.filter((e:any) => e.selected).length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={suppliesRequestCtx.data === null ? 0 : suppliesRequestCtx.data.items.length}
          />
          {
            suppliesRequestCtx.data !== null && !suppliesRequestCtx.loading
              ? (
                <TableBody>
                  {stableSort(suppliesRequestCtx.data.items, getComparator(order, orderBy))

                    .map((row, index) => {
                      const isItemSelected = isSelected(row)
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          aria-checked={isItemSelected}
                          onClick={(event) => handleClick(event, row)}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}

                        >
                          <TableCell align='center'>
                            <Checkbox
                              color='primary'
                              checked={row.selected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                              onChange={(e) => {
                                const newArray : any[] = [].concat(suppliesRequestCtx.data.items)
                                newArray.find((el : any) => el.id === row.id).selected = e.target.checked
                                saveSuppliesRequestCtx({ ...suppliesRequestCtx, data: { ...suppliesRequestCtx.data, items: newArray } })
                              }}
                            />
                          </TableCell>
                          <TableCell id={labelId} scope='row' align='center'>{row.id_request}</TableCell>
                          <TableCell align='center'>{row.technician}</TableCell>
                          <TableCell align='center'>{row.type}</TableCell>
                          <TableCell align='center'>{row.supply}</TableCell>
                          <TableCell align='center'>{row.reference_links}</TableCell>
                          <TableCell align='center'>{UTCDateToCountryDate(row.created_at)}</TableCell>
                          <TableCell align='center'>{row.quantity}</TableCell>

                        </TableRow>
                      )
                    })}
                </TableBody>
                )
              : suppliesRequestCtx.loading ? <TableBody><TableRow tabIndex={-1}><TableCell colSpan={11} align='center'>Cargando...</TableCell></TableRow></TableBody> : null
          }
          <EnhancedTableReportFooter />
        </Table>
      </TableContainer>
      {/* EXCEL* */}
      {/* {JSON.stringify({ hola: dataReport.data })} */}
      <TblSuppliesRequestListExcel dataReport={(suppliesRequestCtx.data === null) ? [] : suppliesRequestCtx.data.items.filter((e : any) => e.selected).length === 0 ? suppliesRequestCtx.data.items : suppliesRequestCtx.data.items.filter((e : any) => e.selected)} />
    </div>
  )
}

export default TblSuppliesRequestList

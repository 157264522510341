
import { Footer } from '@components/Footer'
import { Header } from '@components/Header'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { IAppStore } from '@models/store.model'
import { useSelector } from 'react-redux'

const Layout: React.FC = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  return (
    <div className={userState.id !== 0 ? 'wrapper d-flex flex-column my-5 p-1 h-100 w-100' : 'wrapper d-flex flex-column p-1 h-100 w-100'}>
      {userState.id !== 0
        ? (
          <Header />
          )
        : null}
      <main className='flex-fill position-relative pt-lg-3 pb-lg-5'>
        <Outlet />
        <Footer />
      </main>

    </div>
  )
}

export default Layout

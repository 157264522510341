import { PrivateRoutes } from '@constants/routes.constants'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const BtnCancel = () => {
  const navigate = useNavigate()

  return (
    <Button
      variant='contained'
      size='medium'
      color='secondary'
      className='m-2 btn-secondary'
      onClick={() => navigate(PrivateRoutes.SUPPLIES_MANAGEMENT)}
    >
      Cancelar
    </Button>

  )
}

export default BtnCancel

import { CloseIcon } from '@assets/icons/Icons'
import { ModalNames } from '@constants/modal.constants'
import { Transition } from '@constants/TransitionModal.contants'
import { ModalProps } from '@models/modals.model'
import { Card, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material'
import { webApiService } from '@services/index'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'

const ModalHistoryPhoneCheck: React.FC<ModalProps> = ({ modal, setModal }) => {
  const [data, setData] = useState<{pcheck:any, loading: boolean }>({ pcheck: null, loading: false })
  // función histotial phonecheck

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getAllPCheck(modal.parametros.serial).then((response) => {
      setData({ pcheck: response.items, loading: false })
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (modal.visible && modal.name === ModalNames.HISTORYPHONECHECK) { loadData() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.parametros.serial])
  return (
    <Dialog
      open={modal.visible && modal.name === ModalNames.HISTORYPHONECHECK}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      fullScreen
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }} className='d-flex align-items-center'>
            <span>HITORIAL PHONECHECK:</span> {data.pcheck !== null && !data.loading ? <span className='badge rounded-pill text-bg-light ms-2'>{modal.parametros.serial}</span> : null}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setModal({ ...modal, visible: false, name: ModalNames.HISTORYPHONECHECK })}
            className='text-white'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <Card className='shadow-sm'>
          <CardContent>
            {data.loading
              ? (
                <Table striped responsive>
                  <tr className='text-center'>
                    <td colSpan={22}><CircularProgress size={20} /> Cargando...</td>
                  </tr>
                </Table>
                )
              : (
                <Table striped responsive>

                  <thead className='text-center small'>

                    <tr>
                      <th scope='col'>User Id</th>
                      <th scope='col'>Invoice No</th>
                      <th scope='col'>Vendor Name</th>
                      <th scope='col'>IMEI</th>
                      <th scope='col'>Make</th>
                      <th scope='col'>Model</th>
                      <th scope='col'>Model Number</th>
                      <th scope='col'>Country of Origin</th>
                      <th scope='col'>Memory</th>
                      <th scope='col'>Color</th>
                      <th scope='col'>Network Details</th>
                      <th scope='col'>OS</th>
                      <th scope='col'>Notes</th>
                      <th scope='col'>Cosmetics</th>
                      <th scope='col'>ESN</th>
                      <th scope='col'>Battery Health Percentage</th>
                      <th scope='col'>Add Date</th>
                      <th scope='col'>Update Date</th>
                      <th scope='col'>100% Working</th>
                      <th scope='col'>Failed</th>
                      <th scope='col'>Erased</th>
                      <th scope='col'>Erasure Certificate Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.pcheck && data.pcheck.length > 0 && data.pcheck.map((row: any, i: number) => (
                      <tr key={i + 1} className='text-center'>
                        <td>{row.StationID}</td>
                        <td>{row.InvoiceNo}</td>
                        <td>{row.VendorName}</td>
                        <td>{row.IMEI}</td>
                        <td>{row.Make}</td>
                        <td>{row.Model}</td>
                        <td>{row.ModelNo}</td>
                        <td>{row.CountryOfOrigin}</td>
                        <td>{row.Memory}</td>
                        <td>{row.color}</td>
                        <td>{row.network}</td>
                        <td>{row.OS}</td>
                        <td>{row.Notes}</td>
                        <td>{row.Cosmetics}</td>
                        <td>{row.ESN}</td>
                        <td>{parseInt(row.BatteryHealthPercentage)} %</td>
                        <td>{row.DeviceCreatedDate !== null ? moment(row.DeviceCreatedDate.split('.')[0]).format('DD-MM-YYYY hh:mm:ss') : ''}</td>
                        <td>{row.DeviceUpdatedDate !== null ? moment(row.DeviceUpdatedDate.split('.')[0]).format('DD-MM-YYYY hh:mm:ss') : ''}</td>
                        <td>{row.Working}</td>
                        <td>{row.Failed}</td>
                        <td>{row.Erased}</td>
                        <td> {row.erasureReportLink !== '' ? <a href={row.erasureReportLink} target='_blank' rel='noopener noreferrer'>LINK</a> : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                )}
          </CardContent>
        </Card>

      </DialogContent>
    </Dialog>

  )
}

export default ModalHistoryPhoneCheck

import { CloseIcon } from '@assets/icons/Icons'
import { ModalNames } from '@constants/modal.constants'
import { Transition } from '@constants/TransitionModal.contants'
import { ModalProps } from '@models/modals.model'
import { Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Dropzone } from '../../Dropzone'

const ModalFiles: React.FC<ModalProps> = ({ modal, setModal }) => {
  return (
    <Dialog
      open={modal.visible && modal.name === ModalNames.FILES}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      fullScreen
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }} className='d-flex align-items-center'>
            <span>ARCHIVOS OT</span>
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setModal({ ...modal, visible: false })}
            className='text-white'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <div className='shadow-sm bg-white rounded p-4'>
          <Dropzone />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalFiles

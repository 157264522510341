import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { UTCDateToCountryDate } from '@utils/countryDate.util'
import React from 'react'

const TblReportExcel = ({ dataReport }: any) => {
  return (
    <div>
      <Table
        size='small'
        aria-labelledby='tableTitle'
        id='tblReport'
        border={1}
        hidden
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              OT
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Prioridad
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Estado Actual OT
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Responsable estado
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Tipo de Documento
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Número Boleta
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >Fecha de Venta
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Canal de Venta
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Cliente
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Rut Cliente
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Correo Cliente
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Teléfono
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Producto
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Serial/IMEI
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              SKU
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Unidad de Negocio (sbu)
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Tipo Flujo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Tipo de Ingreso
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Solicitud Codigo Desbloqueo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Solicitud Desvinculación
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Código
            </TableCell>
            {/*  <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Estado OT
            </TableCell> */}
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Estado Diagnostico
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Estado Información
            </TableCell>

            {/*    <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Días Disponible ST
            </TableCell> */}
            {/*   <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Día Notificado diagnostico
            </TableCell> */}
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Tipo de Falla
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Observación Falla
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Estado Cosmetico
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Categoria Cosmetica
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Pieza Falla Principal
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Observación Pieza Falla Principal
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Pieza Falla Secundaria
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Observación Pieza Falla Secundaria
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Usuario Notificado
            </TableCell>

            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Diagnostico información cliente
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Fecha Creación
            </TableCell>

            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Numero NC
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Fecha NC
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Bodega NC
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Bodega Ingreso
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Bodega Traspaso
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Usuario Recepción
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Usuario Asignado
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Tecnico Asignado
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Días disponible ST
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Días notificado ST
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Días totales
            </TableCell>
            {/* insumos */}
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Categoría insumo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Familia insumo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              SubFamilia insumo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              SKU insumo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Nombre insumo
            </TableCell>
            <TableCell
              style={{ textAlign: 'center', backgroundColor: '#f7f7ff' }}
            >
              Variante color insumo
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {dataReport
            ? dataReport.map((row: any, index: number) => (
              <TableRow key={index + 1}>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.id}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.priority === 1 ? 'SI' : 'NO'}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.status}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.status_manager}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.type_document === 0 ? 'Boleta' : row.type_document === 1 ? 'Factura' : row.type_document === 2 ? 'Guia de despacho' : 'IMEI/SERIAL'}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.id_invoice}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.date_invoice === null ? 'S/I' : row.date_invoice}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.channel_name}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.client_name}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.client_rut}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.client_email}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.client_phone}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.product_name}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.product_serial}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.product_sku}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.business_unit}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.name_flow}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.reception_type}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.flag_unlock_code === 1 ? 'SI' : 'NO'}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.flag_disassociation === 1 ? 'SI' : 'NO'}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.unlock_code}</TableCell>

                <TableCell style={{ textAlign: 'center' }}>
                  {row.diagnostic_information}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.information_status}
                </TableCell>
                {/*  <TableCell style={{ textAlign: 'center' }}>
                  {parseInt(row.day_available_ST)}
                </TableCell> */}
                {/*  <TableCell style={{ textAlign: 'center' }}>
                  {parseInt(row.diagnosis_notifications_days)}
                </TableCell> */}
                <TableCell style={{ textAlign: 'center' }}>{row.failure_name}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.failure_description}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.cosmetic_description}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.id_cosmetic_status} - {row.category_cosmetic}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.main_component_failure}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.comments_ppl_failure}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.secondary_component_failure}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.comments_snd_failure}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{parseInt(row.client_notification) === 1 ? 'SI' : 'NO'}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.diagnostic_information}
                </TableCell>

                <TableCell style={{ textAlign: 'center' }}>
                  {UTCDateToCountryDate(row.created_at)}
                </TableCell>

                <TableCell style={{ textAlign: 'center' }}>{row.NC_number}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.NC_date}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.warehouse_NC}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.warehouse_reception}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{row.warehouse_transfer}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.name_user_register}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.name_user_assigned}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.name_assigned_technician}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {Math.sign(row.day_diagnostico_ST) === -1 ? Math.abs(row.day_diagnostico_ST) : parseInt(row.day_diagnostico_ST)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {Math.sign(row.diagnosis_notifications_days) === -1 ? Math.abs(row.diagnosis_notifications_days) : parseInt(row.diagnosis_notifications_days)}

                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {Math.sign(row.day_total) === -1 ? Math.abs(row.day_total) : parseInt(row.day_total)}
                </TableCell>
                {/* insumos */}
                <TableCell style={{ textAlign: 'center' }}>
                  {row.category}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.family}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.subfamily}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.sku}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.name}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {row.variant_color}
                </TableCell>
              </TableRow>
            ))
            : <TableRow><TableCell colSpan={21}>Sin información</TableCell></TableRow>}
        </TableBody>
      </Table>
    </div>
  )
}

export default TblReportExcel

import { PrivateRoutes } from '@constants/routes.constants'
import { IAppStore } from '@models/store.model'
import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { SuppliesContextType, useSuppliesContext } from '@pages/SuppliesManagement/context/Supplies.context'
import { editTableRole } from '@utils/Roles.util'
import { getComparator, stableSort } from '@utils/tables.util'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AssignmentsGuard } from 'src/guards'
import { Order } from '../../../../types'
import EnhancedTableFooter from './EnhancedTableFooter'
import EnhancedTableHead from './EnhancedTableHeadCells'

const TblSuppliesList = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  const { suppliesCtx } = useSuppliesContext() as SuppliesContextType
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<any>('')
  const navigate = useNavigate()
  const [selected, setSelected] = React.useState<readonly string[]>([])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc'

    setOrder(isAsc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = suppliesCtx.data.map((n : any) => n.id.toString())
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleLoadSupply = (_idSupply: number) => {
    navigate(PrivateRoutes.EDIT_SUPPLY + _idSupply)
  }

  return (
    <div>

      <TableContainer className='rounded'>
        <Table
          aria-labelledby='tableTitle'
          stickyHeader
          className='tableMUI tbl-reuse'
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={suppliesCtx.data === null ? 0 : suppliesCtx.data.length}
          />
          {
            suppliesCtx.data !== null && !suppliesCtx.loading
              ? (
                <TableBody>
                  {stableSort(suppliesCtx.data.items, getComparator(order, orderBy))

                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          <TableCell align='center'>{row.id}</TableCell>
                          <TableCell align='center'>{row.name}</TableCell>
                          <TableCell align='center'>{row.variant_color}</TableCell>
                          <TableCell align='center'>{row.category}</TableCell>
                          <TableCell align='center'>{row.family}</TableCell>
                          <TableCell align='center'>{row.subfamily}</TableCell>
                          <TableCell align='center'>{row.inventory}</TableCell>
                          <TableCell align='center'>
                            <AssignmentsGuard
                              // userAssigned={row.name_user_assigned}
                              externalCondition
                              userAssigned={row.type_document !== null ? row.name_user_assigned : ''}
                              userAssignedTechnician={row.name_assigned_technician}
                              PublicValidationFragment={null}
                            >
                              <LoadingButton variant='contained' color='warning' className='shadow-none' onClick={() => handleLoadSupply(row.id)} hidden={editTableRole(userState, row)}><Edit /></LoadingButton>
                            </AssignmentsGuard>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
                )
              : suppliesCtx.loading ? <TableBody><TableRow tabIndex={-1}><TableCell colSpan={11} align='center'>Cargando...</TableCell></TableRow></TableBody> : null
          }
          <EnhancedTableFooter />
        </Table>
      </TableContainer>
    </div>
  )
}

export default TblSuppliesList

import FileUploadIcon from '@mui/icons-material/FileUpload'
import RestoreIcon from '@mui/icons-material/Restore'
import { AttachFile } from '@mui/icons-material'
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, FormControl, TextField, Typography } from '@mui/material'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { BtnCancel } from '../BtnCancel'
import { DescriptionIcon } from '@assets/icons/Icons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import IconHistory from './iconHistory'
import { sendInvoiceToS3 } from '@utils/s3Utils'
import { PrivateRoutes } from '@constants/routes.constants'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { isValidFormEditSupply } from '@utils/ValidateForm.util'
import { IESupply } from '@models/OT.model'

const EditSupply = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = React.useState<{
    loading: boolean,
    loadingImei: boolean,
    loadingName: boolean,
    supply: any[],
    supply_history: any[],
    initialSupply: {
      id: number,
      provider:string,
      inventory: number,
      cost:number,
      last_invoice: string,
      selectedFile: File | null
    },
    dataProduct: any,
    knowSku: boolean,
    listProduct: any[],
    searchType: number,
    serial: string,
    supplyUpdate: IESupply,
    supplyID: number,
    type:number,
    supplyImage: string,
    fileName: string,
    FormState: {
      providerField: string,
      invoiceField: string,
      costField: number,
      quantityField: number,
      file: File | null,
      isFileSelected: boolean
    },
    loadingImage: boolean
    }>(
      {
        loading: false,
        loadingImei: false,
        loadingName: false,
        supply: [],
        supply_history: [],
        initialSupply: {
          id: 0,
          provider: '',
          inventory: 0,
          cost: 0,
          last_invoice: '',
          selectedFile: null
        },
        dataProduct: null,
        knowSku: false,
        listProduct: [],
        searchType: 1,
        serial: '',
        supplyUpdate: {
          id: 0,
          provider: '',
          inventory: 0,
          cost: 0,
          last_invoice: '',
          selectedFile: null
        },
        supplyID: 0,
        type: 0,
        supplyImage: '',
        fileName: '',
        FormState: {
          providerField: '',
          invoiceField: '',
          costField: 0,
          quantityField: 0,
          file: null,
          isFileSelected: false
        },
        loadingImage: true
      }
    )
  const [showCount, setShowCount] = useState<number>(5)
  const handleLoadMore = () => {
    setShowCount(prev => prev + 5)
  }
  const loadSupply = async () => {
    setData({ ...data, loading: true })

    // const response : any = await Promise.all([
    //   webApiService.getAllById(Number(id)).then((response) => response),
    //   webApiService.getHistoryById(Number(id)).then((response) => response)
    // ]).catch((error) => {
    //   console.log(error)
    //   enqueueSnackbar(error.message, { variant: 'error' })
    // })

    async function checkURL (url: string): Promise<boolean> {
      try {
        const response = await fetch(url)
        return response.ok
      } catch (error) {
        console.error(error)
        return false
      }
    }
    const fetchSupplyData = async (id: any) => {
      try {
        const response : any = await Promise.all([
          webApiService.getAllById(Number(id)).then((response) => response),
          webApiService.getHistoryById(Number(id)).then((response) => response)
        ]).catch((error) => {
          console.log(error)
          enqueueSnackbar(error.message, { variant: 'error' })
        })

        const supplyImageUrl = `https://${String(process.env.REACT_APP_AWS_BUCKET)}.s3.amazonaws.com/supplies/${id}/image/supply-image.jpg`
        const supplyNoImageUrl = `https://${String(process.env.REACT_APP_AWS_BUCKET)}.s3.amazonaws.com/supplies/reuse-noimage.jpg`
        let imageUrl = supplyNoImageUrl

        const result = await checkURL(supplyImageUrl)
        if (result) {
          imageUrl = supplyImageUrl
        }

        setData({
          ...data,
          loading: false,
          supply: response[0].data,
          supply_history: response[1].data,
          supplyImage: imageUrl,
          loadingImage: false
        })
      } catch (error) {
        console.log(error)
      }
    }
    fetchSupplyData(id)
  }
  const formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
  })

  const handleFileSelect = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files && event.target.files[0]
      setData({
        ...data,
        supplyID: id,
        supplyUpdate: {
          ...data.supplyUpdate,
          selectedFile: file
        }
      })
    }
  }

  const handleSumbmit = async (event:any, id: number) => {
    setData({ ...data, loading: true })
    const fileName = String(data.supplyUpdate.selectedFile?.name)
    await webApiService.updateSupply({ ...data.supplyUpdate, id }).then(async res => {
      if (res.success) {
        setData({ ...data, loading: false })
        if (data.supplyUpdate.selectedFile !== null) {
          sendInvoiceToS3(data.supplyID, data.supplyUpdate.selectedFile)
        }
        await webApiService.registerInvoice({ supplyID: id, fileName }).then(response => response.data)
        enqueueSnackbar('Se ha cargado el insumo con éxito.', { variant: 'success' })
        navigate(PrivateRoutes.SUPPLIES_MANAGEMENT, { replace: true })
      }
    }).catch(error => {
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
      setData({ ...data, loading: false })
    })
  }

  const handleResetFile = () => {
    setData({
      ...data,
      supplyUpdate: {
        ...data.supplyUpdate,
        selectedFile: null
      }
    })
  }

  useEffect(() => {
    loadSupply()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='container-lg'>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            FICHA DEL INSUMO
          </Typography>
          <div className='shadow-sm rounded bg-white p-3'>
            <div className='container-xxl bg-white'>
              <div className='bg-white py-3 row'>
                <Typography variant='h6' component='div' className='color-title'>
                  <DescriptionIcon className='color-azul' /> Detalle
                </Typography>
                <div className='col-12 mt-4'>
                  {
                    data.supply.map((e: any, i: number) => {
                      return (
                        <h4 key={i} className='color-title'>
                          {e.name} <Typography component='small' className={e.inventory > 0 ? 'badge rounded-pill text-bg-success ms-2 h6 m-0' : 'badge rounded-pill text-bg-danger ms-2 h6 m-0'}>{e.inventory} unidades disponibles</Typography>
                        </h4>
                      )
                    })
                  }
                </div>
                <div className='col-3 mt-4'>
                  {data.loadingImage
                    ? (
                      <CircularProgress />
                      )
                    : (
                      <Box
                        component='img'
                        sx={{
                          width: 1
                        }}
                        alt='Imagen insumo'
                        src={data.supplyImage}
                      />
                      )}
                </div>
                <div className='col-9 mt-4'>
                  <div className='row'>

                    <div className='col-6 mt-3'>
                      <FormControl fullWidth>
                        {
                          data.supply.map((e: any, i: number) => {
                            return (
                              <TextField id='outlined-basic' value={e.type_name} key={i} label='Tipo de insumo' disabled fullWidth />)
                          })
                        }
                      </FormControl>
                    </div>

                    <div className='col-6 mt-3'>
                      {
                        data.supply.map((e: any, i: number) => {
                          return (
                            <TextField id='outlined-basic' value={e.name} key={i} label='Nombre' disabled fullWidth />)
                        })
                      }
                    </div>
                    <div className='col-6 mt-3'>
                      {
                        data.supply.map((e: any, i: number) => {
                          return (
                            <TextField id='outlined-basic' value={e.brand} key={i} label='Marca' disabled fullWidth />)
                        })
                      }
                    </div>
                    <div className='col-6 mt-3'>
                      {
                        data.supply.map((e: any, i: number) => {
                          return (
                            <TextField id='outlined-basic' value={e.model} key={i} label='Modelo' disabled fullWidth />)
                        })
                      }
                    </div>
                    <div className='col-6 mt-3'>
                      {
                        data.supply.map((e: any, i: number) => {
                          return (
                            <TextField id='outlined-basic' value={e.sku} key={i} label='Sku' disabled fullWidth />)
                        })
                      }
                    </div>
                    <div className='col-6 mt-3'>
                      {
                        data.supply.map((e: any, i: number) => {
                          return (
                            <TextField id='outlined-basic' value={e.serial} key={i} label='Serie' disabled fullWidth />)
                        })
                      }
                    </div>
                    <div className='col-6 mt-3'>
                      {
                        data.supply.map((e: any, i: number) => {
                          return (
                            <TextField id='outlined-basic' value={e.provider} key={i} label='Último Proveedor' disabled fullWidth />)
                        })
                      }
                    </div>
                    <div className='col-6 mt-3'>
                      {
                        data.supply.map((e: any, i: number) => {
                          return (
                            <TextField id='outlined-basic' value={formatter.format(e.cost)} key={i} label='Último valor por unidad' disabled fullWidth />)
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-4 mb-4'><hr /></div>
                <div className='col-12'>
                  <Typography variant='h6' component='div' className='color-title mb-4'>
                    <FileUploadIcon className='color-azul' /> CARGAR NUEVAS UNIDADES
                  </Typography>
                  <div className='row gy-3 mt-2'>

                    <div className='col-5'>
                      <TextField
                        id='outlined-basic'
                        label='Proveedor'
                        variant='outlined'
                        name='provider'
                        fullWidth
                        value={data.supplyUpdate.provider}
                        onChange={(e) => setData({ ...data, supplyUpdate: { ...data.supplyUpdate, provider: e.target.value } })}
                      />
                    </div>

                    <div className='col-3'>
                      <TextField
                        id='outlined-basic'
                        label='N° Factura/Boleta'
                        variant='outlined'
                        name='last_invoice'
                        fullWidth
                        value={data.supplyUpdate.last_invoice}
                        onChange={(e) => setData({ ...data, supplyUpdate: { ...data.supplyUpdate, last_invoice: e.target.value } })}
                      />
                    </div>
                    <div className='col-2'>
                      <TextField
                        id='outlined-basic'
                        label='Valor por unidad'
                        variant='outlined'
                        name='cost'
                        value={data.supplyUpdate.cost}
                        onChange={(e) => setData({ ...data, supplyUpdate: { ...data.supplyUpdate, cost: Number(e.target.value) } })}
                      />
                    </div>
                    <div className='col-2'>
                      <TextField
                        id='outlined-basic'
                        type='number'
                        label='Cantidad'
                        variant='outlined'
                        name='inventory'
                        fullWidth
                        value={data.supplyUpdate.inventory}
                        onChange={(e) => setData({ ...data, supplyUpdate: { ...data.supplyUpdate, inventory: Number(e.target.value) } })}
                      />
                    </div>
                  </div>
                  <div className='col-12 mt-2 row'>
                    <div className='col-2'>
                      <Button component='label' variant='contained' startIcon={<AttachFile />}>
                        Adjuntar factura
                        {data.supply.map((e: any, i: number) => (
                          <input key={e.id} type='file' hidden onChange={(event) => handleFileSelect(e.id, event)} />

                        ))}

                      </Button>
                    </div>
                    <div className='col-10'>
                      {data.supplyUpdate.selectedFile
                        ? (
                          <div>
                            <p>
                              <Alert severity='success'>{data.supplyUpdate.selectedFile.name}
                                <RemoveCircleIcon onClick={handleResetFile} className='text-danger cursor-pointer' color='action' sx={{ fontSize: 20, mx: 1 }} titleAccess='Quitar archivo'>
                                  Borrar archivo
                                </RemoveCircleIcon>
                              </Alert>

                            </p>

                          </div>
                          )
                        : <Alert severity='warning'>Debe adjuntar la factura (se permiten archivos .pdf, .jpeg y .png).</Alert>}
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-4 mb-4'><hr /></div>
                <div className='col-12'>
                  <Typography variant='h6' component='div' className='color-title mb-4'>
                    <RestoreIcon className='color-azul' /> HISTORIAL DE MOVIMIENTOS
                  </Typography>
                  <div className='row gy-3 mt-2'>

                    <div className='col-12'>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <Typography>Ver historial</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Timeline
                            sx={{
                              [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0
                              }
                            }}
                          >
                            {data.supply_history.length > 0
                              ? (
                                  data.supply_history
                                    .slice(-showCount)
                                    .map((e: any, i: number) => {
                                      return (
                                        <TimelineItem key={i}>
                                          <TimelineSeparator>
                                            <TimelineDot
                                              color={
                                                e.type === 1
                                                  ? 'primary'
                                                  : e.type === 2
                                                    ? 'warning'
                                                    : e.type === 3
                                                      ? 'warning'
                                                      : e.type === 4
                                                        ? 'error'
                                                        : 'success'
                                              }
                                            >
                                              <IconHistory type={e.type} />
                                            </TimelineDot>
                                            <TimelineConnector />
                                          </TimelineSeparator>
                                          <TimelineContent>
                                            <Typography variant='h6' component='span'>
                                              <Typography variant='subtitle1' component='span'>
                                                {moment(e.created_at).format('YYYY-MM-DD')}
                                              </Typography>{' '}
                                              |{' '}
                                              {e.type === 1
                                                ? 'Carga de unidades'
                                                : e.type === 2
                                                  ? 'Solicitud'
                                                  : e.type === 3
                                                    ? 'Solicitud lista'
                                                    : 'Uso de unidades'}
                                            </Typography>
                                            <Typography>{e.history}</Typography>
                                            {e.invoice !== null && (
                                              <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                                                Descargar factura:{' '}
                                                <a
                                                  color='primary'
                                                  href={`https://${String(
                                                      process.env.REACT_APP_AWS_BUCKET
                                                    )}.s3.amazonaws.com/supplies/${id}/invoice/${e.invoice}`}
                                                >
                                                  {e.invoice}
                                                </a>
                                              </Typography>
                                            )}
                                          </TimelineContent>
                                        </TimelineItem>
                                      )
                                    })
                                    .reverse()
                                )
                              : (
                                <Alert severity='info'>No registra movimientos.</Alert>
                                )}
                          </Timeline>

                          <button onClick={handleLoadMore} disabled={showCount >= data.supply_history.length}>Cargar más</button>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex justify-content-end mt-2'>
                    <BtnCancel />
                    {data.supply.map((e: any, i: number) => (
                      <LoadingButton
                        key={e.id}
                        disabled={JSON.stringify(data.initialSupply) === JSON.stringify(data.supplyUpdate) || !isValidFormEditSupply(data.supplyUpdate, data.initialSupply)}
                        variant='contained'
                        size='medium'
                        color='primary'
                        type='submit'
                        className='m-2 btn-secondary'
                        onClick={(event) => handleSumbmit(event, e.id)}
                        loading={data.loading}
                      >
                        Cargar insumo
                      </LoadingButton>
                    ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditSupply

import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import BuildIcon from '@mui/icons-material/Build'
import CachedIcon from '@mui/icons-material/Cached'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ScheduleIcon from '@mui/icons-material/Schedule'
import Button from '@mui/material/Button'

import {
  Alert,
  Badge,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'

import { ModalNames } from '@constants/modal.constants'

import { inputsOT } from '@constants/InputsFormOT.constants'
import { IAppStore } from '@models/store.model'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import { disableInputsRoleImei } from '@utils/Roles.util'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ModalNewSupply from './components/ModalRequestSupply'

interface SuppliesOption {
  id: number,
  name: string,
  variant_color: string
}
const Supplies = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const [data, setData] = useState<{ loading: boolean, idType: number, idSupply: string, inventory: string, searchType: number, SpProduct: SuppliesOption | null, supplyRequest: any[], supplyOrder: any[], dialog: boolean, idxToRemove: number}>(
    { loading: false, idType: 0, idSupply: '', inventory: '', searchType: 1, SpProduct: null, supplyRequest: [], supplyOrder: [], dialog: false, idxToRemove: 0 })
  const [modal, setModal] = useState<{ visible: boolean, name: string, parametros: any }>({ visible: false, name: '', parametros: {} })
  const { enqueueSnackbar } = useSnackbar()
  const userState = useSelector((store: IAppStore) => store.user)
  const resetInputs = () => {
    saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: [{ id: 0, idType: 0, listSupplies: [], stock: 0, idSupply: 0, cantidad: 0, buy: 0, isLocked: false, purchased: 0, initial_stock: 0, idFamily: 0, delivered: 0 }] } })
  }
  modal.parametros.saveData = async (newSupply: { idType: number, idFamily: number, stock: number, idSupply: number, cantidad: number, buy: number }) => {
    setModal({ ...modal, visible: false })
    setData({ ...data, loading: true })
    const listTypes = await webApiService.getAllSuppliesCategory().then(res => res.data)
    const listFamily = await webApiService.getAllSuppliesFamily(0).then(res => res.data)

    const newArray = formCtx.ot.listSuppliesTypesValues
    const arrSelected = formCtx.ot.listSuppliesTypesValues.filter(e => e.idSupply !== 0).map(e => e.idSupply)
    const listSupplies = await webApiService.getSuppliesByCatAndFam(newSupply.idType, newSupply.idFamily).then(res => res.data)

    if (formCtx.ot.listSuppliesTypesValues[formCtx.ot.listSuppliesTypesValues.length - 1].idSupply === 0) {
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].id = 0
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].idType = newSupply.idType
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].listSupplies = listSupplies.filter((e :any) => !arrSelected.includes(e.id))
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].stock = 0
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].idSupply = newSupply.idSupply
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].cantidad = newSupply.cantidad
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].buy = newSupply.cantidad
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].isLocked = true
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].purchased = 2
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].initial_stock = 0
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].idFamily = newSupply.idFamily
      newArray[formCtx.ot.listSuppliesTypesValues.length - 1].delivered = 0
    } else {
      newArray.push({
        id: 0,
        idType: newSupply.idType,
        listSupplies: listSupplies.filter((e :any) => !arrSelected.includes(e.id)),
        stock: 0,
        idSupply: newSupply.idSupply,
        cantidad: newSupply.cantidad,
        buy: newSupply.cantidad,
        isLocked: true,
        purchased: 2,
        initial_stock: 0,
        idFamily: newSupply.idFamily, // nuevo
        delivered: 0
      })
    }
    saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: newArray, listSuppliesTypes: listTypes, listFamily } })
  }

  const handleOnChangeCat = async (_e: SelectChangeEvent<number>, _idx :number) => {
    setData({ ...data, loading: true })
    const newArray = formCtx.ot.listSuppliesTypesValues
    newArray[_idx].idType = Number(_e.target.value)
    newArray[_idx].idFamily = 0
    newArray[_idx].idSupply = 0
    saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: newArray } })
  }

  const handleOnChangeFam = async (_e: SelectChangeEvent<number>, _idx :number) => {
    setData({ ...data, loading: true })
    await webApiService.getSuppliesByCatAndFam(Number(formCtx.ot.listSuppliesTypesValues[_idx].idType), Number(_e.target.value)).then((response) => {
      if (response.success) {
        const arrSelected = formCtx.ot.listSuppliesTypesValues.filter(e => e.idSupply !== 0).map(e => e.idSupply)
        const newArray = formCtx.ot.listSuppliesTypesValues
        newArray[_idx].listSupplies = response.data.filter((e :any) => !arrSelected.includes(e.id))
        newArray[_idx].idType = Number(formCtx.ot.listSuppliesTypesValues[_idx].idType)
        newArray[_idx].idFamily = Number(_e.target.value)
        newArray[_idx].idSupply = 0
        saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: newArray } })
        // setData({ ...data, listSuppliesTypesValues: newArray })
      }
    }).catch(error => {
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
      setData({ ...data, loading: false })
    })
  }

  return (
    <>
      <div className='col-12'>
        <Typography variant='h6' component='div' className='color-title'>
          <BuildIcon className='color-azul' /> INSUMOS
        </Typography>
      </div>
      {
        formCtx.ot.listSuppliesTypesValues.map((e: any, idx: number) => {
          return (
            <React.Fragment key={idx}>
              <div className='col-12 col-lg-3'>
                <FormControl fullWidth>
                  <InputLabel id={'lbl-tipo-insumo-' + idx}>
                    Categoría insumo
                  </InputLabel>
                  <Select
                    disabled={
                      // disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ||
                      disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ||
                      // formCtx.listStatus.some(e => e.id_status === 25) ||
                      formCtx.ot.listSuppliesTypesValues[idx].id > 0
                      // formCtx.ot.listSuppliesTypesValues[idx].isLocked
                    }
                    id={'sl-id-' + idx}
                    value={formCtx.ot.listSuppliesTypesValues[idx].idType}
                    onChange={(e => {
                      handleOnChangeCat(e, idx)
                    })}
                    error={formCtx.ot.failures.length === 0 && formCtx.errorValidation}
                    labelId={'lbl-tipo-insumo-' + idx}
                    label='Categoría insumo'
                  >
                    {
                      formCtx.ot.listSuppliesTypes.map((e: any, i: number, inv: any) => {
                        return (
                          <MenuItem value={e.id} key={i}>{e.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='col-12 col-lg-3'>
                <FormControl fullWidth>
                  <InputLabel id={'lbl-family-' + idx}>
                    Familia
                  </InputLabel>
                  <Select
                    disabled={
                      formCtx.ot.listSuppliesTypesValues[idx].idType === 0 ||
                      // disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ||
                      disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ||
                      // formCtx.listStatus.some(e => e.id_status === 25) ||
                      formCtx.ot.listSuppliesTypesValues[idx].id > 0
                      // formCtx.ot.listSuppliesTypesValues[idx].isLocked
                    }
                    id={'sl-id-family-' + idx}
                    value={formCtx.ot.listSuppliesTypesValues[idx].idFamily}
                    onChange={(e => {
                      handleOnChangeFam(e, idx)
                    // setData({ ...data, listSuppliesTypesValues: newArray })
                    })}
                    error={formCtx.ot.failures.length === 0 && formCtx.errorValidation}
                    labelId={'lbl-family-' + idx}
                    label='Familia'
                  >
                    {
                      formCtx.ot.listFamily.map((e: any, i: number, inv: any) => {
                        return (
                          <MenuItem value={e.id} key={i}>{e.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='col-12 col-lg-3'>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>
                    Insumo
                  </InputLabel>
                  <Select
                    disabled={
                      // disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ||
                      disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ||
                      // formCtx.listStatus.some(e => e.id_status === 25) ||
                      formCtx.ot.listSuppliesTypesValues[idx].id > 0 ||
                      formCtx.ot.listSuppliesTypesValues[idx].idFamily === 0
                      // formCtx.ot.listSuppliesTypesValues[idx].isLocked
                    }
                    labelId='label-sl-id-failure'
                    id='sl-id-failure'
                    value={formCtx.ot.listSuppliesTypesValues[idx].idSupply}
                    label='Insumo'
                    onChange={(e) => {
                      const newArray = formCtx.ot.listSuppliesTypesValues
                      const selectedSupplyId = Number(e.target.value)
                      const selectedSupply = newArray[idx].listSupplies.find((supply) => supply.id === selectedSupplyId)

                      if (selectedSupply) {
                        newArray[idx].idSupply = selectedSupplyId
                        newArray[idx].cantidad = 0
                        newArray[idx].buy = 0
                        newArray[idx].stock = selectedSupply.inventory
                        newArray[idx].initial_stock = selectedSupply.inventory
                        saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: newArray } })
                      }
                    }}
                    error={formCtx.ot.failures.length === 0 && formCtx.errorValidation}
                  >
                    {
                      formCtx.ot.listSuppliesTypesValues[idx].listSupplies.map((e: any, i: number, inv: any) => {
                        return (
                          <MenuItem value={e.id} key={i}>{e.name + ' ' + e.variant_color} - (Stock: {e.inventory === null ? 0 : e.inventory})</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='col-12 col-lg-1'>
                <Badge badgeContent={formCtx.ot.listSuppliesTypesValues[idx].delivered} color={formCtx.ot.listSuppliesTypesValues[idx].delivered < formCtx.ot.listSuppliesTypesValues[idx].cantidad ? 'warning' : 'success'}>
                  <TextField
                    disabled={(
                    formCtx.ot.listSuppliesTypesValues[idx].idSupply === 0 ||
                    formCtx.ot.listSuppliesTypesValues[idx].idType === 0 ||
                    disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ||
                    formCtx.ot.listSuppliesTypesValues[idx].id > 0 ||
                    data.supplyRequest.some((el :any) => Number(el.id_supplies) === Number(formCtx.ot.listSuppliesTypesValues[idx].idSupply)) ||
                    formCtx.ot.listSuppliesTypesValues[idx].isLocked === true)}
                    id='outlined-basic' type='number' label='Cantidad' variant='outlined' value={formCtx.ot.listSuppliesTypesValues[idx].cantidad}
                    onChange={(e) => {
                      const newArray = formCtx.ot.listSuppliesTypesValues
                      newArray[idx].cantidad = parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value)
                      const inventory = formCtx.ot.listSuppliesTypesValues[idx].listSupplies.find((el :any) => el.id === Number(formCtx.ot.listSuppliesTypesValues[idx].idSupply)).inventory
                      newArray[idx].buy =
                    newArray[idx].cantidad <= inventory
                      ? 0
                      : (inventory === 0)
                          ? newArray[idx].cantidad
                          : newArray[idx].cantidad - inventory
                      newArray[idx].stock = inventory
                      saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: newArray, diagnostic_resolution: newArray[idx].cantidad > newArray[idx].stock ? '' : formCtx.ot.diagnostic_resolution } })
                    }}
                  />
                </Badge>
              </div>
              <div className='col-12 col-lg-1'>

                <TextField
                  disabled={
                    formCtx.ot.listSuppliesTypesValues[idx].idSupply === 0 ||
                    formCtx.ot.listSuppliesTypesValues[idx].idType === 0 ||
                    disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyQuantityRequest) ||
                    // formCtx.listStatus.some(e => e.id_status === 25) ||
                    formCtx.ot.listSuppliesTypesValues[idx].id > 0 ||
                    data.supplyRequest.some((el :any) => Number(el.idSupply) === Number(formCtx.ot.listSuppliesTypesValues[idx].idSupply))
                  }
                  InputProps={{
                    readOnly: true,
                    className: formCtx.ot.listSuppliesTypesValues[idx].buy > 0 ? 'buyMark' : ''
                  }}
                  id={formCtx.ot.listSuppliesTypesValues[idx].buy > 0 ? 'buyMark' : 'outlined-basic'} type='number' label='Comprar' variant='outlined' value={formCtx.ot.listSuppliesTypesValues[idx].buy}
                />

              </div>
              <div className='col-12 col-lg-1 d-flex align-items-center'>
                {/* <div className={(disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ? 'col-12 col-lg-1 d-flex align-items-center d-none' : formCtx.listStatus.some(e => e.id_status === 25) ? 'col-12 col-lg-1 d-flex align-items-center d-none' : 'col-12 col-lg-1 d-flex align-items-center')}></div>  */}
                {
                  (formCtx.ot.listSuppliesTypesValues[idx].id > 0)
                    ? (
                      <>
                        {/* Este icono se muestra si el insumo esta en purchased 1 o si no hay nada que comprar */}
                        <CheckCircleIcon
                          className={(formCtx.ot.listSuppliesTypesValues[idx].purchased === 1) ? 'text-success' : (formCtx.ot.listSuppliesTypesValues[idx].purchased === 2) ? 'text-primary' : 'd-none'}
                          sx={{ fontSize: 25 }}
                          titleAccess={(formCtx.ot.listSuppliesTypesValues[idx].purchased === 1) ? 'Solicitud lista' : 'Stock asignado.'}
                        />
                        <CheckCircleIcon
                          className={formCtx.ot.listSuppliesTypesValues[idx].cantidad === formCtx.ot.listSuppliesTypesValues[idx].delivered ? 'text-success' : 'd-none'}
                          sx={{ fontSize: 25 }}
                          titleAccess='Insumos entregados'
                        />
                        {/* Este icono se muestra si el insumo esta en purchased 0 */}
                        <ScheduleIcon
                          className={(formCtx.ot.listSuppliesTypesValues[idx].purchased === 0) ? 'text-primary' : 'd-none'}
                          sx={{ fontSize: 25 }}
                          titleAccess='Solicitud pendiente'
                        />
                      </>
                      )
                    : null
                }
                {
                (formCtx.ot.listSuppliesTypesValues.length > 1)
                  ? (
                    <>
                      <RemoveCircleIcon
                        className={(disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ? 'text-danger cursor-pointer d-none' : 'text-danger cursor-pointer')}
                        color='action'
                        sx={{ fontSize: 25 }}
                        titleAccess='Quitar insumo'
                        onClick={() => {
                          formCtx.ot.listSuppliesTypesValues.splice(idx, 1)
                          saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: formCtx.ot.listSuppliesTypesValues } })
                        }}
                      />
                    </>
                    )
                  : null
                }
                <div hidden={disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) || formCtx.ot.listSuppliesTypesValues.length > idx + 1}>
                  <AddCircleIcon
                    className={formCtx.ot.listSuppliesTypesValues[idx].cantidad === 0 ? 'text-secondary' : 'text-primary cursor-pointer'}
                    sx={{ fontSize: 25 }}
                    titleAccess='Agregar insumo'
                    onClick={() => {
                      if (formCtx.ot.listSuppliesTypesValues[idx].cantidad === 0) {
                        return false
                      }
                      const newArray = formCtx.ot.listSuppliesTypesValues.concat({ id: 0, idType: 0, listSupplies: [], stock: 0, idSupply: 0, cantidad: 0, buy: 0, isLocked: false, purchased: 0, initial_stock: 0, idFamily: 0, delivered: 0 })
                      console.log('newArray', newArray)
                      saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, listSuppliesTypesValues: newArray } })
                      // setData({ ...data, listSuppliesTypesValues: newArray })
                    }}
                  />
                </div>
                <div hidden={formCtx.ot.listSuppliesTypesValues.length !== 1}>
                  <CachedIcon
                    className={(disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyActions) ? 'text-primary cursor-pointer d-none' : 'text-primary cursor-pointer')}
                        // className='text-primary cursor-pointer'
                    sx={{ fontSize: 25 }}
                    titleAccess='Limpiar'
                    onClick={resetInputs}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        })
      }
      <div className='col-12'>
        <ModalNewSupply modal={modal} setModal={setModal} />
        <Button
          hidden
          disabled={disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyRequest)}
          // disabled={disableInputsRoleImei(userState.id_role, formCtx.otInicial.status.id, inputsOT.supplyRequest) || formCtx.listStatus.some(e => e.id_status === 25)}
          variant='contained'
          sx={{ color: 'color-azul' }}
          size='small'
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => setModal({ ...modal, visible: true, name: ModalNames.NEWSUPPLY })}
        >Solicitar Nuevo insumo
        </Button>
      </div>
      <div className='col-12' hidden={formCtx.ot.status.id !== 25}>
        {
          formCtx.ot.listSuppliesTypesValues.some(e => e.buy !== 0)
            ? <Alert severity='warning'>Aún tiene solicitudes de insumos pendientes.</Alert>
            : formCtx.ot.listSuppliesTypesValues.some(e => e.cantidad === 0)
              ? null
              : <Alert severity='success'>Solicitudes listas.</Alert>
        }

      </div>
    </>
  )
}

export default Supplies

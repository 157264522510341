import { createContext, useContext, useEffect, useState } from 'react'
import { webApiService } from '../../../services'
import useDebounce from '@utils/useDebounce.util'
import { IParamSuppliesService } from '@models/paramSupply.model'

interface IOwnProps {
  data: any
  loading: boolean,
  params: IParamSuppliesService
}

export type SuppliesContextType = {
  suppliesCtx: IOwnProps
  saveSuppliesCtx: (suppliesCtx: IOwnProps) => void
}

export const SuppliesContext = createContext<SuppliesContextType | null>(null)

export const SuppliesProvider = ({ children } : any) => {
  const [suppliesCtx, setSuppliesCtx] = useState<IOwnProps>({ data: null, loading: false, params: { limit: 10, page: 1 } })

  const debouncedValue = useDebounce(suppliesCtx.params, 300)
  const getAllSupplies = () => {
    setSuppliesCtx({ ...suppliesCtx, loading: true })
    webApiService.getAllSupplies(suppliesCtx.params).then((response) => {
      if (response.success) {
        setSuppliesCtx({ ...suppliesCtx, data: response.data, loading: false })
      }
    }).catch((error) => {
      console.log(error)
      setSuppliesCtx({ ...suppliesCtx, loading: false })
    })
  }

  const saveSuppliesCtx = (_suppliesCtx: IOwnProps) => {
    setSuppliesCtx(_suppliesCtx)
  }

  useEffect(() => {
    getAllSupplies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return <SuppliesContext.Provider value={{ suppliesCtx, saveSuppliesCtx }}>{children}</SuppliesContext.Provider>
}

export const useSuppliesContext = () => {
  const context = useContext(SuppliesContext)
  if (context === undefined) {
    throw new Error('SuppliesContext must be used within a DashboardProvider')
  }
  return context
}

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, TextField, Tooltip, Typography } from '@mui/material'
import { SupplyContextType, useSupplyContext } from '../context/Supply.context'
import InfoIcon from '@mui/icons-material/Info'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const Others = () => {
  const { supplyCtx, saveSupplyCtx } = useSupplyContext() as SupplyContextType

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }))

  return (
    <div className='row gy-3'>
      <div className='col-12 d-flex align-items-center'>
        <Typography variant='h6' className='py-2 color-title'>
          <AccountCircleIcon className='color-azul' /> INFORMACION ADICIONAL
        </Typography>
      </div>
      <div className='col-12 col-lg-6'>
        <TextField
          type='text'
          name='modelos'
          label='Modelos compatibles'
          fullWidth
          value={supplyCtx.supply.compatible_models}
          onChange={(e) => saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, compatible_models: e.target.value } })}
        />
      </div>
      <div className='col-12 col-lg-6'>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type='text'
            name='origin_code'
            label='Código de origen'
            fullWidth
            value={supplyCtx.supply.origin_code}
            onChange={(e) => saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, origin_code: e.target.value } })}
          />
          <BootstrapTooltip title='Modelo o número de pieza del fabricante del producto'>
            <InfoIcon className='text-secondary mx-2' />
          </BootstrapTooltip>
        </Box>
      </div>
      <div className='col-12' hidden={supplyCtx.supply.id === 0}>
        <TextField
          type='text'
          name='reference_links'
          label='Links referenciales'
          fullWidth
          value={supplyCtx.supply.reference_links}
          onChange={(e) => saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, reference_links: e.target.value } })}
        />
      </div>
    </div>
  )
}

export default Others

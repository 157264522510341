import { InventoryIcon } from '@assets/icons/Icons'
import { ModalNames } from '@constants/modal.constants'
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  TextField,
  Button
} from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import ModalHistoryPhoneC from '../ModalHistoryPhoneCheck/ModalHistoryPhoneCheck'

const Product = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const [data, setData] = React.useState<{ loading: boolean, listSbu: any[]}>({ loading: false, listSbu: [] })
  const { enqueueSnackbar } = useSnackbar()
  // State Modales
  const [modal, setModal] = React.useState<{ visible: boolean, name: string, parametros: any }>({ visible: false, name: '', parametros: {} })

  const handleModal = (serialProduct: any, modalname: string) => {
    setModal({ visible: true, name: modalname, parametros: { serial: serialProduct } })
  }

  const handleChangeProduct = (value: string) => {
    const posicionUltimoGuion = value.lastIndexOf('-')

    const prod = (value.indexOf('SINSERIAL') >= 0) ? formCtx.objBsale.productos[Number(value.substring(posicionUltimoGuion + 1))] : formCtx.objBsale.productos.find(e => e.serial === value)
    saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, product: { ...formCtx.ot.product, serial: value, sku: (prod !== undefined) ? prod.sku : '', sbu: { ...formCtx.ot.product.sbu, id: (prod !== undefined) ? prod.sbu.id.toString() : '0' } } } })
  }

  const loadSbu = () => {
    webApiService.getAllSbu().then((response) => {
      setData({ ...data, listSbu: response.items })
    }).catch((error) => {
      console.log(error)
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    })
  }

  useEffect(() => {
    loadSbu()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <ModalHistoryPhoneC modal={modal} setModal={setModal} />
      <div className='row gy-3 '>
        <div className='col-12 col-lg-8 my-4'>
          <Typography variant='h6' component='div' className='color-title'>
            <InventoryIcon className='color-azul' /> PRODUCTO
          </Typography>
        </div>
        <div className='col-12 col-lg-4 text-end my-4'>
          <Button
            hidden={(parseInt(formCtx.ot.product.sbu.id) !== 1 || formCtx.ot.product.serial === '')}
            variant='contained'
            color='primary'
            size='small'
            onClick={() => handleModal(formCtx.ot.product.serial, ModalNames.HISTORYPHONECHECK)}
          >
            Historial phoneCheck
          </Button>
        </div>
      </div>
      <div className='row gy-3'>
        <div className='col-12 col-lg-4'>
          {}
          <FormControl fullWidth>
            <InputLabel id='label-sl-serial'>Producto</InputLabel>
            <Select
              disabled={formCtx.ot.id > 0}
              labelId='label-sl-serial'
              id='sl-serial'
              value={formCtx.ot.product.serial}
              label='Producto'
              onChange={(e) => handleChangeProduct(e.target.value.toString())}
              error={formCtx.ot.product.serial === '' && formCtx.errorValidation}
            >
              <MenuItem value=''>Seleccione producto</MenuItem>
              {
                formCtx.objBsale.productos !== undefined && formCtx.objBsale.productos.map((e, i) => {
                  const serial = (e.serial === '') ? 'SINSERIAL-' + String(formCtx.ot.invoice.id_invoice) + '-' + i : e.serial
                  return (<MenuItem value={serial} key={i}>{'(' + e.sku + ') ' + e.name}</MenuItem>)
                })
              }

            </Select>
          </FormControl>
        </div>
        <div className='col-12 col-lg-4'>
          <TextField
            disabled
            type='text'
            name='serial'
            label='Serial'
            fullWidth
            value={formCtx.ot.product.serial}
            inputProps={{ readOnly: true }}
          />

        </div>
        <div className='col-12 col-lg-4'>
          <FormControl fullWidth>
            <InputLabel id='label-sl-id-sbu'>
              Unidad de Negocio
            </InputLabel>
            <Select
              disabled={formCtx.ot.id > 0}
              labelId='label-sl-id-sbu'
              id='sl-id-sbu'
              value={formCtx.ot.product.sbu.id}
              label='Unidad Negocio'
              onChange={(e) => saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, product: { ...formCtx.ot.product, sbu: { ...formCtx.ot.product.sbu, id: e.target.value } } } })}
              error={formCtx.ot.product.sbu.id === '' && formCtx.errorValidation}
            >
              <MenuItem value=''>Seleccione unidad de negocio</MenuItem>
              {
              data.listSbu.map(e => {
                return (<MenuItem value={e.id} key={e.id}>{e.description}</MenuItem>)
              })
            }
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  )
}

export default Product

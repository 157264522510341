import { inputsOT } from '@constants/InputsFormOT.constants'
import { UserItem } from '@models/user.model'

export const editTableRole = (_user: UserItem, _row: any): boolean => {
  const idRole: number = _user.id_role
  // console.log('🚀 ~ file: Roles.util.ts:6 ~ editTableRole ~ _user:', _user)
  const idEstado: number = _row.id_status
  // Estado finalizado
  if (idEstado === 34) { return true }
  // Encargado tienda
  if (idRole === 10 && idEstado !== 37) { return true }
  // Encargado bodega
  if (idRole === 6 && idEstado === 25 && (process.env.REACT_APP_MARKETPLACE_NOTIFICA === undefined ? [] : process.env.REACT_APP_MARKETPLACE_NOTIFICA.split(',').map(e => Number(e))).includes(_row.id_channel === undefined ? 0 : _row.id_channel)) { return false }
  if (idRole === 6 && [1, 2, 28, 17, 18, 31, 32, 33].indexOf(idEstado) < 0) { return true }
  // Gestion (SAC)
  if ((idRole === 4 && [17, 26, 27, 25, 31, 32, 33].indexOf(idEstado) < 0) || (idRole === 4 && _row.id_assigned_user !== _user.id)) { return true }
  // Técnico y supervisor tecnico
  if ((idRole === 3 && [3, 30, 16, 29, 9, 14, 15, 25].indexOf(idEstado) < 0) || (idRole === 3 && _row.id_assigned_technician !== _user.id)) { return true }

  // Supervisor tecnico
  if (idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR) && [26, 27].indexOf(idEstado) > 0) { return false } // fix para que el supervisor pueda agregar codigo de desbloqueo al solicitar informacion
  if ((idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR) && [3, 30, 16, 29, 9, 14, 15, 25].indexOf(idEstado) < 0) || (idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR) && _row.id_assigned_technician !== _user.id)) { return true }

  return false
}

export const disableInputsRole = (idRole: number, idEstado: number, inputName?: string, idCanal?: number) : boolean => {
  // console.log('disableInputsRole', idRole, idEstado, inputName)
  // Responsables

  if ([11].includes(idRole) && [2, 28, 3, 29, 9, 25, 14, 16, 17, 30, 31, 32, 33, 15, 18].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slStatusInformation) { return true }
  if ([11].includes(idRole) && [26, 27, 28, 3, 29, 9, 25, 14, 16, 17, 30, 31, 32, 33, 15, 18].includes(idEstado) && inputName !== undefined && (inputName === inputsOT.codeUnlock || inputName === inputsOT.disassociation)) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slFailure) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slCosmetic) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.faultDesc) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.cosmeticStatus) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 14, 37, 18, 31, 33].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slDiagnostic) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 14, 37, 18, 31, 33].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slPpl) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 14, 37, 18, 31, 33].includes(idEstado) && inputName !== undefined && inputName === inputsOT.observacionPpl) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 14, 37, 18, 31, 33].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slScd) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 14, 37, 18, 31, 33].includes(idEstado) && inputName !== undefined && inputName === inputsOT.observacionScd) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 9, 14, 16, 17, 30, 31, 32, 33, 15, 18, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.chkNotification) { return true }

  if ([11, 6].includes(idRole) && idEstado === 25 && (inputName === inputsOT.radioReparado || inputName === inputsOT.radioSinReparar) && (process.env.REACT_APP_MARKETPLACE_NOTIFICA === undefined ? [] : process.env.REACT_APP_MARKETPLACE_NOTIFICA.split(',').map(e => Number(e))).includes(idCanal === undefined ? 0 : idCanal)) { return false }

  if ([11].includes(idRole) && [25, 14, 29, 17, 31, 32, 33, 18].includes(idEstado) && inputName !== undefined && (inputName === inputsOT.radioReparado || inputName === inputsOT.radioSinReparar)) { console.log('testing', idRole, idEstado, inputName); return true }

  // Encargado bodega
  if (idRole === 6 && idEstado === 2 && inputName !== undefined && (inputName === inputsOT.codeUnlock || inputName === inputsOT.disassociation)) { return false }
  if (idRole === 6 && idEstado !== 0) { return true }

  // Encargado gestion (SAC)
  if (idRole === 4 && (idEstado === 26 || idEstado === 27) && inputName !== undefined && inputName === inputsOT.slStatusInformation) { return false }
  if (idRole === 4 && idEstado === 25 && inputName !== undefined && inputName === inputsOT.slStatusInformation) { return true }
  if (idRole === 4 && (idEstado === 26 || idEstado === 27)) { return true }
  if (idRole === 4 && idEstado === 25 && inputName !== undefined && inputName === inputsOT.chkNotification) { return false }
  if (idRole === 4 && [25, 31].includes(idEstado)) { return true }

  // Técnico y supervisor tecnico
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.slDiagnostic) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.slPpl) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.observacionPpl) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.slScd) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.observacionScd) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 16 || idEstado === 25 || idEstado === 9 || idEstado === 15) && inputName !== undefined && (inputName === inputsOT.radioReparado || inputName === inputsOT.radioSinReparar)) { return false }
  if (idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR) && (idEstado === 26 || idEstado === 27) && inputName !== undefined && inputName === inputsOT.slStatusInformation) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && idEstado !== 0) { return true }

  return false
}

export const disableInputsRoleImei = (idRole: number, idEstado: number, inputName?: string) : boolean => {
  // console.log('disableInputsRoleImei', idRole, idEstado, inputName)
  // Responsables

  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slFailure) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slCosmetic) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18].includes(idEstado) && inputName !== undefined && inputName === inputsOT.faultDesc) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 29, 25, 14, 29, 9, 17, 30, 31, 32, 33, 15, 18].includes(idEstado) && inputName !== undefined && inputName === inputsOT.cosmeticStatus) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slDiagnostic) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slPpl) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31].includes(idEstado) && inputName !== undefined && inputName === inputsOT.observacionPpl) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31].includes(idEstado) && inputName !== undefined && inputName === inputsOT.slScd) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31].includes(idEstado) && inputName !== undefined && inputName === inputsOT.observacionScd) { return true }
  if ([11].includes(idRole) && [14, 29, 17, 30, 31, 32, 33, 18].includes(idEstado) && inputName !== undefined && (inputName === inputsOT.radioReparado || inputName === inputsOT.radioSinReparar)) { console.log('testing', idRole, idEstado, inputName); return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.supplyType) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.supplyName) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.supplyQuantity) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.supplyQuantityRequest) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.supplyRequest) { return true }
  if ([11].includes(idRole) && [1, 2, 26, 27, 28, 3, 17, 29, 14, 31, 37].includes(idEstado) && inputName !== undefined && inputName === inputsOT.supplyActions) { return true }

  // Encargado bodega
  if (idRole === 6 && idEstado !== 0) { return true }

  // Encargado gestion (SAC)
  if (idRole === 4 && (idEstado === 26 || idEstado === 27) && inputName !== undefined && inputName === inputsOT.slStatusInformation) { return false }
  if (idRole === 4 && idEstado === 25 && inputName !== undefined && inputName === inputsOT.slStatusInformation) { return true }
  if (idRole === 4 && (idEstado === 26 || idEstado === 27)) { return true }
  if (idRole === 4 && [25, 31].includes(idEstado)) { return true }

  // Técnico y supervisor tecnico
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.slDiagnostic) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.slPpl) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.observacionPpl) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.slScd) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.observacionScd) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 16 || idEstado === 25 || idEstado === 9 || idEstado === 15) && inputName !== undefined && (inputName === inputsOT.radioReparado || inputName === inputsOT.radioSinReparar)) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.supplyType) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.supplyName) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.supplyQuantity) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.supplyQuantityRequest) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.supplyRequest) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && (idEstado === 30 || idEstado === 25 || idEstado === 9) && inputName !== undefined && inputName === inputsOT.supplyActions) { return false }
  if ((idRole === 3 || idRole === Number(process.env.REACT_APP_ROLE_SUPERVISOR)) && idEstado !== 0) { return true }

  return false
}

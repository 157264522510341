import { AttachFileIcon } from '@assets/icons/Icons'
import { ModalNames } from '@constants/modal.constants'
import { Badge, Fab } from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { useState } from 'react'
import ModalFiles from './components/ModalFiles'

const BtnUpload = () => {
  const { formCtx } = useOTFormContext() as FormContextType
  const [modal, setModal] = useState<{ visible: boolean, name: string, parametros: any }>({ visible: false, name: '', parametros: {} })

  return (
    <div>
      <ModalFiles modal={modal} setModal={setModal} />
      {
      (formCtx.ot.files.length)
        ? (
          <Badge color='warning' overlap='circular' badgeContent={formCtx.ot.files.length} className='btn-upload'>
            <Fab color='primary' aria-label='add' onClick={() => setModal({ ...modal, visible: true, name: ModalNames.FILES })}>
              <AttachFileIcon />
            </Fab>
          </Badge>
          )
        : <Fab color='primary' aria-label='add' className='btn-upload' onClick={() => setModal({ ...modal, visible: true, name: ModalNames.FILES })}> <AttachFileIcon /></Fab>
    }

    </div>
  )
}

export default BtnUpload

export const inputsOT = {
  codeUnlock: 'codeUnlock',
  disassociation: 'disassociation',
  slStatusInformation: 'slStatusInformation',
  slDiagnostic: 'slDiagnostic',
  slPpl: 'slPpl',
  observacionPpl: 'observacionPpl',
  slScd: 'slScd',
  observacionScd: 'observacionScd',
  chkNotification: 'chkNotification',
  radioReparado: 'radioReparado',
  radioSinReparar: 'radioSinReparar',
  slFailure: 'slFailure',
  slCosmetic: 'slCosmetic',
  faultDesc: 'faultDesc',
  cosmeticStatus: 'cosmeticStatus',
  supplyType: 'supplyType',
  supplyName: 'supplyName',
  supplyQuantity: 'supplyQuantity',
  supplyQuantityRequest: 'supplyQuantityRequest',
  supplyRequest: 'supplyQuantityRequest',
  supplyActions: 'supplyActions'
}

import { PrivateRoutes } from '@constants/routes.constants'
import { IAppStore } from '@models/store.model'
import { CircularProgress, Typography } from '@mui/material'
import { webApiService } from '@services/index'
import { editTableRole } from '@utils/Roles.util'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { OTDiagnosticGuard, RoleChildrenGuard } from 'src/guards'
import OTStatusGuard from 'src/guards/statusOT.guard'
import { BtnCancel } from './components/BtnCancel'
import { BtnSave } from './components/BtnSave'
import { BtnUpload } from './components/BtnUpload'
import { Client } from './components/Client'
import { Diagnostic } from './components/Diagnostic'
import { FaultInformation } from './components/FaultInformation'
import { Flows } from './components/Flows'
import { ManagementData } from './components/ManagementData'
import { OTStatus } from './components/OTStatus'
import { Product } from './components/Product'
import { PurchaseDetail } from './components/PurchaseDetail'
import { RequiredInformation } from './components/RequiredInformation'
import { StatusInformation } from './components/StatusInformation'
import { FormContextType, OTEmpty, objBsaleEmpty, useOTFormContext } from './context/OTForm.context'

const OTFormPage = () => {
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const userState = useSelector((store: IAppStore) => store.user)
  const navigate = useNavigate()

  const loadData = async () => {
    saveFormCtx({ ...formCtx, loading: true })
    const _listStatus = await webApiService.getHistoryStatus(parseInt(id !== undefined ? id : '0')).then(response => response.data)
    const res : any = await Promise.all([
      webApiService.getByRequestStatus(id !== undefined ? Number(id) : 0).then((r) => r.data),
      webApiService.getAllSuppliesCategory().then((r) => r.data),
      webApiService.getAllSuppliesFamily(0).then((r) => r.data)
      // webApiService.getByOrder(formCtxIMEI.ot.id).then((response) => response)
    ]).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    })

    webApiService.getRequest(parseInt(id !== undefined ? id : '0')).then(response => {
      if (response.success) {
        // simular row
        const row = {
          id_status: response.data.status.id,
          id_assigned_technician: response.data.id_assigned_technician,
          id_assigned_user: response.data.id_assigned_user,
          id_channel: response.data.channel.id
        }
        // validacion posibles editores
        if (editTableRole(userState, row)) {
          enqueueSnackbar('No puede acceder a esta OT', { variant: 'warning' })
          navigate(PrivateRoutes.OTLIST, { replace: true })
        }

        if (response.data.listSuppliesTypesValues.length === 0) {
          response.data.listSuppliesTypesValues.push(
            {
              id: 0,
              idType: 0,
              listSupplies: res[1],
              stock: 0,
              idSupply: 0,
              cantidad: 0,
              buy: 0,
              isLocked: false,
              purchased: 2,
              initial_stock: 0,
              idFamily: 0,
              delivered: 0
            }
          )
        } else {
          const newArray :any[] = []
          console.log('res[0]', res[0])
          response.data.listSuppliesTypesValues.forEach((element: any) => {
            console.log('ssresd[0].find((e: any) => e.id_supplies === element.idSupply)', res[0].find((e: any) => e.id_supplies === element.idSupply))
            element.purchased = res[0].find((e: any) => e.id_supplies === element.idSupply) !== undefined ? res[0].find((e: any) => e.id_supplies === element.idSupply).purchased : 2
            newArray.push({ ...element })
          })
          response.data.listSuppliesTypesValues = newArray
        }

        response.data.failures = response.data.failures.map((e: any) => e.id_failure)
        response.data.updated_by = userState.id
        response.data.id_warehouse_NC = (response.data.id_warehouse_NC === 0 ? '' : response.data.id_warehouse_NC)
        response.data.id_warehouse_transfer = (response.data.id_warehouse_transfer === 0 ? '' : response.data.id_warehouse_transfer)
        response.data.listSuppliesTypes = res[1]
        response.data.listFamily = res[2]
        console.log('🚀 ~ webApiService.getRequest ~ response.data:', response.data.product)
        saveFormCtx({ ...formCtx, errorValidation: true, ot: response.data, loading: false, objBsale: { ...formCtx.objBsale, productos: [response.data.product] }, otInicial: JSON.parse(JSON.stringify(response.data)), listStatus: _listStatus })
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
      }
    }).catch((error) => {
      console.log(error)
      if (error.code !== undefined) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
      saveFormCtx({ ...formCtx, loading: false })
    })
  }

  const unlockOT = () => {
    const idOT = (id !== undefined && id !== 'new') ? parseInt(id) : 0
    webApiService.lockRequest(idOT, userState.id, false).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (id !== undefined && id !== 'new') loadData()
    if (id === 'new') {
      saveFormCtx({
        objBsale: objBsaleEmpty,
        ot: { ...OTEmpty, reception_type: userState.id === 0 ? 'CLIENTE' : '' },
        otInicial: OTEmpty,
        loading: false,
        listStatus: [],
        errorValidation: false
      })
    }
    return () => { unlockOT() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (formCtx.loading && id !== undefined && id !== 'new') {
    return (
      <div className='container-lg'>
        <div className='row gy-3 my-4'>
          <div className='col-12 my-2'>
            <Typography variant='h6' className='title mb-3'>
              {(id === 'new' || userState.id === 0) ? 'NUEVA' : 'EDITANDO'} ORDEN DE TRABAJO
            </Typography>
            <div className='shadow-sm rounded bg-white p-3'>
              <div className='container-xxl bg-white'>
                {
                id !== undefined && id !== 'new'
                  ? (
                    <div className='row bg-white sticky-ot py-3'>
                      <div className='col-12 col-lg-8 d-flex align-items-center'>
                        <div className='h5 title d-flex align-items-center mb-0 '>
                          {(id === 'new') ? <span /> : <p className='m-0 fw-bold'>OT <span className='text-primary ms-2 fw-bold'>{'#' + id} </span> <CircularProgress size={30} className='ms-5' /></p>}
                        </div>
                      </div>
                    </div>
                    )
                  : null
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='container-lg'>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            {(id === 'new' || userState.id === 0) ? 'NUEVA' : 'EDITANDO'} ORDEN DE TRABAJO
          </Typography>
          <div className='shadow-sm rounded bg-white p-3'>
            <div className='container-xxl bg-white'>
              {
                id !== undefined && id !== 'new'
                  ? (
                    <div className='row bg-white sticky-ot py-3'>
                      <div className='col-12 col-lg-8 d-flex align-items-center'>
                        <div className='h5 title d-flex align-items-center mb-0 '>
                          {(id === 'new') ? <span /> : <p className='m-0 fw-bold'>OT <span className='text-primary ms-2 fw-bold'>{(formCtx.loading) ? <CircularProgress size={20} /> : '#' + id}</span></p>}
                        </div>
                        <div className='title  mb-0 mx-3'>
                          {(id === 'new') ? <span /> : <p className='m-0 h5 fw-bold d-flex align-items-center'>ESTADO<span className='badge rounded-pill text-bg-primary ms-2 h3 m-0'>{(formCtx.loading) ? <CircularProgress size={20} /> : formCtx.ot.status.name}</span></p>}
                        </div>
                      </div>

                      <div className='col-12 col-lg-4'>
                        <div className='justify-content-end'>
                          <OTStatus />
                        </div>
                      </div>
                    </div>
                    )
                  : null
              }
              <div className='row gy-3 gx-5'>
                {id !== undefined && id !== 'new' ? <div className='col-12'><hr /></div> : null}
                <div className='col-12 col-lg-6'>
                  <PurchaseDetail />
                </div>
                <div className='col-12 col-lg-6'>
                  <Client />
                </div>
                <div className='col-12'><hr /></div>
              </div>

              <div className='row gy-3 pt-3 '>
                <div className='col-12'>
                  <Product />
                </div>
                <div className='col-12'><hr /></div>
                <div className='col-12 col-lg-6'>
                  <Flows />
                </div>
                <div className='col-12 col-lg-3'>
                  <RequiredInformation />
                </div>
                {
                  (id !== undefined && id !== 'new')
                    ? (
                      <div className='col-12 col-lg-3'>
                        <StatusInformation />
                      </div>
                      )
                    : null
                }
                <div className='col-12'><hr /></div>
                <div className='col-12'>
                  <FaultInformation />
                </div>
              </div>
              {
                id !== undefined && id !== 'new'
                  ? (
                    <div className='row gy-3 pt-3 '>
                      <div className='col-12'><hr /></div>
                      <div className='col-12'>
                        <Diagnostic />
                      </div>

                      <RoleChildrenGuard roles={[4, 6, 10, 11]} PublicValidationFragment={null}>
                        <OTStatusGuard listStatus={[16, 31]} idStatus={formCtx.otInicial.status.id} PublicValidationFragment={null}>
                          <OTDiagnosticGuard listDiagnostic={['APLICAR GARANTIA CAMBIO DE EQUIPO', 'FUNCIONAMIENTO OK', 'FUNCIONAMIENTO NO OK, REPARAR']} diagnostic={formCtx.otInicial.diagnostic_information} PublicValidationFragment={null}>
                            <div className='col-12' hidden={formCtx.otInicial.diagnostic_resolution === ''}><hr /></div>
                            <div className='col-12' hidden={formCtx.otInicial.diagnostic_resolution === ''}>

                              {formCtx.ot.id_flow !== 10 ? <ManagementData /> : null}

                            </div>
                          </OTDiagnosticGuard>
                        </OTStatusGuard>
                      </RoleChildrenGuard>
                      <div className='col-12'><hr /></div>

                    </div>
                    )
                  : null
              }
              <div className='row gy-2'>
                <div className='col-12'>
                  <div className='d-flex justify-content-end mt-2'>
                    {/* <BtnCancel />  <BtnSave /> {id === 'new' ? <BtnUpload /> : null} */}
                    {userState.id === 0 ? null : <BtnCancel />}<BtnSave loadData={loadData} />

                    {
                      userState.id === 0
                        ? null
                        : (
                          <OTStatusGuard listStatus={[0, 9, 25, 30, 37]} idStatus={formCtx.otInicial.status.id} PublicValidationFragment={null}>
                            <BtnUpload />
                          </OTStatusGuard>)
                      }

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OTFormPage

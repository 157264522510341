import { FORMAT_DATE_HOURS } from '@constants/formateDate.constants'
import { IESupply, INSupply, IOT, IOTIMEI, ISupply } from '@models/OT.model'
import moment from 'moment'
import { validateEmail } from './validateEmail'

export const isValidForm = (_ot: IOT, _otInicial: IOT) : boolean => {
  let valid = true
  // console.log('Managment', (_otInicial.status.id === 31 && (_ot.diagnostic_information.toUpperCase() === 'APLICAR GARANTIA CAMBIO DE EQUIPO' || _ot.diagnostic_information.toUpperCase() === 'FUNCIONAMIENTO OK') && (_ot.NC_number === 0 || _ot.id_warehouse_NC === '')))
  // Managment data (Nota de credito)
  if (_otInicial.status.id === 31 && (_ot.diagnostic_information.toUpperCase() === 'APLICAR GARANTIA CAMBIO DE EQUIPO' || _ot.diagnostic_information.toUpperCase() === 'FUNCIONAMIENTO OK') && (_ot.NC_number === 0 || _ot.id_warehouse_NC === '')) {
    valid = false
  }

  // StatusInformation
  if (_ot.information_status.toUpperCase() === 'DATOS ENTREGADOS' && _ot.flag_unlock_code === 1 && _ot.unlock_code === '') {
    valid = false
  }

  //  console.log('Diagnostico', (_ot.diagnostic_information !== '' && (_ot.id_product_functionality === '' || _ot.technical_service.id_component_ppl_failure === null || _ot.technical_service.id_component_ppl_failure === '')))
  // Diagnostico
  if (_ot.diagnostic_information !== '' && (_ot.id_product_functionality === '' || _ot.technical_service.id_component_ppl_failure === null || _ot.technical_service.id_component_ppl_failure === '')) {
    valid = false
  }

  // Componente DetailPurchase
  // console.log('DetailPurchase', (_ot.invoice.id_invoice === '' || _ot.channel.id === ''))
  if (_ot.invoice.id_invoice === '' || _ot.channel.id === '') {
    valid = false
    // console.log(JSON.stringify({ id_invoice: _ot.invoice.id_invoice, channelid: _ot.channel.id }))
    // console.log('entro DetailPurchase', _ot.invoice.id_invoice, _ot.channel.id)
  }

  // console.log('Client', (_ot.client.identification_number === '' || _ot.client.email === '' || _ot.client.phone === ''))
  // Componente Client
  if (_ot.client.identification_number === '' || _ot.client.email === '' || _ot.client.phone === '' || _ot.client.name === '') {
    valid = false
  }
  // validar correo
  // console.log('validateEmail(_ot.client.email)', validateEmail(_ot.client.email))
  if (!validateEmail(_ot.client.email)) {
    valid = false
  }

  //  console.log('Product', (_ot.product.serial === '' || _ot.product.sbu.id === ''))
  // Componente Product
  if (_ot.product.serial === '' || _ot.product.sbu.id === '') {
    valid = false
  }

  // Componente FaultInformation
  if (_ot.failures.length === 0 || _ot.id_cosmetic_status === '') {
    valid = false
  }
  const fechaActual = new Date()
  // console.log('fecha nc', ((_ot.NC_date < _ot.created_at || _ot.NC_date > moment(fechaActual).format(FORMAT_DATE_HOURS)) && _ot.NC_number >= 0))
  // validate fecha nota de credito
  // console.log('1', _ot.NC_date, _ot.created_at)
  // console.log('2', _ot.NC_date > moment(fechaActual).format(FORMAT_DATE_HOURS))
  // console.log('3', _ot.NC_number >= 0)
  if ((new Date(moment(_ot.NC_date).format('YYYYMMDD')) < new Date(moment(_ot.created_at).format('YYYYMMDD')) || _ot.NC_date > moment(fechaActual).format(FORMAT_DATE_HOURS)) && _ot.NC_number >= 0) {
    valid = false
  }

  return valid
}

// FUNCION PARA VALIDAR FORMULARIO POR IMEI
export const isValidFormIMEI = (_ot: IOTIMEI, _otInicial: IOTIMEI) : boolean => {
  let valid = true

  // console.log('Managment', (_otInicial.status.id === 31 && (_ot.diagnostic_information.toUpperCase() === 'APLICAR GARANTIA CAMBIO DE EQUIPO' || _ot.diagnostic_information.toUpperCase() === 'FUNCIONAMIENTO OK') && (_ot.NC_number === 0 || _ot.id_warehouse_NC === '')))
  // Managment data (Nota de credito)
  if (_otInicial.status.id === 31 && (_ot.diagnostic_information.toUpperCase() === 'APLICAR GARANTIA CAMBIO DE EQUIPO' || _ot.diagnostic_information.toUpperCase() === 'FUNCIONAMIENTO OK') && (_ot.NC_number === 0 || _ot.id_warehouse_NC === '')) {
    valid = false
    console.log('1')
  }

  //  console.log('Diagnostico', (_ot.diagnostic_information !== '' && (_ot.id_product_functionality === '' || _ot.technical_service.id_component_ppl_failure === null || _ot.technical_service.id_component_ppl_failure === '')))
  // Diagnostico
  if (_ot.diagnostic_information !== '' && (_ot.id_product_functionality === '' || _ot.technical_service.id_component_ppl_failure === null || _ot.technical_service.id_component_ppl_failure === '')) {
    valid = false
    console.log('2')
  }

  // Insumos
  if (
    (_ot.diagnostic_information === '' || _ot.listSuppliesTypesValues.slice(1).some(e => e.cantidad === 0)) && _otInicial.status.id !== 0 && _otInicial.status.id !== 2 && _otInicial.status.id !== 3 && _otInicial.status.id !== 29 && _ot.status.id !== 29
  ) {
    valid = false
    console.log('3')
  }

  //  console.log('Product', (_ot.product.serial === '' || _ot.product.sbu.id === ''))
  // Componente Product
  if (_ot.product.serial === '' || _ot.product.sbu.id === '' || (_ot.product.sku === '' || _ot.product.sku === undefined)) {
    valid = false
    console.log('4')
  }

  // Componente FaultInformation
  if ((_ot.failures.length === 0 || _ot.id_cosmetic_status === '') && _otInicial.status.id !== 29) {
    valid = false
    console.log('5')
  }
  // console.log('valid isValidFormIMEI ', valid)
  return valid
}

export const isValidFormNewSupply = (_newSupply: ISupply, _initialSupply: ISupply) : boolean => {
  let valid = true

  // Tipo insumo
  if (_newSupply.type.name === '') {
    valid = false
  }

  if (
    _newSupply.type.name === '' ||
    _newSupply.supply.name === '' ||
    _newSupply.supply.brand === '' ||
    _newSupply.supply.model === '' ||
    _newSupply.supply.provider === '' ||
    _newSupply.supply.last_invoice === '' ||
    _newSupply.supply.quantity === 0 ||
    _newSupply.supply.cost === 0 ||
    _newSupply.supply.selectedInvoice === null
  ) {
    valid = false
  }
  return valid
}

export const isValidFormEditSupply = (_editSupply: IESupply, _initialSupply: IESupply) : boolean => {
  let valid = true

  if (
    _editSupply.provider === '' ||
    _editSupply.inventory === 0 ||
    _editSupply.cost === 0 ||
    _editSupply.last_invoice === '' ||
    _editSupply.selectedFile === null ||
    _editSupply.selectedFile === null) {
    valid = false
  }

  return valid
}

export const isValidFormAddNewSupply = (_newRequestSupply: INSupply, _initialSupply: INSupply) : boolean => {
  let valid = true
  // Verifica si algún campo requerido está vacío
  if (
    ((_newRequestSupply.type.id === '' || _newRequestSupply.type.id === '0') && _newRequestSupply.type.name === '') || ((_newRequestSupply.family.id === '' || _newRequestSupply.family.id === '0') && _newRequestSupply.family.name === '') || _newRequestSupply.name === '' || _newRequestSupply.quantity === 0
  ) {
    valid = false
  }

  return valid
}

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RestoreIcon from '@mui/icons-material/Restore'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { SupplyContextType, useSupplyContext } from '../context/Supply.context'
import IconHistory from './iconHistory'

const LogSupply: React.FC = () => {
  const { supplyCtx } = useSupplyContext() as SupplyContextType
  const [showCount, setShowCount] = useState<number>(5)
  const handleLoadMore = () => {
    setShowCount(prev => prev + 5)
  }
  return (
    <div>
      <div className='col-12'>
        <Typography variant='h6' component='div' className='color-title mb-4'>
          <RestoreIcon className='color-azul' /> HISTORIAL DE MOVIMIENTOS
        </Typography>
        <div className='col-12'>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Ver historial</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0
                  }
                }}
              >
                {supplyCtx.log.length > 0
                  ? (
                      supplyCtx.log
                        .slice(-showCount)
                        .map((e: any, i: number) => {
                          return (
                            <TimelineItem key={i}>
                              <TimelineSeparator>
                                <TimelineDot
                                  color={
                                                e.type === 1
                                                  ? 'primary'
                                                  : e.type === 2 || e.type === 3 || e.type === 4
                                                    ? 'warning'
                                                    : e.type === 5
                                                      ? 'success'
                                                      : 'error'
                                              }
                                >
                                  <IconHistory type={e.type} />
                                </TimelineDot>
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography variant='h6' component='span'>
                                  <Typography variant='subtitle1' component='span'>
                                    {moment(e.created_at).format('YYYY-MM-DD')}
                                  </Typography>{' '}
                                  |{' '}
                                  {e.type === 1
                                    ? 'Carga de unidades'
                                    : e.type === 2
                                      ? 'Se genera una solicitud'
                                      : e.type === 3
                                        ? 'Solicitud lista'
                                        : e.type === 4
                                          ? 'Uso de unidades'
                                          : e.type === 5
                                            ? 'Creación de insumo'
                                            : 'Eliminacion insumo OT'}
                                </Typography>
                                <Typography>{e.history}</Typography>
                              </TimelineContent>
                            </TimelineItem>
                          )
                        })
                        .reverse()
                    )
                  : (
                    <Alert severity='info'>No registra movimientos.</Alert>
                    )}
              </Timeline>
              <Button
                variant='contained'
                size='medium'
                color='secondary'
                onClick={handleLoadMore}
                hidden={showCount >= supplyCtx.log.length}
              >
                Cargar más
              </Button>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default LogSupply

import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Alert, Checkbox, Paper } from '@mui/material'
import BtnExcel from '../BtnExcel/BtnExcel'
import EnhancedTableHeadCellsOt from './EnhancedTableHeadCellsOt'
import { DataReport } from '../../../../models/reportData.model'
import { Order } from '../../../../types'
import { getComparator, stableSort } from '@utils/tables.util'
import { ReportContextType, useOTSuppliesReportContext } from '@pages/OTSuppliesReport/context/OTSuppliesReport.context'
import EnhancedTableReportFooter from './EnhancedTableReportFooter'
import TblReportExcel from './TblReportExcel'
import { webApiService } from '@services/index'
import { UTCDateToCountryDate } from '@utils/countryDate.util'

export default function TblReporte () {
  const { ctxReport } = useOTSuppliesReportContext() as ReportContextType
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof DataReport>('id')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [dataReport, setDataReport] = React.useState<any>({ data: null, loading: false })
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataReport
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = ctxReport.data.items.map((n: any) => n)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, row: string) => {
    const selectedIndex = selected.indexOf(row)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: string) =>
    selected.indexOf(id) !== -1

  // ? si no ha se aplicado ningun filtro se llena la data total de los registro
  React.useEffect(() => {
    if (selected.length === 0 || ctxReport.data === null) {
      const _params = {
        id_request: '',
        order_number: '',
        date_init: '',
        date_end: ''
      }
      setDataReport({ ...dataReport, loading: true })
      webApiService.getAllRequestReport(_params).then((response) => {
        if (response.success) {
          setDataReport({ ...dataReport, data: response.data, loading: false })
        } else {
          enqueueSnackbar(response.message, { variant: 'warning' })
          setDataReport({ ...dataReport, loading: false })
        }
      }).catch((error) => {
        console.log(error)
        setDataReport({ ...ctxReport, loading: false })
        if (error.code !== undefined) {
          enqueueSnackbar(error.message, { variant: 'error' })
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ width: '100%' }}>

      <BtnExcel />
      <TableContainer component={Paper} className='my-4'>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby='tableTitle'

        >
          <EnhancedTableHeadCellsOt
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={ctxReport.data !== null ? ctxReport.data.items.length : 0}
          />
          {
            ctxReport.data !== null
              ? (
                <TableBody>
                  {stableSort(ctxReport.data.items, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          aria-checked={isItemSelected}
                          onClick={(event) => handleClick(event, row)}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}

                        >
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                            />
                          </TableCell>
                          <TableCell id={labelId} scope='row' align='center'>{row.id}</TableCell>
                          <TableCell align='center'>{row.id_invoice}</TableCell>
                          <TableCell align='center'>{row.status}</TableCell>
                          <TableCell align='center'>{row.status_manager}</TableCell>
                          <TableCell align='center'>{UTCDateToCountryDate(row.created_at)}</TableCell>
                          <TableCell align='center'>{row.client_name}</TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
                )
              : ctxReport.loading
                ? (
                  <TableBody>
                    <TableRow tabIndex={-1}>
                      <TableCell colSpan={11} align='center'>
                        <Alert severity='info'>Cargando, por favor espere...</Alert>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )
                : (
                  <TableBody>
                    <TableRow tabIndex={-1}>
                      <TableCell colSpan={11} align='center'>
                        <Alert severity='warning'>Sin información para mostrar.</Alert>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )
          }
          <EnhancedTableReportFooter
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        </Table>

      </TableContainer>
      {/* EXCEL* */}
      <TblReportExcel dataReport={selected.length > 0 ? selected : ctxReport.data !== null ? ctxReport.data.items : dataReport.data !== null && dataReport.data.items} />

    </Box>
  )
}
function enqueueSnackbar (message: any, arg1: { variant: string }) {
  throw new Error('Function not implemented.')
}

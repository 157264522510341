import React from 'react'
import { Button, Typography } from '@mui/material'
import TblSuppliesList from './components/SuppliesList/TblSuppliesList'
import { RoleChildrenGuard } from 'src/guards'
import { useNavigate } from 'react-router-dom'
import { PrivateRoutes } from '@constants/routes.constants'
interface IOwnProps {
  valor: string
}
const SuppliesManagementPage: React.FC<IOwnProps> = ({ valor }) => {
  const navigate = useNavigate()
  return (

    <div className='container-xxl '>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            Gestión de Insumos
          </Typography>

          <div className='shadow-sm rounded bg-white p-3'>
            <div className='d-flex justify-content-end actionBar'>
              <RoleChildrenGuard roles={[4, 6, 10, 11]} PublicValidationFragment={null}>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  className='mb-3'
                  onClick={() => navigate(PrivateRoutes.NEW_SUPPLY)}
                >
                  + CREAR INSUMO
                </Button>
              </RoleChildrenGuard>
            </div>
            <TblSuppliesList />
          </div>
        </div>
      </div>
    </div>

  )
}

export default SuppliesManagementPage

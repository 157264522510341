import { ModalNames } from '@constants/modal.constants'
import { PrivateRoutes } from '@constants/routes.constants'
import { ModalState } from '@models/modals.model'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RoleChildrenGuard from 'src/guards/roleChildren.guard'
import { ModalBulkLoad } from './ModalBulkLoad'

interface IOwnProps {
  load: () => void
}

const CustomToolbar: React.FC<IOwnProps> = ({ load }) => {
  const [data, setData] = useState<{ processing: boolean, count: number, total: number, modal: ModalState }>({ processing: false, count: 0, total: 0, modal: { visible: false, name: ModalNames.BULKLOAD, parametros: {} } })
  const navigate = useNavigate()

  /*
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    uploadAndProcess(file, event.target)
  }

  const uploadAndProcess = async (csvFile: any, _target: any) => {
    if (!csvFile) return

    setData({ ...data, processing: true, count: 0 })
    const reader = new FileReader()
    reader.onload = async () => {
      const csvString = reader.result
      console.log('🚀 ~ file: CustomToolbar.tsx:31 ~ reader.onload= ~ csvString:', reader)

      Papa.parse(csvString as any, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (result: any) => {
          console.log('length', result.data.length)

          for (const record of result.data.filter((e: any) => e.Categoria !== 'Servicios')) {
            if (record.Categoria === null) break
            const objSupply = {
              id: 0,
              category: record.Categoria,
              family: record.Familia === null ? '' : record.Familia,
              subfamily: record.Subfamilia === null ? '' : record.Subfamilia,
              sku: record['SKU reuse'],
              name: record['Nombre o descripcion'],
              variant_color: record['Variante o Color'],
              compatible_models: '',
              origin_code: record['Codigo de origen'],
              sku_rewa: record['SKU REWA'],
              active: record.Activo === 'SI' ? 1 : 0,
              cost: record['Costo unitario'],
              provider: record.Proveedor
            }
            console.log('🚀 ~ file: CustomToolbar.tsx:42 ~ complete: ~ objSupply:', objSupply)
            const retSupply = await webApiService.saveSupplies(objSupply).then(res => res.data)
            console.log('🚀 ~ file: CustomToolbar.tsx:40 ~ complete: ~ retSupply:', retSupply)
            setData({ ...data, count: ++data.count, processing: true, total: result.data.filter((e: any) => e.Categoria !== 'Servicios').length })
          }
          _target.value = null
          setData({ ...data, processing: false, count: 0 })
          load()
        }
      })
    }

    reader.readAsText(csvFile)
  }
  */

  const saveModal = ({ visible, name, parametros }: ModalState) => {
    setData({ ...data, modal: { visible, name, parametros } })
  }

  return (
    <RoleChildrenGuard roles={[4, 6, 10, 11]} PublicValidationFragment={null}>
      <Button variant='contained' color='primary' className='mx-1' onClick={() => navigate(PrivateRoutes.SUPPLY + 'new')}>Crear</Button>
      <Button variant='contained' color='primary' className='mx-1' onClick={() => setData({ ...data, modal: { ...data.modal, visible: true } })}>Creación masiva</Button>
      <ModalBulkLoad modal={data.modal} setModal={saveModal} />
    </RoleChildrenGuard>
  )
}

export default CustomToolbar

import { ReactNode } from 'react'

interface IOwnProps {
    userAssigned?: string
    userAssignedTechnician?: string
    PublicValidationFragment: any
    children?: ReactNode
    externalCondition: boolean
  }

export const AssignmentsGuard = ({ children, userAssigned, userAssignedTechnician, PublicValidationFragment, externalCondition }: IOwnProps) => {
  // console.log('userAssigned', userAssigned)
  // console.log('userAssignedTechnician', userAssignedTechnician)

  return ((userAssigned !== null) && (userAssignedTechnician !== null)) || externalCondition
    ? (children)
    : (
        PublicValidationFragment
      )
}

export default AssignmentsGuard

import AWS from 'aws-sdk'

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})
export default s3
export const sendInvoiceToS3 = async (supplyID:number, file: File) => {
  const params = {
    Bucket: String(process.env.REACT_APP_AWS_BUCKET),
    Key: `supplies/${supplyID}/invoice/${file.name}`,
    Body: file
  }

  try {
    await s3.upload(params).promise()
  } catch (error) {
    console.error('Error al subir el archivo: ', error)
  }
}

export const sendImageToS3 = async (supplyID:number, file: File) => {
  const newFile = new File([file], 'supply-image.jpg', { type: file.type })
  const params = {
    Bucket: String(process.env.REACT_APP_AWS_BUCKET),
    Key: `supplies/${supplyID}/image/${newFile.name}`,
    Body: newFile
  }

  try {
    await s3.upload(params).promise()
  } catch (error) {
    console.error('Error al subir el archivo: ', error)
  }
}

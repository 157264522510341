import { IOTIMEI } from '@models/OT.model'

import { createContext, useContext, useState } from 'react'

interface IOwnProps {
  ot: IOTIMEI
  loading: boolean,
  // objBsale: IBsaleObject
  otInicial: IOTIMEI
  listStatus: any[]
  errorValidation: boolean
}

export type FormIMEIContextType = {
  formCtxIMEI: IOwnProps;
  saveFormCtxIMEI: (formCtxIMEI: IOwnProps) => void;
};

export const OTFormIMEIContext = createContext<FormIMEIContextType | null>(null)

export const OTEmpty: IOTIMEI = {
  id: 0,
  updated_by: 0,
  created_by: 0,

  channel: {
    bsale_channel_id: '',
    id: '',
    name: ''
  },
  product: {
    id: 0,
    name: '',
    serial: '',
    id_product_type: 0,
    id_status: 0,
    id_warehouse: 0,
    sbu: {
      bsale_sbu_id: 0,
      id: '',
      name: ''
    },
    sku: ''
  },
  id_flow: 0,
  priority: 0,
  failures: [],
  failure_description: '',
  id_cosmetic_status: '',
  cosmetic_description: '',
  information_status: '',
  technical_service: {
    id: 0,
    id_failure_type: 0,
    id_component_ppl_failure: '',
    comments_ppl_failure: '',
    id_component_snd_failure: 0,
    comments_snd_failure: ''
  },
  diagnostic_information: '',
  diagnostic_resolution: '',
  id_assigned_user: '',
  id_assigned_technician: '',
  NC_number: -1,
  NC_date: '',
  id_warehouse_NC: '',
  id_warehouse_transfer: '',
  status: {
    id: 0,
    name: ''
  },
  listSuppliesTypes: [],
  listFamily: [],
  listSuppliesTypesValues: [{
    id: 0,
    idType: 0,
    listSupplies: [],
    stock: 0,
    idSupply: 0,
    cantidad: 0,
    buy: 0,
    isLocked: false,
    purchased: 0,
    initial_stock: 0,
    idFamily: 0,
    delivered: 0
  }],
  id_product_functionality: '',
  created_at: '',
  files: []
}

export const OTFormIMEIProvider = ({ children } : any) => {
  const [formCtxIMEI, setFormCtxIMEI] = useState<IOwnProps>({ ot: OTEmpty, otInicial: OTEmpty, loading: false, listStatus: [], errorValidation: false })

  const saveFormCtxIMEI = (_formCtxIMEI: IOwnProps) => {
    setFormCtxIMEI(_formCtxIMEI)
  }

  return <OTFormIMEIContext.Provider value={{ formCtxIMEI, saveFormCtxIMEI }}>{children}</OTFormIMEIContext.Provider>
}

export const useOTFormIMEIContext = () => {
  const context = useContext(OTFormIMEIContext)
  if (context === undefined) {
    throw new Error('OTFormContext must be used within a DashboardProvider')
  }
  return context
}

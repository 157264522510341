import { IAppStore } from '@models/store.model'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { FormContextType, useOTFormContext } from '@pages/OTForm/context/OTForm.context'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useSelector } from 'react-redux'

const OTStatus = ({ props }: any) => {
  const userState = useSelector((store: IAppStore) => store.user)
  const { formCtx, saveFormCtx } = useOTFormContext() as FormContextType
  const [data, setData] = React.useState<{ loading: boolean, listStatus: any[]}>({ loading: false, listStatus: [] })
  const { enqueueSnackbar } = useSnackbar()
  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getAllListStatus(formCtx.ot.status.id, userState.id_role, formCtx.ot.id_flow).then((response) => {
      if (response.success) {
        setData({ listStatus: response.data, loading: false })
      }
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'warning' })
      console.log(error)
    })
  }

  React.useEffect(() => {
    if (formCtx.ot.id > 0) loadData()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCtx.ot.id])

  const handleChangeStatus = (idStatus: any) => {
    saveFormCtx({ ...formCtx, ot: { ...formCtx.ot, diagnostic_resolution: (idStatus === 29) ? '' : formCtx.ot.diagnostic_resolution, status: { ...formCtx.ot.status, id: idStatus } } })
  }

  return (
    <div hidden={formCtx.ot.NC_number >= 0 || Number.isNaN(formCtx.ot.NC_number)}>
      <FormControl fullWidth hidden={((formCtx.otInicial.status.id === 2 && ((formCtx.otInicial.flag_unlock_code === 0 && formCtx.ot.flag_unlock_code === 1) || (!formCtx.otInicial.flag_disassociation && formCtx.ot.flag_disassociation))) || data.listStatus.length === 0 || (formCtx.ot.status.id === 15 && (formCtx.ot.diagnostic_information.toUpperCase() === 'NO APLICA GARANTIA MAL USO' || formCtx.ot.diagnostic_information.toUpperCase() === 'NO APLICA GARANTIA FUNCIONAMIENTO OK')) || (formCtx.otInicial.status.id === 31 && formCtx.otInicial.diagnostic_information.toUpperCase() === 'FUNCIONAMIENTO OK')) && (formCtx.otInicial.status.id !== 37)}>
        <InputLabel id='demo-simple-select-label'>Cambio de Estado</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={formCtx.ot.status.id}
          label='Cambio de Estado'
          onChange={(e) => handleChangeStatus(e.target.value)}

        >
          {data.listStatus && data.listStatus.map((item, i) => (
            <MenuItem value={item.id} key={i + 1}>{item.status}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default OTStatus

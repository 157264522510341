import { Alert, Button, Card, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Table, TextField, Toolbar, Typography } from '@mui/material'
import React, { FC, useEffect, useState, Dispatch, SetStateAction } from 'react'
import { Transition } from '@constants/TransitionModal.contants'
import { LoadingButton } from '@mui/lab'
import { CloseIcon } from '@assets/icons/Icons'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'

// interface IOwnProps {
//     open: boolean
//     handleClose: () => void
// }
type modal = { open: boolean, idSupply: null | number }
interface IOwnProps {
  modal: modal
  setModal: Dispatch<SetStateAction<modal>>;
  inventory: number
}

const ModalOTList: FC<any> = ({ modal, setModal, inventory }: IOwnProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState<{ loadingData: boolean, saving: boolean, requests: any[], reqId: number, totalDelivery: boolean, partialDelivery: boolean, qty: number }>({ loadingData: false, saving: false, requests: [], reqId: 0, totalDelivery: false, partialDelivery: false, qty: 0 })
  // console.log('modal => ', modal)
  console.log('data => ', data)

  const deliver = async (idSupplyRequest: number, quantity: number) => {
    console.log('🚀 ~ deliver ~ idSupplyRequest:', idSupplyRequest, quantity)
    console.log('Entregando insumo')
    setData({ ...data, saving: true, reqId: idSupplyRequest })
    // console.log('data => ', data)

    try {
      const body: any = {
        idSupplyRequest,
        quantitySupplyRequest: data.totalDelivery ? quantity : data.qty
      }
      console.log('🚀 ~ deliver ~ body:', body)
      // eslint-disable-next-line no-debugger
      debugger
      // console.log('body => ', body)

      const res = await webApiService.updateSupplyOrder(body)
      // console.log('res => ', res)
      if (res.success) {
        let newRequest
        if (data.totalDelivery) { newRequest = data.requests.filter((req: any) => req.id !== idSupplyRequest) } else {
          newRequest = data.requests.map((req: any) => {
            console.log('comp =>', req, idSupplyRequest)
            if (req.id === idSupplyRequest) { return { ...req, entregado: req.entregado + data.qty } } else { return req }
          })
        }

        console.log('newRequest => ', newRequest, data.totalDelivery)

        setData({ ...data, saving: false, requests: newRequest, reqId: 0, totalDelivery: false, partialDelivery: false })
        enqueueSnackbar('Insumo entregado correctamente.', { variant: 'success' })
      }
    } catch (error) {
      setData({ ...data, saving: false, reqId: 0 })
      enqueueSnackbar('Hubo un error al entregar el insumo, por favor, vuelva a intentar.', { variant: 'error' })
    }
  }

  const loadData = async () => {
    setData({ ...data, loadingData: true })
    try {
      const requests = modal.idSupply && await webApiService.getOtBySupply(modal.idSupply).then(res => res.data)
      console.log('requests => ', requests)

      setData({ ...data, requests, loadingData: false })
    } catch (error) {
      console.log(error)
      setData({ ...data, loadingData: false })
      enqueueSnackbar('Hubo un error al cargar la información, por favor, vuelva a intentar.', { variant: 'error' })
    }
  }

  useEffect(() => {
    loadData()
  }, []) // eslint-disable-line

  return (
    <Dialog
      open={modal.open}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <Typography variant='h6' component='div' className='color-title'>
            Inventario insumo:
          </Typography>
          <span className='badge rounded-pill text-bg-light ms-2'>{inventory}</span>
          <IconButton
            aria-label='close'
            onClick={() => setModal({ open: false, idSupply: null })}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <Card className='shadow-sm'>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            {data.loadingData
              ? <CircularProgress />
              : data.requests.length > 0
                ? (
                  <Table>
                    <thead>
                      <tr className='text-center'>
                        <th>OT N°</th>
                        <th>Asignado</th>
                        <th>Entregado</th>
                        <th>Pendientes de entrega</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.requests.map((req: any, i: number) => (
                        <tr className='text-center border-bottom' key={i}>
                          <td className='py-2'>{req.id_request_st}</td>
                          <td className='py-2'>{req.cantidad}</td>
                          <td className='py-2'>{req.entregado}</td>
                          <td className='py-2'>{req.cantidad - req.entregado}</td>
                          {data.partialDelivery && data.reqId === req.id
                            ? (
                              <>
                                <td className='py-2'>
                                  <TextField
                                    disabled={data.saving && data.reqId === req.id}
                                    label='Qty'
                                    size='small'
                                    sx={{ width: 128.33 }}
                                    type='number'
                                    value={data.qty || ''}
                                    onChange={(e) => {
                                      console.log(JSON.stringify(req))
                                      const number = Number(e.target.value)
                                      if (number >= 0 && number <= req.cantidad - req.entregado) {
                                        setData({ ...data, qty: number })
                                      }
                                    }}
                                  />
                                </td>
                                <td className='py-2'>
                                  <LoadingButton
                                    loading={data.saving && data.reqId === req.id}
                                    variant='contained'
                                    onClick={() => deliver(req.id, data.qty)}
                                    color='warning'
                                  >
                                    Entregar
                                  </LoadingButton>
                                </td>
                              </>
                              )
                            : data.totalDelivery && data.reqId === req.id
                              ? (
                                <>
                                  <td className='py-2'>
                                    {JSON.stringify(req)}
                                    <LoadingButton
                                      disabled={(data.saving && data.reqId !== req.id) || data.partialDelivery}
                                      loading={data.saving && data.reqId === req.id}
                                      variant='contained'
                                      color='warning'
                                      className='shadow-none'
                                      onClick={() => deliver(req.id, req.cantidad - req.entregado)}
                                    >
                                      Confirmar
                                    </LoadingButton>
                                  </td>
                                  <td className='py-2'>
                                    <Button
                                      disabled={data.saving}
                                      variant='contained'
                                      onClick={() => setData({ ...data, totalDelivery: false })}
                                    >
                                      Cancelar
                                    </Button>
                                  </td>
                                </>
                                )
                              : (
                                <>
                                  <td className='py-2'>
                                    <Button
                                      hidden={req.cantidad - req.entregado === 1}
                                      variant='contained'
                                      disabled={data.partialDelivery || data.totalDelivery}
                                      onClick={() => setData({ ...data, partialDelivery: true, reqId: req.id })}
                                    >
                                      Entrega parcial
                                    </Button>
                                  </td>
                                  <td className='py-2'>
                                    <Button
                                      variant='contained'
                                      color='warning'
                                      disabled={data.partialDelivery || data.totalDelivery}
                                      onClick={() => setData({ ...data, totalDelivery: true, reqId: req.id })}
                                    >
                                      Entrega Total
                                    </Button>
                                  </td>
                                </>
                                )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  )
                : (
                  <Alert severity='success' sx={{ width: '100%' }}>Todos los suministros fueron entregados</Alert>)}
          </CardContent>
        </Card>

      </DialogContent>
    </Dialog>
  )
}

export default ModalOTList

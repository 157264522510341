
import { suppliesRequestHeadCells } from '@constants/headcell.constants'
import { SuppliesDataReport } from '@models/reportData.model'
import { Box, Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import React from 'react'
import { Order } from '../../../../types'
import SearchColumn from './SearchColumn'

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof SuppliesDataReport
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-undef
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof SuppliesDataReport) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell className='tbl-header border-bottom' />
        {suppliesRequestHeadCells.map((headCell : any, idx:number) => (
          <TableCell
            key={idx}
            padding='none'
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ textAlign: 'center', padding: '10px' }}
            className='tbl-header border-bottom'
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>)
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className='tbl-header border-bottom' />
      </TableRow>
      <TableRow>
        <TableCell className='tbl-header border-bottom'>
          <Checkbox
            color='primary'
            indeterminate={props.numSelected > 0 && props.numSelected < props.rowCount}
            checked={props.rowCount > 0 && props.numSelected === props.rowCount}
            onChange={props.onSelectAllClick}
          />
        </TableCell>
        {suppliesRequestHeadCells.map((headCell : any, idx:number) => (
          <TableCell
            key={idx}
            padding='none'
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ textAlign: 'center', padding: '10px' }}
            className='tbl-header border-bottom'
          >
            {headCell.hasFilter ? <SearchColumn nameField={headCell.id} /> : null}
          </TableCell>
        ))}
        <TableCell className='tbl-header border-bottom' />
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead

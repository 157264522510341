import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Button, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material'
import { SupplyContextType, useSupplyContext } from '../context/Supply.context'
import React, { useEffect, useState } from 'react'
import { webApiService } from '@services/index'
import { useSnackbar } from 'notistack'
import * as XLSX from 'xlsx'

interface IOwnProps {
  idSupply: number
}

const Name: React.FC<IOwnProps> = ({ idSupply }) => {
  const { supplyCtx, saveSupplyCtx } = useSupplyContext() as SupplyContextType
  const [data, setData] = useState<{ seriales: any[] }>({ seriales: [] })

  const { enqueueSnackbar } = useSnackbar()

  const downloadEXCEL = () => {
    const ws = XLSX.utils.json_to_sheet(data.seriales)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')
    XLSX.writeFile(wb, `seriales-${idSupply}.xlsx`)
  }

  const loadData = async () => {
    await webApiService.getAllSerial(idSupply).then((response) => {
      setData({ seriales: response.data })
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    })
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='row gy-3'>
      <div className='col-12 d-flex align-items-center'>
        <Typography variant='h6' className='py-2 color-title'>
          <AccountCircleIcon className='color-azul' /> Nombre
        </Typography>
      </div>
      <div className='col-12 col-lg-10'>
        <TextField
          type='text'
          name='cliente'
          label='Nombre'
          value={supplyCtx.supply.name}
          onChange={(e) => saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, name: e.target.value } })}
          fullWidth
        />
      </div>
      <div className='col-12 col-lg-2 d-flex align-items-center justify-content-center'>
        <FormControl>
          <FormControlLabel control={<Checkbox checked={!!(supplyCtx.supply.active)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { saveSupplyCtx({ ...supplyCtx, supply: { ...supplyCtx.supply, active: e.target.checked ? 1 : 0 } }) }} />} label='Activo' />
        </FormControl>
      </div>
      <div className='col-12 d-flex align-items-center justify-content-start'>
        <Button
          variant='contained'
          size='medium'
          hidden={data.seriales.length === 0}
          className='m-2 bg-success'
          onClick={downloadEXCEL}
        >
          Descargar seriales
        </Button>
      </div>
    </div>
  )
}

export default Name

import { TableFooter, TablePagination, TableRow } from '@mui/material'
import { ReportContextType, useOTSuppliesReportContext } from '@pages/OTSuppliesReport/context/OTSuppliesReport.context'
import React from 'react'

const EnhancedTableReportFooter = ({ handleChangePage, handleChangeRowsPerPage, rowsPerPage, page }: any) => {
  const { ctxReport } = useOTSuppliesReportContext() as ReportContextType

  if (ctxReport.data === null) return <></>

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          colSpan={6}
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          labelRowsPerPage='Filas'
          SelectProps={{
            inputProps: {
              'aria-label': 'page number'
            }
          }}
          showFirstButton
          showLastButton
          count={ctxReport.data !== null ? ctxReport.data.items.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  )
}

export default EnhancedTableReportFooter

import { Typography } from '@mui/material'
import { webApiService } from '@services/index'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'

const SuppliesReportPage = () => {
  const [data, setData] = useState<{ loading: boolean, supplies: any[] }>({ loading: false, supplies: [] })
  const columns = [
    {
      name: 'id_supply',
      label: 'ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'stock_inicial',
      label: 'Stock inicial',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'stock_ingresado',
      label: 'Stock ingresado',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          console.log(JSON.stringify(tableMeta))
          return value - tableMeta.rowData[2]
        }
      }
    },
    {
      name: 'solicitados',
      label: 'Stock solicitado',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'pendiente',
      label: 'Stock pendiente de entregar (asignado)',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'entregados',
      label: 'Stock entregado',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'stock',
      label: 'Stock final',
      options: {
        filter: true,
        sort: true,
        display: true
      }
    }
  ]

  const loadData = async () => {
    setData({ ...data, loading: true })
    const get = await webApiService.getDeliveredReport().then(res => res.data)
    console.log('Data de useEffect => ', get)
    setData({ loading: false, supplies: get })
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className='container-xxl '>
        <div className='row gy-3 my-4'>
          <div className='col-12 my-2'>
            <Typography variant='h6' className='title mb-3'>
              REPORTE INSUMOS
            </Typography>

            <div className='shadow-sm rounded bg-white p-3'>
              <div className='tabla-ot' data-tour='tabla-ot'>
                <MUIDataTable
                  title=''
                  data={data.supplies}
                  columns={columns}
                  options={{ selectableRowsHideCheckboxes: false }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliesReportPage

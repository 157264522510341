import LogoReuse from '@assets/img/logo-reuse-alargado.png'
import { IAppStore } from '@models/store.model'
import { useSelector } from 'react-redux'

const Footer = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  return (
    <footer>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12 d-flex justify-content-center py-4'>
            {/*  <a
              href="https://reuse.cl"
              target="_blank"
              className="btn btn-xs btn-default"
            > */}
            <div className='text-center'>
              <img src={LogoReuse} alt='Reuse.cl' />
              {userState.id !== 0
                ? (
                  <div className='col-12'>
                    <h6>
                      versión: <b>V1.0</b>
                    </h6>
                  </div>
                  )
                : null}

            </div>

            {/*  </a> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import { IBsaleObject } from '@models/bsale.model'
import { IOT } from '@models/OT.model'
import { createContext, useContext, useState } from 'react'

interface IOwnProps {
  ot: IOT
  loading: boolean,
  objBsale: IBsaleObject
  otInicial: IOT
  listStatus: any[]
  errorValidation: boolean
}

export type FormContextType = {
  formCtx: IOwnProps;
  saveFormCtx: (formCtx: IOwnProps) => void;
};

export const OTFormContext = createContext<FormContextType | null>(null)

export const OTEmpty: IOT = {
  id: 0,
  updated_by: 0,
  created_by: 0,
  invoice: {
    id: 0,
    id_invoice: '',
    id_channel: 0,
    date_invoice: '',
    type: 0,
    url: '',
    name: '',
    rut: '',
    address: '',
    commune: '',
    commercial_activity: ''
  },

  channel: {
    bsale_channel_id: '',
    id: '',
    name: ''
  },
  client: {
    id: 0,
    email: '',
    name: '',
    phone: '',
    identification_number: ''
  },
  product: {
    id: 0,
    serial: '',
    name: '',
    id_product_type: 0,
    id_status: 0,
    id_warehouse: 0,
    sbu: {
      bsale_sbu_id: 0,
      id: '',
      name: ''
    },
    sku: ''
  },
  id_flow: 0,
  priority: 0,
  reception_type: '',
  failures: [],
  failure_description: '',
  id_cosmetic_status: '',
  cosmetic_description: '',
  flag_unlock_code: 0,
  flag_disassociation: false,
  information_status: '',
  unlock_code: '',
  technical_service: {
    id: 0,
    id_failure_type: 0,
    id_component_ppl_failure: '',
    comments_ppl_failure: '',
    id_component_snd_failure: 0,
    comments_snd_failure: ''
  },
  diagnostic_information: '',
  diagnostic_resolution: '',
  client_notification: 0,
  id_assigned_user: '',
  id_assigned_user_initial: 0,
  id_assigned_technician: '',
  id_assigned_technician_initial: 0,
  NC_number: -1,
  NC_date: '',
  id_warehouse_NC: '',
  id_warehouse_transfer: '',
  status: {
    id: 0,
    name: ''
  },
  id_product_functionality: '',
  created_at: '',
  files: [],
  typeDocument: '',
  numberDocument: '',
  listSuppliesTypes: [],
  listFamily: [],
  listSuppliesTypesValues: [{
    id: 0,
    idType: 0,
    listSupplies: [],
    stock: 0,
    idSupply: 0,
    cantidad: 0,
    buy: 0,
    isLocked: false,
    purchased: 0,
    initial_stock: 0,
    idFamily: 0,
    delivered: 0
  }]
}

export const objBsaleEmpty : IBsaleObject = {
  id_bsale: 0,
  typeDocument: 0,
  number: 0,
  emissionDate: '',
  expirationDate: '',
  generationDate: '',
  document_type: {
    codeSii: '',
    name: '',
    urlPublicView: ''
  },
  warehouse: {
    nameWareHouse: ''
  },
  cliente: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    rut: '',
    phone: '',
    company: '',
    activity: '',
    city: '',
    municipality: '',
    address: ''
  },
  productos: [],
  channel: {
    id_channel: 0,
    channel_name: '',
    bsale_channel_id: 0
  }
}

export const OTFormProvider = ({ children } : any) => {
  const [formCtx, setFormCtx] = useState<IOwnProps>({ ot: OTEmpty, otInicial: { ...OTEmpty }, loading: false, objBsale: objBsaleEmpty, listStatus: [], errorValidation: false })

  const saveFormCtx = (_formCtx: IOwnProps) => {
    setFormCtx(_formCtx)
  }

  return <OTFormContext.Provider value={{ formCtx, saveFormCtx }}>{children}</OTFormContext.Provider>
}

export const useOTFormContext = () => {
  const context = useContext(OTFormContext)
  if (context === undefined) {
    throw new Error('OTFormContext must be used within a DashboardProvider')
  }
  return context
}

import { PrivateRoutes } from '@constants/routes.constants'
import { Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { webApiService } from '@services/index'
import CustomToolbar from './components/CustomToolbar'
import { Edit } from '@mui/icons-material'

const SuppliesListPage = () => {
  const [data, setData] = useState<{supplies:any[], loading: boolean }>({ supplies: [], loading: false })
  const navigate = useNavigate()
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'sku',
      label: 'Sku',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'category',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'variant_color',
      label: 'Variante ó Color',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'family',
      label: 'Familia',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'subfamily',
      label: 'Sub Familia',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'inventory',
      label: 'Inventario',
      options: {
        filter: true,
        sort: true,
        display: true
      }
    },
    {
      name: 'Action',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Button variant='contained' color='warning' className='shadow-none' onClick={() => navigate(PrivateRoutes.SUPPLY + tableMeta.rowData[0])}><Edit /></Button>
          )
        }
      }
    }
  ]
  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getSuppliesList().then((response) => {
      setData({ supplies: response.data, loading: false })
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='container-xxl '>
      <div className='row gy-3 my-4'>
        <div className='col-12 my-2'>
          <Typography variant='h6' className='title mb-3'>
            Gestión de Insumos
          </Typography>

          <div className='shadow-sm rounded bg-white p-3'>
            <MUIDataTable
              title=''
              data={data.supplies}
              columns={columns}
              options={{ selectableRowsHideCheckboxes: false, customToolbar: () => <CustomToolbar load={loadData} /> }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliesListPage
